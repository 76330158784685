import styled from 'styled-components'
import SanityStockistGroup from './../SanityStockistGroup';
// import {SanityFileAsset} from '@sanity/asset-utils';

interface Props {
  className?: string,
  title: string,
  regions: any,
  anchor: string,
}
const SanityContentStockists: React.FC<Props> = ({ 
  title, 
  regions, 
  anchor,
  className 
}: Props) => <Wrapper title={title} regions={regions} className={className} anchor={anchor} />

const Wrapper = styled(SanityStockistGroup)`
  padding-top: var(--spacing-16);
  padding-bottom: var(--spacing-32);
  border-top: 1px solid var(--color-charcoal200);
`

export default SanityContentStockists


   
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

const COOKIE_NAME = 'acceptCookies'

export const useAcceptCookies = () => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!!Cookies.get(COOKIE_NAME)) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }, [])

  const close = () => {
    setIsOpen(false)
    Cookies.set(COOKIE_NAME, 'closed', { expires: 365 })
  }

  return {
    isOpen,
    close,
  }
}
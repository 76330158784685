import { useProduct } from "frontend/src/contexts/ProductProvider";
import styled from "styled-components";
import ProductCardHeading from "./ProductCardHeading";
import ProductCardPrice from "./ProductCardPrice";
import Link from "../../link/LinkRender";
import ProductCardMedia from "./ProductCardMedia";
import { useState } from "react";
import ProductCardWrapper from "./ProductCardWrapper";

interface Props {
  className?: string;
}
const ProductCardS: React.FC<Props> = ({ className }: Props) => {
  const { shopifyProduct, product } = useProduct();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Container className={className} setIsHovered={setIsHovered}>
      <Link href={`/products/${shopifyProduct.handle}`}>
        <ProductCardMedia
          isHovered={isHovered}
          product={product}
          shopifyProduct={shopifyProduct}
        />

        <ProductCardHeading
          withColour={true}
          product={product}
          shopifyProduct={shopifyProduct}
        />

        <ProductCardPrice product={product} shopifyProduct={shopifyProduct} />
      </Link>
    </Container>
  );
};

const Container = styled(ProductCardWrapper)``;

export default ProductCardS;

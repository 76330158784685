import styled from 'styled-components'
import { useCart } from '../../contexts/CartProvider';
import SvgPlus from '../svg/SvgPlus';
import SvgMinus from '../svg/SvgMinus';

interface P {
  item
}
const CartItemQuantity: React.FC<P> = ({ 
  item,
  ...props
}: P) => {
  const {
    cartLineUpdate,
    cartLineRemove,
  } = useCart();

  const updateQuantity = async (quantity = 1) => {
    if(quantity < 1) {
      await cartLineRemove(item.id)
    } else {
      await cartLineUpdate({ 
        id: item.id, 
        quantity, 
      })
    }
  }

  return (
    <Container {...props}>
      <Button onClick={() => updateQuantity(item.quantity - 1)}>
        <SvgMinus />
      </Button>
      <span>{ item.quantity }</span>
      <Button onClick={() => updateQuantity(item.quantity + 1)}>
        <SvgPlus />
      </Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 0 4px;
`

const Button = styled.button``

export default CartItemQuantity
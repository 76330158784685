import { useState } from 'react';
import styled from 'styled-components'
import { Breakpoint, MobileBreakpoint } from '../../styles/functions';
import { TypeBodyM, TypeHeadingXS } from '../../styles/mixins';
import TransitionAccordion from '../transition/TransitionAccordion';
import SanityBlocks from './SanityBlocks';
import SanityContent from './content/SanityContent';

interface Props {
  region: any
  className?: string
}
const SanityStockistRegion: React.FC<Props> = ({ 
  className,
  region,
}: Props) => {
  const {title, stores} = region;

  const [isOpen, setIsOpen] = useState(false);

  const toggle = function() {
    setIsOpen(!isOpen)
  }

  return (
    <Container className={className}>
      <Heading onClick={toggle}>
        {title} 
        <span>({stores.length})</span>
      </Heading>

      <TransitionAccordion isOpen={isOpen}>
        <StoresWrap>
        <Stores>
          {
            stores.map((store, index) => (
              <Store key={store._key}>
                <Number>({(index+1)})</Number>
                <div>
                  <StoreName>{store.name}</StoreName>
                  <SanityBlocks  
                    blocks={store.details} 
                  />
                </div>
              </Store>
            ))
          }
        </Stores>
        </StoresWrap>
      </TransitionAccordion>
    </Container>
  )
}

const Container = styled.div`
`

const StoreName = styled.h3`
  margin-bottom: 8px;
`

const Heading = styled.button`
  ${TypeHeadingXS};
  span {
    vertical-align: super;
    font-size: 14px;
    margin-left: 0.5ch;
  }
`

const StoresWrap = styled.div`
overflow: hidden;
  height: 100%;
`

const Stores = styled.div`
  ${TypeBodyM};
  padding-top: var(--spacing-40);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: var(--spacing-40) var(--gutter);
  padding-bottom: var(--spacing-64);

  ${Breakpoint('tablet', 'max')} {
    grid-template-columns: 1fr 1fr;
  }
`

const Store = styled.div`

  display: grid;
  grid-template-columns: var(--spacing-40) 1fr;
`

const Number = styled.div`
`

export default SanityStockistRegion
import styled from "styled-components";
import { useNavigation } from "../../contexts/NavigationProvider";
import { TypeCaptionL, TypeCaptionM } from "src/styles/mixins";

type Props = {
  className?: string;
  footer: any;
};

const FooterLogos: React.FC<Props> = ({ className, footer }: Props) => {
  const { images } = footer;

  return (
    <Container className={className}>
      <Heading>{footer.logoHeading}</Heading>
      <Logos>
        {images?.map((image) => (
          <Logo key={image._key}>
            <img src={image.asset.url} alt={image.alt || ""} />
          </Logo>
        ))}
      </Logos>
    </Container>
  );
};

const Container = styled.div``;

const Heading = styled.h2`
  ${TypeCaptionM}
  grid-column: 1 / -1;
  margin-bottom: var(--spacing-16);
`;

const Logos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gutter);
  align-items: flex-start;
  padding-right: 10px;
  margin-right: auto;
  max-width: 110px;
`;

const Logo = styled.div`
  grid-column: auto / span 1;
  display: flex;
  margin-bottom: auto;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

export default FooterLogos;

import { css } from "styled-components";
import { BezierDefault } from "./animation";
import { TypeBodyM } from "./text";

export const BorderUnderlineOn = css`
  border-bottom: solid 0.04em var(--color-foreground);
`

export const BorderUnderlineOff = css`
  border-bottom: solid 0.04em transparent;
`

export const ShadowUnderlineOn = css`
  box-shadow: inset 0 -0.04em 0 0 var(--color-foreground);
`

export const ShadowUnderlineOff = css`
  box-shadow: inset 0 -0.04em 0 0 transparent;
`

export const Hover = `
  &:hover { @media screen and (pointer: fine)
`

export const HoverScoped = `
  &:hover & { @media screen and (pointer: fine)
`

export const LinkInline = css`
  ${TypeBodyM};
  display: inline-flex;
  vertical-align: baseline;

  transition: all 0.3s ${BezierDefault};

  ${Hover} {
    opacity: 0.5;
  }}
`

export const UnstyledLinkInline = css`
  display: inline-flex;
  vertical-align: baseline;

  transition: all 0.3s ${BezierDefault};

  ${Hover} {
    opacity: 0.5;
  }}
`
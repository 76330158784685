import { useEffect } from "react";
import { useCart } from "../contexts/CartProvider";
import { useCustomer } from "../contexts/CustomerProvider";

const useBuyer = async () => {
  const { isLoggedIn, accessToken } = useCustomer();
  const { cart, cartBuyerUpdate } = useCart();

  useEffect(() => {
    const cartId = cart?.id;
    const cartAccessToken = cart?.customerAccessToken;
    if (!cartId || isLoggedIn === undefined) return;

    // Add Cart Buyer
    if (isLoggedIn === true && !cartAccessToken) {
      console.log("adding cart buyer");
      cartBuyerUpdate(
        {
          customerAccessToken: accessToken,
        },
        cartId,
      );
    }

    // Remove Cart Buyer
    if (isLoggedIn === false && cartAccessToken) {
      console.log("removing cart buyer");
      cartBuyerUpdate(
        {
          customerAccessToken: null,
        },
        cartId,
      );
    }
  }, [cart?.id, isLoggedIn]);
};

export default useBuyer;

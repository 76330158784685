
import styled from 'styled-components'
import { useItem } from '../../contexts/ItemProvider'
import GlobalSpecs from '../global/GlobalSpecs'

const CartItemSpecs: React.FC = ({ 
  ...props
}) => {
  const {
    number,
    style,
    specs,
    colour,
  } = useItem();

  return (
    <Specs 
      withSku={true}
      withSpecs={true}
      withStyle={true}
      withHandle={true}
      withColour={true}
      withColours={false}

      number={number}
      styleName={style}
      specs={specs}
      colour={colour}

      {...props}
    />
  )
}

const Specs = styled(GlobalSpecs)`
`

export default CartItemSpecs

import styled, { css } from 'styled-components'
import { useProduct } from '../../contexts/ProductProvider'
import { Hover, TypeBodyM, TypeCaptionM } from '../../styles/mixins'
import Link from '../link/LinkRender'

interface P {
  withSku?: boolean
  withStyle?: boolean
  withSpecs?: boolean
  withHandle?: boolean
  withColour?: boolean
  withColours?: boolean

  number: any,
  styleName: any,
  specs: any,
  colour: any,
  colours?: any,
}

const GlobalSpecs: React.FC<P> = ({ 
  withSku = false,
  withStyle = false,
  withSpecs = false,
  withHandle = false,
  withColour = false,
  withColours = false,

  number = false,
  styleName = '',
  specs = false,
  colour = false,
  colours = false,

  ...props
}: P) => {

  return (
    <Container {...props}>
      { withSku &&
        <>
          <Label>No.</Label>
          <Detail>{number}</Detail>
        </>
      }

      { withStyle &&
        <Detail>
          <Label>Style</Label>
          <Detail>{styleName}</Detail>

          { withColour && <>
             <Label>Colour</Label>
              <Detail>{colour}</Detail>
            </>
          }

          { withColours && colours.length > 0 && <>
            <Label>Colours</Label>
            <Detail>
              {colours.map((_colour, index) => 
                <ColoursLink 
                  $isActive={_colour.colour == colour} 
                  href={`/products/${_colour.handle}`} 
                  key={`colour_${index}`}
                >
                  {_colour.colour}
                </ColoursLink>
              )}
            </Detail>
          </>}
        </Detail>
      }

      { withSpecs && (specs.height || specs.width) &&
        <>
          <Label>Specs</Label>
          <DetailTable>
            { specs.height && <><span>H</span> <span>{specs.height}</span></> }
            { specs.width && <><span>W</span> <span>{specs.width}</span></> }
            { specs.depth && <><span>D</span> <span>{specs.depth}</span></> }
          </DetailTable>

          { withHandle && specs.handle && 
            <>
              <Label>Strap</Label>
              <Detail>{specs.handle}</Detail>
            </>
          }
        </>
      }
    </Container>
  )
}

const Container = styled.div`
  ${TypeBodyM};
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 8px 8px;
  margin-bottom: auto;
  margin-right: auto;
`

const Label = styled.span`
  ${TypeCaptionM};
  line-height: inherit;
  grid-column: 1 / 2;
`

const Detail = styled.span`
  grid-column: 2 / 3;
  display: grid;
  gap: 0 10px;
`

const DetailTable = styled(Detail)`
  grid-template-columns: 10px 1fr;
`

const ColoursLink = styled(Link)<{$isActive: boolean}>`
${props => props.$isActive && css`
  pointer-events: none;
`}

  ${props => !props.$isActive && css`
    opacity: 0.5;
  `}

  ${Hover} {
    opacity: 1;
  }
`

export default GlobalSpecs
import {
  convertShopifyPathIdToNumber,
  convertShopifyGidToNumber,
} from "./shopify/transform";
// Document type for all incoming synced Shopify products
export const SHOPIFY_PRODUCT_DOCUMENT_TYPE = "product";
export const SHOPIFY_COLLECTION_DOCUMENT_TYPE = "collection";

// Prefix added to all Sanity product document ids
export const SHOPIFY_PRODUCT_DOCUMENT_ID_PREFIX = "product-";
export const SHOPIFY_COLLECTION_DOCUMENT_ID_PREFIX = "collection-";

/**
 * Map Shopify product ID number to a corresponding Sanity document ID string
 * e.g. 12345 => product-12345
 */
export function getDocumentProductId(productId: string) {
  return `${SHOPIFY_PRODUCT_DOCUMENT_ID_PREFIX}${productId}`;
}

export function getDocumentCollectionId(collectionId: string) {
  return `${SHOPIFY_COLLECTION_DOCUMENT_ID_PREFIX}${collectionId}`;
}

// TODO: Maybe don't assume that the sanity id always formatted this way?
export function convertSanityRefToShopifyProductNumber(ref: string) {
  return parseInt(ref?.replace("drafts.", "")?.replace("product-", ""));
}

export function convertShopifyIdToSanityRef(shopifyId: string) {
  const number = convertShopifyGidToNumber(shopifyId);
  if (number && shopifyId.indexOf("Product") > -1)
    return getDocumentProductId(number);
  if (number && shopifyId.indexOf("Collection") > -1)
    return getDocumentCollectionId(number);
  return null;
}

export const sanityImageBuilder =
  ({ ratio }: { ratio: number | null }) =>
  (imageUrlBuilder: any, sourceOptions: any) => {
    // Get width
    const finalWidth = Math.min(
      sourceOptions.width ||
        Math.min(sourceOptions.originalImageDimensions.width, 400),
      1920
    );

    // Allow for overriding ratio
    let height = Math.round(
      finalWidth / sourceOptions.originalImageDimensions.aspectRatio
    );

    if (ratio) height = Math.round(finalWidth / ratio);

    return imageUrlBuilder
      .width(finalWidth)
      .height(height)
      .quality(sourceOptions.quality || 90)
      .crop("entropy")
      .fit("crop");
  };

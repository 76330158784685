import Link from 'next/link'
import styled from 'styled-components'
import { TypeBodyM } from '../../styles/mixins'
import LinkRender from '../link/LinkRender'

import SanityImage from "../sanity/SanityImage"
import SanityMedia from '../sanity/SanityMedia'

interface P {
  feature: any
  [key: string]: any
}
const HeaderSubmenuFeature: React.FC<P> = ({ 
  feature,
  ...props
}: P) => {

  if(!feature?.link?.href) return <></>

  return (
    <LinkWrap href={feature.link.href} {...props}>
      <SanityMedia media={feature.media} ratio="2x3" autoplay={true} />
      <Title>{feature.link.title}</Title>
    </LinkWrap>
  )
}

const LinkWrap = styled(LinkRender)``

const Title = styled.h3`
  ${TypeBodyM};
  margin-top: 8px;
`

export default HeaderSubmenuFeature
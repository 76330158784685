import { subscribe } from "klaviyo-subscribe";

interface SubmitNewsletterProps {
  input: {
    email: string;
    firstName?: string;
    lastName?: string;
    country?: string;
  };
  listId?: string;
}

export async function submitNewsletter({
  input,
  listId,
}: SubmitNewsletterProps): Promise<{ success?: boolean; errors?: string }> {
  try {
    const options = {
      ...(input.firstName ? { $first_name: input.firstName } : {}),
      ...(input.lastName ? { $last_name: input.lastName } : {}),
      ...(input.country ? { $country: input.country } : {}),
    };

    await subscribe(
      listId || process.env.NEXT_PUBLIC_KLAVIYO_LIST_ID,
      input.email,
      options
    );

    return {
      success: false,
    };
  } catch (e: any) {
    console.error(e);
    throw e;
  }
}

export {};

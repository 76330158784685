import { Columns } from "frontend/src/styles/functions";
import { collectionGrid } from "frontend/src/utils/collection";
import { useState } from "react";
import styled, { css } from "styled-components";
import ProductCard from "./ProductCard";

interface Props {
  layout: "XS" | "S" | "M" | "L" | "SUBMENU" | "OVERVIEW";
  products: any;
  className?: string;
}

const ProductCardList: React.FC<Props> = ({
  products,
  layout,
  className,
}: Props) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <Container
      $layout={layout}
      className={className}
      onMouseLeave={() => setActiveIndex(-1)}
    >
      {products.map((product, index: number) => (
        <ProductCard
          product={product.product || product}
          index={index}
          layout={layout}
          key={product._key || product._id || index}
          setActiveIndex={setActiveIndex}
          activeIndex={activeIndex}
        />
      ))}
    </Container>
  );
};

ProductCardList.displayName = "ProductCardList";

const Container = styled.div<{ $layout: string }>`
  ${(props) =>
    props.$layout == "OVERVIEW" &&
    css`
      display: flex;
      flex-wrap: wrap;
      gap: 16px var(--gutter);

      ${collectionGrid.map(
        (columnCount, index) => css`
          .product-overview-image:nth-child(
              ${collectionGrid.length}n + ${index + 1}
            ) {
            width: ${Columns(columnCount)};
          }
        `,
      )}
    `}
`;

export default ProductCardList;

import { FC, ReactNode, useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import { useIntersection } from "react-use";
import styled, { css } from "styled-components";
import { ratioToNumber } from "library/utils/image";

interface Props {
  controls?: boolean;
  url: string;
  className?: string;
  autoplay?: boolean;
  muted?: boolean;
  loop?: boolean;
  pause?: ReactNode;
  play?: ReactNode;
  poster?: string;
  ratio: string;
  layout?: "fill" | "responsive";
}

const GlobalVideo: FC<Props> = ({
  className,
  controls,
  autoplay,
  pause,
  play,
  poster,
  url,
  ratio,
  layout = "responsive",
  ...rest
}) => {
  const videoElement = useRef<HTMLVideoElement>(null);
  const intersection = useIntersection(videoElement, {});
  const [playing, setPlaying] = useState(false);
  const [paused, setPaused] = useState(false);

  useEffect(() => {
    if (!(/\.m3u8/.test(url) && videoElement.current)) return;
    if (videoElement.current.canPlayType("application/vnd.apple.mpegurl")) {
      videoElement.current.src = url;
    } else if (Hls.isSupported()) {
      const hls = new Hls({
        capLevelToPlayerSize: true,
        capLevelOnFPSDrop: true,
        maxBufferLength: 5,
        // maxBufferSize: 30,
      });
      hls.loadSource(url);
      hls.attachMedia(videoElement.current);
    }
  }, [url]);

  useEffect(() => {
    async function onIntersection() {
      if (!videoElement.current || paused) return;

      try {
        if (intersection?.isIntersecting) {
          await videoElement.current.play();
        } else {
          await videoElement.current.pause();
        }
      } catch (error) {
        console.log("Error playing or pausing video:", error);
      }
    }

    onIntersection();
  }, [intersection?.isIntersecting, paused, videoElement]);

  // async function togglePlay() {
  //   if (!videoElement.current) return
  //   if (playing) {
  //     videoElement.current.pause()
  //     setPaused(true)
  //   } else {
  //     await videoElement.current.play()
  //     setPaused(false)
  //   }
  // }

  return (
    <Container
      className={className}
      $ratio={ratio && ratioToNumber(ratio)}
      $layout={layout}
    >
      <Video
        src={url}
        {...rest}
        {...(autoplay && { autoPlay: true, loop: true, muted: true })}
        onPlaying={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        ref={videoElement}
        controls={controls}
        poster={poster}
        playsInline
      />
      {/* {controls && (
        <button
          aria-label={playing ? 'Pause video' : 'Play video'}
          onClick={togglePlay}
        >
          {playing ? (pause ? pause : 'Pause') : play ? play : 'Play'}
        </button>
      )} */}
    </Container>
  );
};

const Container = styled.div<{ $ratio; $layout }>`
  position: relative;
  width: 100%;

  button {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 10;

    display: flex;
    align-items: flex-end;
  }

  path,
  rect,
  path {
    stroke: var(--color-background);
  }

  ${(props) =>
    props.$ratio &&
    css`
      aspect-ratio: ${props.$ratio};
      object-fit: cover;
      object-position: center;
    `}

  ${(props) =>
    props.$layout == "fill" &&
    css`
      position: absolute;
      height: 100%;
      object-fit: cover;
      object-position: center;
    `}
`;

const Video = styled.video`
  width: 100%;
`;

export default GlobalVideo;

import styled, { css } from 'styled-components'

import { useProduct } from '../../contexts/ProductProvider'
import usePurchase from '../../hooks/usePurchase'
import { Breakpoint, MobileBreakpoint } from '../../styles/functions'
import { TypeBodyM } from '../../styles/mixins'

import ButtonPrimary from "../button/ButtonPrimary"
import ProductOptions from './ProductOptions'
import ProductPrice from './ProductPrice'

interface P {
  [key: string]: any
}
const ProductBuy: React.FC<P> = ({
  ...props
}: P) => {
  
  const { isAdding, isSingleVariant, selectedVariant, } = useProduct();
  const { addToCart } = usePurchase();

  const disabled = !selectedVariant?.availableForSale;

  return (
    <form onSubmit={addToCart} {...props}>
      { !isSingleVariant &&  <ProductOptions /> }
      <Button disabled={disabled}>
        { 
          disabled ? 
            'Sold Out' : 
            isAdding ? 
              "Adding to Cart" : 
              "Add to Cart" 
        }
        <Price variant={selectedVariant} />
      </Button>
    </form>
  )
}

const Button = styled(ButtonPrimary)`
  width: 100%;
  ${MobileBreakpoint} {
    ${TypeBodyM};
    padding: 12px var(--inset);
    justify-content: space-between;
  }
`

const Price = styled(ProductPrice)`
  margin: 0 auto 0 30px;
  ${Breakpoint('mobile', 'min')} {
    display: none;
  }
`

export default ProductBuy

import styled, { css } from 'styled-components'
import SvgClose from 'frontend/src/components/svg/SvgClose'
import { TypesetCaptionL } from '../typeset/Typeset'
import { Hover, TransitionDefault } from '../../styles/mixins'
import { useSettings } from '../../contexts/SettingsProvider'
import SanityContent from '../sanity/content/SanityContent'
import TransitionFade from '../transition/TransitionFade'
import { useAcceptCookies } from '../../hooks/useAcceptCookies'
import { Breakpoint, MobileBreakpoint } from '../../styles/functions'

function OverlayCookies() {
  const settings = useSettings();
  const {isOpen, close} = useAcceptCookies();

  return (
      <Container $isOpen={isOpen}>
        <Inner>

          <Message>
            <SanityContent 
              layout="DEFAULT" 
              blocks={settings.overlayCookies.content} 
            />
          </Message>

          <Accept onClick={close}>
            <SvgClose />
          </Accept>

        </Inner>
      </Container>
  )
}

const Container = styled.section<{$isOpen}>`
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 9040;
  transition: opacity 0.5s ease;
  pointer-events: none;
  opacity: 0;

  ${props => props.$isOpen && css`
    opacity: 1;
  `}

  ${MobileBreakpoint} {
    bottom: 0;
  }
`

const Inner = styled.div`
  width: 420px;
  margin: 0 auto;
  opacity: 1;
  display: flex;
  pointer-events: auto;

  ${MobileBreakpoint} {
    width: 100%;
  }
`

const Message = styled(TypesetCaptionL)`
  background: var(--color-charcoal100);
  padding: 8px 16px;
  margin-left: auto;
  
  ${MobileBreakpoint} {
    flex-grow: 1;
  }
`

const Accept = styled.button`
  display: inline-flex;
  background: var(--color-charcoal100);
  margin-left: 4px;
  padding: 11px;
  margin-bottom: auto;
  margin-right: auto;

  ${MobileBreakpoint} {
    margin-left: 0;
    margin-right:0;
  }

  svg {
    ${TransitionDefault}
    width: 10px;
  }

  ${Hover} {
    svg {
      opacity: 0.5;
    }
  }
}
`

export default OverlayCookies

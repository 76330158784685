import {
  CartFieldsFragment,
  CartLineFieldsFragment,
  CollectionEdge,
  ProductFieldsFragment,
  VariantFieldsFragment,
} from "../../../services/shopify";
import {
  getBaseUrlFromLocale,
  getFirstVariant,
  getNumberFromProduct,
  getOriginalSrcFromProduct,
  getOriginalSrcFromCartLine,
} from "../getters";
import {
  constructShopifyImageUrlLarge,
  constructShopifyImageUrlThumb,
} from "..";

export function transformKlaviyoFeedProducts(
  locale: any,
  shopifyProducts: any[]
) {
  return shopifyProducts.map((shopifyProduct) => {
    const productNumber = getNumberFromProduct(shopifyProduct);
    const originalImageUrl = getOriginalSrcFromProduct(shopifyProduct);

    return {
      id: productNumber,
      title: shopifyProduct.title,
      link: `${getBaseUrlFromLocale(locale)}/products/${shopifyProduct.handle}`,
      description: shopifyProduct.description,
      price: shopifyProduct.priceRange.minVariantPrice.amount,
      image_link: constructShopifyImageUrlThumb(originalImageUrl),
      categories: shopifyProduct?.collections?.edges.map(
        (c: CollectionEdge) => c.node.title
      ),
      inventory_quantity: shopifyProduct.totalInventory,
      inventory_policy: 1,
    };
  });
}

export function transformklaviyoViewProduct({ locale, shopifyProduct }: any) {
  const variant = getFirstVariant(shopifyProduct);
  const originalImageUrl = getOriginalSrcFromProduct(shopifyProduct);
  const minPrice = parseFloat(shopifyProduct.priceRange.minVariantPrice.amount);
  const comparePrice =
    parseFloat(shopifyProduct.compareAtPriceRange.minVariantPrice.amount) ||
    minPrice;
  const categories = shopifyProduct.collections?.edges.map(
    (c: CollectionEdge) => c.node.title
  );
  const url = `${getBaseUrlFromLocale(locale)}/products/${
    shopifyProduct.handle
  }`;
  const productNumber = getNumberFromProduct(shopifyProduct);

  return {
    ProductName: shopifyProduct.title,
    ProductID: productNumber,
    SKU: variant.sku,
    Categories: categories,
    ImageURL: constructShopifyImageUrlLarge(originalImageUrl),
    URL: url,
    Brand: shopifyProduct.vendor,
    Price: minPrice,
    CompareAtPrice: comparePrice,
  };
}

function transformKlaviyoAddToCartItem({
  locale,
  item,
}: {
  locale: any;
  item: CartLineFieldsFragment;
}) {
  const shopifyProduct = item.merchandise.product;
  if (!shopifyProduct) return null;

  const productNumber = getNumberFromProduct(shopifyProduct);
  const url = `${getBaseUrlFromLocale(locale)}/products/${
    shopifyProduct?.handle
  }`;
  const originalImageUrl = getOriginalSrcFromCartLine(item);
  const categories: string[] = []; //shopifyProduct?.collections?.edges.map((c) => c.node.title)

  return {
    ProductID: productNumber,
    ProductName: item.merchandise?.product.title,
    SKU: item.merchandise?.sku,
    Quantity: item.quantity,
    ItemPrice: item.merchandise.price.amount,
    RowTotal: item.estimatedCost.totalAmount.amount,
    ProductURL: url,
    ImageURL: constructShopifyImageUrlLarge(originalImageUrl),
    ProductCategories: categories,
  };
}

export interface transformKlaviyoAddToCartProductArguments {
  shopifyProduct: ProductFieldsFragment;
  shopifyVariant: VariantFieldsFragment;
  quantity: number;
  cart: CartFieldsFragment;
  locale: string;
}

export function transformKlaviyoAddToCartProduct({
  shopifyProduct,
  shopifyVariant,
  quantity,
  cart,
  locale,
}: transformKlaviyoAddToCartProductArguments) {
  // const variants = shopifyProduct.variants.edges.map((v) => v.node)
  // const variant = variants.find((v) => v.availableForSale) || variants[0]
  // const minPrice = parseFloat(shopifyProduct.priceRange.minVariantPrice.amount);
  // const comparePrice = parseFloat(shopifyProduct.compareAtPriceRange.minVariantPrice.amount) || minPrice
  const originalImageUrl = getOriginalSrcFromProduct(shopifyProduct);
  const categories: string[] = shopifyProduct.collections.edges.map(
    (collection) => collection.node.title
  );
  const url = `${getBaseUrlFromLocale(locale)}/products/${
    shopifyProduct.handle
  }`;

  return {
    $value: parseFloat(shopifyVariant.price.amount) * quantity,
    AddedItemProductName: shopifyProduct.title,
    AddedItemProductID: getNumberFromProduct(shopifyProduct),
    AddedItemSKU: shopifyVariant.sku,
    AddedItemCategories: categories,
    AddedItemImageURL: constructShopifyImageUrlLarge(originalImageUrl),
    AddedItemURL: url,
    AddedItemPrice: shopifyVariant.price.amount,
    AddedItemQuantity: quantity,
    // "ItemNames": ["Winnie the Pooh", "A Tale of Two Cities"],
    CheckoutURL: cart.checkoutUrl,
    Items: cart.lines.edges
      .filter((item) => item.node.__typename == "CartLine")
      .map((item) =>
        transformKlaviyoAddToCartItem({
          locale,
          item: item.node as CartLineFieldsFragment,
        })
      ),
  };
}

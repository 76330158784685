function SvgLogo({...props}) {
  return (
    <svg width="60" height="16" viewBox="0 0 60 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15.9389 10.7577V0H12.9043V10.9739C12.9043 14.073 14.9071 16 17.9266 16C20.946 16 22.6985 14.073 22.6985 10.9739V0H19.9218V10.7577C19.9218 13.0678 18.7118 13.4623 17.9266 13.4623C17.1414 13.4623 15.9199 13.083 15.9199 10.7577" fill="#414042"/>
      <path d="M8.31483 0L5.73162 7.2072L2.99288 0H0L3.92982 10.4049V15.8445H6.94546L6.95684 10.348L10.8753 0H8.31483Z" fill="#414042"/>
      <path d="M46.0625 0V15.8407H53.4669V13.5799H48.8392V8.73588H52.9055V6.58131H48.8392V2.26078H53.4669V0H46.0625Z" fill="#414042"/>
      <path d="M59.4336 0H56.418V15.8407H59.4336V0Z" fill="#414042"/>
      <path d="M39.1379 0L36.7065 10.7198L34.2712 0H30.5234V15.8407H33.0611V5.87197L35.3257 15.8407H37.8634L40.1128 5.93266V15.8407H42.8895V0H39.1379Z" fill="#414042"/>
    </svg>

  )
}

export default SvgLogo;
import { findNearestDefaultImageRatio } from 'library/utils/image';
import styled from 'styled-components';
import SanityImageWithCaption from './../SanityImageWithCaption';

interface Props {
  className?: string,
  images: any
  
}

const SanityContentImagePair: React.FC<Props> = ({ 
  images, 
  className,
  ...props 
}: Props) => {
    const {image1, image2} = images;

  const [
    ratio1,
    ratio2,
  ] = [
    findNearestDefaultImageRatio(image1?.image?.asset?.metadata?.dimensions?.aspectRatio),
    findNearestDefaultImageRatio(image2?.image?.asset?.metadata?.dimensions?.aspectRatio),
  ];

    return (
      <Content className={className}>
        <SanityImageWithCaption 
          ratio={ratio1} 
          image={image1.image} 
          caption={image1.caption} 
        />
        <SanityImageWithCaption 
          ratio={ratio2} 
          image={image2.image} 
          caption={image2.caption} 
        />
      </Content>
  )
}

const Content = styled.div`
  
`

const Caption = styled.p`
`

export default SanityContentImagePair
import styled from "styled-components";
import ButtonUnderline from "../button/ButtonUnderline";
import ButtonPrimary from "../button/ButtonPrimary";

import FormInputEmail from "./FormInputEmail";
import { FormControls, FormErrors, FormSuccess } from "./FormStyles";
import useFormRecover from "../../hooks/useFormRecover";

interface P {
  setMode: any;
  [key: string]: any;
}
const FormRecover: React.FC<P> = ({ setMode, ...props }: P) => {
  const { disabled, errors, onSubmit, register, submitErrors, successMessage } =
    useFormRecover();

  return (
    <Container onSubmit={onSubmit} {...props}>
      {successMessage != "" && (
        <FormSuccess style={{ marginBottom: "var(--spacing-16)" }}>
          {successMessage}
        </FormSuccess>
      )}

      {submitErrors && (
        <FormErrors style={{ marginBottom: "var(--spacing-16)" }}>
          {submitErrors.join(", ")}
        </FormErrors>
      )}

      <FormInputEmail
        error={errors.email}
        register={register}
        required
        style={{
          marginBottom: "var(--spacing-32)",
        }}
      />

      <FormControls>
        <ButtonPrimary disabled={disabled}>Recover your account</ButtonPrimary>
        <ButtonUnderline onClick={() => setMode("LOGIN")}>
          Back to login
        </ButtonUnderline>
      </FormControls>
    </Container>
  );
};

const Container = styled.form``;

export default FormRecover;

import styled from "styled-components";
import UtilWrapper from "frontend/src/components/util/UtilWrapper";
import { TypeHeadingS } from "../../styles/mixins";
import { DefaultComponentInterface } from "../../types/components";

const PageMissing: DefaultComponentInterface<{
  error?: any;
}> = () => {
  return (
    <Container>
      <Content>Sorry, we could not load that page right now.</Content>
    </Container>
  );
};

const Container = styled(UtilWrapper)`
  margin-top: var(--spacing-page-top);
  margin-bottom: var(--spacing-200);
`;

const Content = styled.div`
  ${TypeHeadingS};
`;

export default PageMissing;

// import { sanity } from 'library/services';
import { useContext, createContext  } from 'react';
 
type State = any[]
interface NavigationProviderProps {
  navigation: any[],
  children: React.ReactNode
}


const initialState = [];

export const NavigationContext = createContext<State>(initialState);

export function useNavigation() {
  const context = useContext(NavigationContext)
  if (context === undefined) {
    // throw new Error(`use must be used within a NavigationProvider`)
  }
  return context
}

const NavigationProvider: React.FC<NavigationProviderProps> = ({ 
  children, navigation, 
}: NavigationProviderProps) => {

  return (
    <NavigationContext.Provider value={navigation}>
      {children}
    </NavigationContext.Provider>
  )
}

export default NavigationProvider
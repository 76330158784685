import { useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import styled, { css } from 'styled-components'
import { useCart } from '../../contexts/CartProvider'
import ButtonCheckbox from '../button/ButtonCheckbox'
import InputTextArea from '../input/InputTextArea'
import TransitionAccordion from '../transition/TransitionAccordion'

interface P {
  checkoutActive: boolean
  checkoutRequired: boolean
  setCheckoutActive: any
}
const CartControls: React.FC<P> = ({ 
  checkoutActive,
  checkoutRequired,
  setCheckoutActive,
  ...props
}: P) => {
  const { cart, cartNoteUpdate } = useCart();
  const [ noteActive, setNoteActive ] = useState(false);
  const [ isEditing, setIsEditing ] = useState(false);
  const [ noteText, setNoteText ] = useState('');
  const [ noteTextDebounced, setNoteTextDebounced ] = useState('');

  useEffect(() => {
    if(!noteActive && cart?.note && cart?.note != '') setNoteActive(true);
    if(cart?.note && cart.note != noteTextDebounced) setNoteText(cart.note);
  }, [cart?.note]);
    
  useDebounce(
    () => {
      if(!isEditing) return;
      // TODO: Run this only when being edited
      setNoteTextDebounced(noteText);
      cartNoteUpdate(noteText);
    },
    2000,
    [noteText, isEditing]
  );

  async function toggleNote() {
    if(noteActive && noteText != '') {
      // Do nothing
    } else {
      setNoteActive(!noteActive)
    }
  }

  return (
    <Container {...props}>
      <Top>
        <ButtonCheckbox 
          onClick={toggleNote} 
          isActive={noteActive} 
        >
          Add a note
        </ButtonCheckbox>

        <TransitionAccordion isOpen={noteActive || noteText != ''}>
          <TextWrap>
            <Text 
              text={noteText} 
              setText={({ currentTarget }) => {
                setIsEditing(true);
                setNoteText(currentTarget.value);
              }} 
              placeholder="Add Message..."
            />
          </TextWrap>
        </TransitionAccordion>
      </Top>
      <div>
        <Checkbox 
          onClick={() => setCheckoutActive(!checkoutActive)} 
          isActive={checkoutActive} 
          $required={checkoutRequired}
        >
          <span>I am responsible for any import duties</span>
          {checkoutRequired && <span>Required</span>}
        </Checkbox>
      </div>
    </Container>
  )
}

const Container = styled.div`
  padding-bottom: var(--spacing-24);
  padding-top: 8px;
`

const Top = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-charcoal200);
`

const Checkbox = styled(ButtonCheckbox)<{$required}>`
  padding-top: 8px;
  padding-bottom: 8px;

  color: var(--color-foreground);
  width: 100%;
  
  ${props => props.$required && css`
    --color-foreground: var(--color-error);
  `}
`

const Text = styled(InputTextArea)`
  margin-top: 8px;
  height: 8em;
`

const TextWrap = styled.div`
  overflow: hidden;
  height: 100%;
`

export default  CartControls
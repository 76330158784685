// import { TypesetHeadingM } from 'frontend/src/components/typeset/Typeset';
import styled, { css } from 'styled-components'
import { TypeBodyM, TypeHeadingXL } from 'frontend/src/styles/mixins'
import { Breakpoint, Columns, MobileBreakpoint } from 'frontend/src/styles/functions'
import { formatDate } from 'library/services/sanity/helpers';
import SanityImageWithCaption from '../sanity/SanityImageWithCaption';
import { TypesetHeadingM, TypesetHeadingS } from '../typeset/Typeset';
import { useArticle } from '../../contexts/ArticleProvider';
import SanityMedia from '../sanity/SanityMedia';

interface P {
  section: any
}

const ArticleSectionLanding: React.FC<P> = ({ section }: P) => {
  const article = useArticle();
  const date = formatDate(article.publishedAt);

  let media, caption, isPortrait, mode;
  // Deprecated
  if(section?.image?.image) {
    mode = 'deprecated'
    const {image, caption: imageCaption} = section.image;
    media = image;
    caption = imageCaption;
    const aspectRatio = image.asset?.metadata?.dimensions?.aspectRatio;
    isPortrait = aspectRatio < 1;
    // Image
  } else if(section.type == 'image') {
    mode = 'media'
    media = section;
    caption = section.caption;
    const aspectRatio = section.image.asset?.metadata?.dimensions?.aspectRatio
    isPortrait = aspectRatio < 1;
  } else if(section.type == 'video') {
    // Video
    mode = 'media'
    media = section;
    caption = section.caption;
    const [w,h] = section.video.aspectRatio.split(':').map((v) => parseFloat(v))
    isPortrait = w < h;
    // ratio = `${w}x${h}`;
  }


  return (
    <Section>
      <SectionTitle $isPortrait={isPortrait}>{section.title}</SectionTitle>

      <Main
        $isPortrait={isPortrait} >
        <Details
          $isPortrait={isPortrait}
        > 
          <span>
            <time itemProp="datePublished" dateTime={article.publishedAt}>{date}</time>
            <div>Words by {article.author}</div>
          </span>
          
          
          {article.attributions && <Attribution>
            {article.attributions}
          </Attribution>}
        </Details>


        {mode == 'deprecated' && 
          <SectionImage 
            ratio={isPortrait ? "2x3" : "16x9"}
            image={media} 
            caption={caption} 
          />
        }

        {mode == 'media' && 
          <SectionMedia 
            ratio={isPortrait ? "2x3" : "16x9"}
            media={media} 
            caption={caption} 
          />
        }
      </Main>
      
      <SectionText 
        $isPortrait={isPortrait}
        indent={4}
        content={section?.excerpt || []}  
      />
    </Section>
  )
}


const Section = styled.div`
  /* margin-bottom: 80px; */
`

const SectionTitle = styled.h1<{$isPortrait: boolean}>`
  ${TypeHeadingXL};
  white-space: pre-wrap;
  margin-bottom: var(--spacing-40);

  ${(props) => props.$isPortrait && css`
    text-align: center;
  `} 
`

const SectionImage = styled(SanityImageWithCaption)`
  margin-bottom: var(--spacing-40);
`

const SectionMedia = styled(SanityMedia)`
  margin-bottom: var(--spacing-40);
`

const Main = styled.div<{$isPortrait: boolean}>`
  ${TypeBodyM};
  display: grid;
  gap: 0 var(--gutter);

  ${Breakpoint('mobile', 'min')} {
    ${(props) => props.$isPortrait ? css`
      grid-template-columns: ${Columns(7)} ${Columns(10)} ${Columns(7)};
      ` : css`
        margin-left: ${Columns(3,0)};
        width: ${Columns(18)};
    `} 
  }
`

const Details = styled.div<{$isPortrait: boolean}>`
  ${Breakpoint('mobile', 'min')} {
    ${(props) => props.$isPortrait ? css`
      margin-left: ${Columns(2,0)};
      width: ${Columns(4)};
    ` : css`
      width: ${Columns(8)};
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 var(--gutter);
      margin-bottom: var(--spacing-24);
    `} 
  }


  ${MobileBreakpoint} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 var(--gutter);
    margin-bottom: 16px;
  }
`

const Attribution = styled.div`
  ${Breakpoint('mobile', 'min')} {
    margin-top: 1em;
  }
`

const SectionText = styled(TypesetHeadingS)<{$isPortrait: boolean}>`
  margin-bottom: var(--spacing-40);
  
  ${Breakpoint('mobile', 'min')} {
    margin-right: ${Columns(3, 0)};
    margin-left: ${Columns(3, 0)};
  }
`

export default ArticleSectionLanding

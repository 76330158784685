import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import useBreakpoints from '../hooks/useBreakpoints'
// import { ThemeProvider } from 'next-themes'

const COLLECTION_LAYOUT_COOKIE = 'collectionLayout'

type OVERLAYS =
  | ''
  | 'BANNER'
  | 'SHIPPING'
  | 'COOKIES'
  | 'SEARCH'
  | 'ACCOUNT'
  | 'LOCATION'
  | 'CART'
  | 'MMENU'

type ACCOUNT_FORMS = 'LOGIN' | 'REGISTER' | 'RECOVER' | 'ACTIVATE' | 'RESET'

type COLLECTION_MODE = 'OVERVIEW' | 'GRID'

type COLLECTION_LAYOUT = 'OVERVIEW' | 'M' | 'S'

type Action =
  | {
      type: 'HIDE_OVERLAY'
    }
  | {
      type: 'SET_OVERLAY'
      view: OVERLAYS
    }
  | {
      type: 'SET_ACCOUNT_FORM'
      view: ACCOUNT_FORMS
    }
  | {
      type: 'SET_COLLECTION_LAYOUT'
      view: COLLECTION_LAYOUT
    }
  | {
      type: 'SET_COLLECTION_MODE'
      view: COLLECTION_MODE
    }
  | {
      type: 'SET_BACKGROUND_COLOR'
      view: string
    }

export interface State {
  overlay: string
  accountForm: string
  collectionLayout: string
  collectionMode: string
  backgroundColor: string | null
}

const initialState = {
  overlay: 'BANNER',
  accountForm: 'LOGIN',
  collectionMode: 'GRID',
  collectionLayout: 'M',
  backgroundColor: null
}

export const InterfaceContext = React.createContext<State | any>(initialState)

InterfaceContext.displayName = 'InterfaceContext'

function interfaceReducer(state: State, action: Action) {
  switch (action.type) {
    case 'HIDE_OVERLAY': {
      return {
        ...state,
        overlay: '',
      }
    }
    case 'SET_OVERLAY': {
      return {
        ...state,
        overlay: action.view,
      }
    }
    case 'SET_ACCOUNT_FORM': {
      return {
        ...state,
        accountForm: action.view,
      }
    }
    case 'SET_COLLECTION_LAYOUT': {
      return {
        ...state,
        collectionLayout: action.view,
      }
    }
    case 'SET_COLLECTION_MODE': {
      return {
        ...state,
        collectionMode: action.view,
      }
    }
    case 'SET_BACKGROUND_COLOR': {
      return {
        ...state,
        backgroundColor: action.view,
      }
    }
  }
}

export const InterfaceProvider: FC = (props) => {
  const router = useRouter()
  const { breakpoint } = useBreakpoints()

  const [state, dispatch] = React.useReducer(interfaceReducer, initialState)

  const closeOverlay = useCallback(
    () => dispatch({ type: 'HIDE_OVERLAY' }),
    [dispatch]
  )

  const setOverlay = useCallback(
    (view: OVERLAYS) => dispatch({ type: 'SET_OVERLAY', view }),
    [dispatch]
  )

  const toggleOverlay = useCallback(
    (view: OVERLAYS) =>
      state.overlay == view
        ? dispatch({ type: 'SET_OVERLAY', view: '' })
        : dispatch({ type: 'SET_OVERLAY', view }),
    [dispatch, state]
  )

  const setAccountForm = useCallback(
    (view: ACCOUNT_FORMS) => dispatch({ type: 'SET_ACCOUNT_FORM', view }),
    [dispatch]
  )

  const setCollectionLayout = useCallback(
    (view: COLLECTION_LAYOUT) =>
      dispatch({ type: 'SET_COLLECTION_LAYOUT', view }),
    [dispatch]
  )

  const setCollectionMode = useCallback(
    (view: COLLECTION_MODE) => dispatch({ type: 'SET_COLLECTION_MODE', view }),
    [dispatch]
  )

  const setBackgroundColor = useCallback(
    (view: COLLECTION_MODE) => dispatch({ type: 'SET_BACKGROUND_COLOR', view }),
    [dispatch]
  )

  // Get initial layout mode from cookie
  useEffect(() => {
    const cookieValue = Cookies.get(COLLECTION_LAYOUT_COOKIE) as COLLECTION_MODE
    if (cookieValue == 'OVERVIEW' || cookieValue == 'GRID') {
      dispatch({ type: 'SET_COLLECTION_MODE', view: cookieValue })
    }
  }, [])

  // Update layout from layout mode
  useEffect(() => {
    if (state.collectionMode == 'OVERVIEW') {
      if (breakpoint != 'mobile' && breakpoint != 'tablet') {
        setCollectionLayout('OVERVIEW')
      } else {
        setCollectionLayout('M')
      }
    }

    if (state.collectionMode == 'GRID') {
      if (breakpoint == 'mobile') setCollectionLayout('S')
      else setCollectionLayout('M')
    }
  }, [breakpoint, state.collectionMode])

  // Open account form based on url
  useEffect(() => {
    if (router.query.action) {
      const { action } = router.query
      if (action == 'LOGIN') {
        setTimeout(() => {
          setOverlay('ACCOUNT')
          setAccountForm('LOGIN')
        }, 2000)
      }

      if (action == 'REGISTER') {
        setTimeout(() => {
          setOverlay('ACCOUNT')
          setAccountForm('REGISTER')
        }, 2000)
      }

      if (action == 'ACTIVATE') {
        setTimeout(() => {
          setOverlay('ACCOUNT')
          setAccountForm('ACTIVATE')
        }, 2000)
      }

      if (action == 'RECOVER') {
        setTimeout(() => {
          setOverlay('ACCOUNT')
          setAccountForm('RECOVER')
        }, 2000)
      }

      if (action == 'RESET') {
        setTimeout(() => {
          setOverlay('ACCOUNT')
          setAccountForm('RESET')
        }, 2000)
      }
    }
  }, [router.query])

  const value = useMemo(
    () => ({
      ...state,
      closeOverlay,
      setOverlay,
      toggleOverlay,
      setAccountForm,
      setCollectionMode,
      setCollectionLayout,
      setBackgroundColor,
    }),
    [state]
  )

  return <InterfaceContext.Provider value={value} {...props} />
}

export const useInterface = () => {
  const context = React.useContext(InterfaceContext)

  if (context === undefined) {
    throw new Error(`use must be used within a InterfaceProvider`)
  }
  return context
}

export default InterfaceProvider

// export const ManagedInterfaceContext: FC = ({ children }) => (
//   <InterfaceProvider>
//     {children}
//   </InterfaceProvider>
// )

// export default ManagedInterfaceContext;

export const isObjectLiteral = (obj: any): boolean => obj === Object(obj);
export const isArrayLiteral = (arr: any): boolean => arr === Array(arr);

export const wait = (delay = 1000) =>
  new Promise<void>((res) => setTimeout(res, delay));
export const retry: any = async (func: any, limit = 5, retries = 0) => {
  if (retries > limit)
    throw new Error(`Could not fetch item after ${limit} retries`);

  try {
    return func();
  } catch (e: any) {
    console.error(e);
    await wait(4000);
    return retry(func, limit, retries + 1);
  }
};

export function base64FromString(str: string) {
  return Buffer.from(str, "ascii").toString("base64");
}

export function base64ToString(str: string) {
  return Buffer.from(str, "base64").toString("ascii");
}

export const createHashKey = (path: string, object: any) => {
  const key = `${path}\\\\${JSON.stringify(object)}`;
  return base64FromString(key);
};

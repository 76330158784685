import styled from "styled-components";
import { TypeCaptionL } from "../../styles/mixins";
import ShopifyMedia from "../shopify/ShopifyMedia";
import PriceRender from "../price/PriceRender";
import GlobalSpecs from "../global/GlobalSpecs";
import { useProduct } from "src/contexts/ProductProvider";
import CartUpsellProductBuy from "./CartUpsellProductBuy";
import Link from "next/link";
import { useInterface } from "src/contexts/InterfaceProvider";

interface P {
  className?: string;
}
const CartUpsellProduct: React.FC<P> = ({ ...props }: P) => {
  const { shopifyProduct, number, style, colour, specs, selectedVariant } =
    useProduct();
  const { closeOverlay } = useInterface();
  const onClick = () => closeOverlay();

  return (
    <Container {...props}>
      <Link href={`/products/${shopifyProduct.handle}`} onClick={onClick}>
        <ShopifyMedia
          ratio="4x3"
          media={shopifyProduct.media.edges?.[0].node}
        />
      </Link>
      <Details>
        <GlobalSpecs
          withSku={true}
          withSpecs={true}
          withStyle={true}
          withHandle={true}
          withColour={false}
          withColours={false}
          number={number}
          styleName={style}
          specs={specs}
          colour={colour}
        />
        <DetailsFooter>
          <CartUpsellProductBuy />

          <PriceRender
            display="CART"
            amount={parseFloat(selectedVariant.price.amount)}
            currencyCode={selectedVariant.price.currencyCode}
          />
        </DetailsFooter>
      </Details>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 0 var(--gutter);
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-charcoal200);
`;
const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailsFooter = styled.div`
  ${TypeCaptionL};
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export default CartUpsellProduct;

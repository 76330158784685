import LinkInline from "../link/LinkInline";

type Props = {
  link: {
    title: string;
    phone: string;
  };
  children?: React.ReactNode;
  [key: string]: any;
};

const SanityLinkPhone: React.FC<Props> = ({
  children,
  link,
  ...props
}: Props) => {
  return (
    <LinkInline href={`tel:${link.phone}`} {...props}>
      {children || link.title}
    </LinkInline>
  );
};

export default SanityLinkPhone;

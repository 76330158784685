import styled from 'styled-components'
import { TypeBodyM, TypeCaptionM } from '../../styles/mixins';
import PriceRender from '../price/PriceRender';

interface Props {
  variant?: any
  className?: string
}
const ProductPrice: React.FC<Props> = ({ 
  variant,
  className,
}: Props) => {
  return (
    <Container className={className}>
      { 
        variant.compareAtPrice && 
        <Sale amount={variant.compareAtPrice.amount} currencyCode={variant.compareAtPrice.currencyCode} showCurrency={false} />
      }
      <PriceRender amount={variant.price.amount} currencyCode={variant.price.currencyCode} />
    </Container>
  )
}

const Container = styled.div`
  margin-top: 8px;
  ${TypeBodyM}
`

const Sale = styled(PriceRender)`
  color: var(--color-charcoal400);
  text-decoration: line-through;
`

export default ProductPrice
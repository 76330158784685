import { FC } from 'react'
import GlobalVideo from 'frontend/src/components/global/GlobalVideo'
import styled from 'styled-components'

interface SanityVideoProps {
  autoplay?: boolean
  video: any
  ratio: string
  [key: string]: any
}

const SanityVideo: FC<SanityVideoProps> = ({
  autoplay,
  video,
  ratio,
  ...props
}) => {
  return (
    <Video
      muted
      ratio={ratio}
      url={video.streamUrl}
      poster={video.thumbnailUrl}
      {...(autoplay && { autoPlay: true, loop: true, muted: true })}
      {...props}
    />
  )
}

const Wrapper = styled.div`
  display: grid;
`

const Video = styled(GlobalVideo)`
  display: flex;

  video {
    object-fit: cover;
    object-position: center;
  }
`

export default SanityVideo

import { findActiveOptionValue } from 'library/utils/shopify';
import styled, { css } from 'styled-components';
import { useProduct } from '../../contexts/ProductProvider'
import { MobileBreakpoint } from '../../styles/functions';
import { Hover, TypeBodyM, TypeCaptionM } from '../../styles/mixins';


interface P {
  [key: string]: any
}

const ProductOptions: React.FC<P> = ({
  ...props
}: P) => {
  const {
    dispatch,
    shopifyProduct,
    selectedOptions,
  } = useProduct();

  const onClick = ({name, value, event}) => {
    event.preventDefault();
    dispatch({
      type: "SET_OPTIONS",
      value: getMergedOptions({name, value}),
    })
  }

  const getMergedOptions = ({name, value}) => {
    return [
      ...selectedOptions.filter((_optionGroup) => name != _optionGroup.name),
      {
        name,
        value,
      }
    ]
  }

  return (
    <div {...props}>
      {shopifyProduct.options?.length && 
        <Groups>
          { shopifyProduct.options.map((optionGroup, index) => 
              <Group key={`product_optionGroup_${index}`}>
                <Heading>
                  {optionGroup.name}
                </Heading>

                <Options>
                  {
                    optionGroup.values.map((value) => 
                      <Option 
                        $isActive={value == findActiveOptionValue({
                          selectedOptions,
                          name: optionGroup.name, 
                          value,
                        })}
                        onClick={(event) => onClick({name: optionGroup.name, value, event})} 
                        key={`product_optionGroup_${index}_${value}`}
                      >
                        {value}
                      </Option>
                    )
                  }
                </Options>
              </Group>
            )
          }
        </Groups>
      }
    </div>
  )
}

const Groups = styled.div`
  ${MobileBreakpoint} {
    padding: 12px var(--inset);
    justify-content: space-between;
  }
`
const Heading = styled.h3`
  ${TypeCaptionM};
`
const Group = styled.div`
  padding-bottom: var(--spacing-16);
  display: grid;
  grid-template-columns: 40px 1fr;
`
const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`

const Option = styled.button<{$isActive}>`
  ${TypeBodyM};
  padding: 4px 8px;
  border: 1px solid transparent;

  ${props => props.$isActive && css`
    border-color: var(--color-charcoal);
  `}

  ${Hover} {
    border-color: var(--color-charcoal);
  }
`

export default ProductOptions
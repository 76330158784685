import { useHeaderSubmenu } from "../../contexts/HeaderSubmenuProvider";
import { useInterface } from "../../contexts/InterfaceProvider";
import LinkInline from "../link/LinkInline";

type Props = {
  link: {
    title: string;
    href: string;
    altReference?: any;
  };
  children?: React.ReactNode;
  [key: string]: any;
};

const SanityLinkUrl: React.FC<Props> = ({
  children,
  link,
  ...props
}: Props) => {
  const { overlay, closeOverlay } = useInterface();
  const { setReference } = useHeaderSubmenu();

  const onMouseEnter = function () {
    // Make sure reference exists and is loaded correctly
    if (link.altReference && link.altReference?._type != "reference") {
      setReference(link.altReference);
    }
  };

  const onClick = function () {
    // close mmenu overlay if it's open
    if (overlay == "MMENU") closeOverlay();
  };

  return (
    <>
      {link.href == "#" ? (
        <LinkInline
          href={link.href}
          style={{
            pointerEvents: "none",
          }}
          {...props}
        >
          {children || link.title}
        </LinkInline>
      ) : (
        <LinkInline
          href={link.href}
          onMouseEnter={onMouseEnter}
          onClick={onClick}
          {...props}
        >
          {children || link.title}
        </LinkInline>
      )}
    </>
  );
};

export default SanityLinkUrl;

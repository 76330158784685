import { useProduct } from "frontend/src/contexts/ProductProvider";
import { useState } from "react";
import styled from "styled-components";
import Link from "../../link/LinkRender";
import ProductCardHeading from "./ProductCardHeading";
import ProductCardMedia from "./ProductCardMedia";
import ProductCardPrice from "./ProductCardPrice";
import ProductCardWrapper from "./ProductCardWrapper";

interface Props {
  className?: string;
}
const ProductCardL: React.FC<Props> = ({ className }: Props) => {
  const { shopifyProduct, product } = useProduct();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Container className={className} setIsHovered={setIsHovered}>
      <Link href={`/products/${shopifyProduct.handle}`}>
        <ProductCardMedia
          isHovered={isHovered}
          product={product}
          shopifyProduct={shopifyProduct}
        />

        <Details>
          <ProductCardHeading
            withColour={true}
            product={product}
            shopifyProduct={shopifyProduct}
          />

          <ProductCardPrice product={product} shopifyProduct={shopifyProduct} />
        </Details>
      </Link>
    </Container>
  );
};

const Container = styled(ProductCardWrapper)``;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0 var(--gutter);
`;

export default ProductCardL;

function SvgMinus() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="12" y1="8.05566" x2="4" y2="8.05566" stroke="#414042"/>
</svg>


  )
}

export default SvgMinus;
import {
  BaseCartLineEdge,
  CartLineFieldsFragment,
  ProductFieldsFragment,
  ProductVariantEdge,
} from "../../services/shopify";
import { getGoogleProductCategory } from "./gpc";
import { convertShopifyPathIdToNumber } from "./transform";

export function getFirstVariant(shopifyProduct: any) {
  return shopifyProduct.variants?.edges[0]?.node || shopifyProduct.variants[0];
}

export function getVariantsFromProduct(shopifyProduct: any) {
  return shopifyProduct?.variants.edges.map((v: ProductVariantEdge) => v.node);
}

export const getPreviewImageFromProduct = (
  shopifyProduct: ProductFieldsFragment
) => {
  const src = shopifyProduct.media?.edges?.[0]?.node?.previewImage;
  return src;
};

export const getOriginalSrcFromProduct = (
  shopifyProduct: ProductFieldsFragment
) => {
  const src = shopifyProduct.media?.edges?.[0]?.node?.previewImage?.originalSrc;
  return src || "";
};

export const getOriginalSrcFromCartLine = (
  shopifyCartLine: CartLineFieldsFragment
) => {
  const src = shopifyCartLine.merchandise.image?.originalSrc;
  return src || "";
};

export function getLineItemsFromCart(cart: any) {
  return cart.lines?.edges.map((v: BaseCartLineEdge) => v.node);
}

export const getNumberFromProduct = (shopifyProduct: any) => {
  return shopifyProduct?.id
    ? convertShopifyPathIdToNumber(shopifyProduct.id)
    : "";
};

export const getGPCFromProduct = (shopifyProduct: any) => {
  const gpc = shopifyProduct?.googleProductCategory;
  return gpc?.value || getGoogleProductCategory(shopifyProduct.productType);
};

export const getGenderFromProduct = (shopifyProduct: any) => {
  const result = shopifyProduct?.gender;
  return result?.value || "female";
};

export const getAgeGroupFromProduct = (shopifyProduct: any) => {
  const result = shopifyProduct?.ageGroup;
  return result?.value || "adult";
};

export const getBaseUrlFromLocale = (locale: string) => {
  let baseUrl = "https://www.yumeibrand.com";
  if (locale.toUpperCase() !== "NZ")
    baseUrl = `https://${locale.toLowerCase()}.yumeibrand.com`;
  return baseUrl;
};

export function getColoursFromProduct(product: any) {
  const productGroup = product.productGroups?.find(
    (group: any) => group.label == "Colours"
  );

  if (productGroup) {
    return productGroup.products
      .map((_product: any) => {
        return {
          ..._product,
          colour: getStyleFromProductTitle(_product.title || "").colour,
        };
      })
      .sort((a: any, b: any) => a.title > b.title);
  }

  return [];
}

export function getStyleFromProductTitle(title: string) {
  const [style, colour] = title.split(" / ");

  return {
    style,
    colour,
  };
}

export function getStyleFromProduct(shopifyProduct: any) {
  return getStyleFromProductTitle(shopifyProduct.title);
}

export function getSpecsFromProduct(shopifyProduct: any) {
  const findSpec = (spec: string) => {
    return shopifyProduct?.[spec]?.value || false;
  };

  return {
    height: findSpec("specHeight"),
    width: findSpec("specWidth"),
    depth: findSpec("specDepth"),
    handle: findSpec("specHandle"),
  };
}

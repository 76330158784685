import styled from 'styled-components'

interface P {
  text: string,
  setText: (any) => void,
  [key: string]: any
}
const InputText: React.FC<P> = ({ 
  text,
  setText,
  ...props
}: P) => {

  return (
    <Container onChange={setText} value={text} {...props} />
  )
}

const Container = styled.textarea`
  width: 100%;
  padding: 8px;
  border: 1px solid var(--color-charcoal200);
`

export default InputText
import styled, { css, useTheme } from "styled-components";

// import { DefaultSeo } from 'next-seo'
// import { AnimatePresence } from 'framer-motion'
// import { useState } from 'react'

import { LayoutStyles } from "frontend/src/styles/layout";
import HeaderDesktopWrapper from "frontend/src/components/header/HeaderDesktopWrapper";
import HeaderMobileWrapper from "../header/HeaderMobileWrapper";
import FooterDesktop from "../footer/FooterDesktop";
import FooterMobile from "../footer/FooterMobile";
import UtilGrid from "frontend/src/components/util/UtilGrid";

// import { CartProvider } from 'frontend/src/contexts/CartProvider'
// import HeaderMobile from '@components/header/HeaderMobile'
// import FooterDesktop from '@components/footer/FooterDesktop'
// import FooterMobile from '@components/footer/FooterMobile'

// import OverlayMmenu from '@components/overlay/OverlayMmenu'
import OverlayShipping from "frontend/src/components/overlay/OverlayShipping";
import { useInterface } from "../../contexts/InterfaceProvider";
import { useEffect, useState } from "react";
import OverlayCart from "../overlay/OverlayCart";
import OverlayMmenu from "../overlay/OverlayMmenu";
import OverlayLocation from "../overlay/OverlayLocation";
import OverlayAccount from "../overlay/OverlayAccount";
import OverlayCookies from "../overlay/OverlayCookies";
import OverlayNewsletter from "../overlay/OverlayNewsletter";
import OverlaySearch from "../overlay/OverlaySearch";
import OverlayMobileControls from "../overlay/OverlayMobileControls";
import OverlayDetect from "../overlay/OverlayDetect";

import useBuyer from "../../hooks/useBuyer";
import useBreakpoints from "../../hooks/useBreakpoints";
import { useFlags } from "../../hooks/useFlags";
import { usePixel } from "../../hooks/usePixel";
import { useCart } from "../../contexts/CartProvider";
import { useCustomer } from "../../contexts/CustomerProvider";
import { useGA } from "../../hooks/useGA";
import ErrorBoundary from "../ErrorBoundary";

interface Props {
  children: React.ReactNode;
}

const LayoutDefault: React.FC<Props> = ({ children }: Props) => {
  useBuyer();
  usePixel();
  useGA();

  const theme = useTheme();
  const { overlay, backgroundColor } = useInterface();
  const { breakpoint } = useBreakpoints();
  const [headerHeight, setHeaderHeight] = useState(
    breakpoint == "desktop" ? 75 : 56,
  );
  const [vh, setVh] = useState(1);
  const { isDevelopment } = useFlags();
  const { isDetectOverlayActive } = useCart();
  const { isLoggedIn } = useCustomer();

  if (typeof window !== "undefined") {
    useEffect(() => {
      const onHeight = () => setVh(window.innerHeight * 0.01);

      onHeight();
      window.addEventListener("resize", onHeight);
      return () => window.removeEventListener("resize", onHeight);
    }, []);
  }

  const style = {
    "--1vh": `${vh}px`,
    "--header-height": `${headerHeight}px`,
    ...(backgroundColor ? { "--color-background": `${backgroundColor}` } : {}),
  } as React.CSSProperties;

  return (
    <>
      <Container $theme={theme} style={style}>
        <LayoutStyles />

        <HeaderDesktopWrapper setHeaderHeight={setHeaderHeight} />
        <HeaderMobileWrapper setHeaderHeight={setHeaderHeight} />

        <ErrorBoundary>{children}</ErrorBoundary>

        <FooterDesktop />
        <FooterMobile />

        <ErrorBoundary>
          <OverlayCart isActive={overlay == "CART"} />
          <OverlayShipping isActive={overlay == "SHIPPING"} />
          <OverlayLocation isActive={overlay == "LOCATION"} />
          <OverlayAccount isActive={overlay == "ACCOUNT"} />
          <OverlaySearch isActive={overlay == "SEARCH"} />
          <OverlayMmenu isActive={overlay == "MMENU"} />
          <OverlayDetect isActive={isDetectOverlayActive} />
          <OverlayCookies />
          <OverlayNewsletter />
          <OverlayMobileControls />
        </ErrorBoundary>
      </Container>
      {isDevelopment && <UtilGrid />}
    </>
  );
};

const Container = styled.div<{ $theme }>`
  display: flex;
  min-height: calc(100 * var(--1vh));
  justify-content: space-around;
  flex-direction: column;
  background: var(--color-background);
  transition: background-color 0.4s ease;
  --spacing-page-top: calc(var(--header-height) + var(--spacing-24));

  *::selection {
    background: var(--color-charcoal400);
    color: var(--color-white);
  }

  ${(props) =>
    props?.$theme?.backgroundColor &&
    css`
      --color-background: ${props?.$theme?.backgroundColor};
    `}
`;

export default LayoutDefault;

import styled from 'styled-components'
import { TypeHeadingXS } from '../../styles/mixins'
import SanityLink from '../sanity/SanityLinkRender'
import SanityMenu from '../sanity/SanityMenu'
import OverlayMmenuRowAccordion from './OverlayMmenuRowAccordion'

interface P {
  row
  rowState
}
const OverlayMmenuRow: React.FC<P> = ({ 
  row,
  rowState,
  ...props
}: P) => {

  if(row._type == 'link') {
    return <Link link={row} {...props} />
  }

  if(row._type == 'links') {
    return (
      <OverlayMmenuRowAccordion 
        title={row.title} 
        rowState={rowState} 
        {...props} 
      >
        <Menu layout="MMENU" links={row.links}  />
      </OverlayMmenuRowAccordion>
    )
  }

  return <></>
}

const Link = styled(SanityLink)`
  ${TypeHeadingXS};
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid var(--color-charcoal200);
  border-bottom: 1px solid var(--color-charcoal200);
  margin-top: -1px;
`

const Menu = styled(SanityMenu)`
padding-bottom: 24px;
`

export default OverlayMmenuRow
import { useContext, createContext, useCallback, useReducer, useMemo  } from 'react';

type Submenu = "" | "SHOP" | "CLUB" | "ABOUT"
type Reference = false | any

interface HeaderSubmenuProps {
  submenu: Submenu,
  reference: Reference,
  children: React.ReactNode
}

type HeaderSubmenuAction =
    {
      type: 'HIDE'
    }
    | {
        type: 'SHOW'
        submenu: Submenu
    } 
    | {
        type: 'SET_REFERENCE'
        reference: Reference
    } 


type State = any; //{submenu: Submenu}

const initialState: State = { submenu: "", }

export const HeaderSubmenuContext = createContext<State>(initialState);

function submenuReducer(state: State, action: HeaderSubmenuAction) {
  switch (action.type) {
    case 'SHOW': {
      return {
        ...state,
        submenu: action.submenu,
      }
    }
    case 'HIDE': {
      return {
        ...state,
        submenu: ""
      }
    }
    case 'SET_REFERENCE': {
      return {
        ...state,
        reference: action.reference
      }
    }
  }
}

const HeaderSubmenuProvider: React.FC<HeaderSubmenuProps> = (props: HeaderSubmenuProps) => {
  const [ state, dispatch ] = useReducer(submenuReducer, initialState)

  const showSubmenu = useCallback(
    (submenu: Submenu) => dispatch({ type: 'SHOW', submenu  }),
    [dispatch]
  )

  const hideSubmenu = useCallback(
    () => dispatch({ type: 'HIDE' }),
    [dispatch]
  )

  const setReference = useCallback(
    (reference: Reference) => dispatch({ type: 'SET_REFERENCE', reference }),
    [dispatch]
  )

  const value = useMemo(
    () => ({
      ...state,
      showSubmenu,
      hideSubmenu,
      setReference,

    }),
    [state]
  )

  return (
    <HeaderSubmenuContext.Provider value={value} {...props} />
  )
}

export function useHeaderSubmenu() {
  return useContext(HeaderSubmenuContext);
}

export default HeaderSubmenuProvider
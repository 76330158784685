import { useProduct } from "frontend/src/contexts/ProductProvider";
import styled from "styled-components";
import { Columns } from "../../../styles/functions";
import { TypeBodyM } from "../../../styles/mixins";
import ProductSpecs from "../ProductSpecs";
import Link from "../../link/LinkRender";
import ProductCardWrapper from "./ProductCardWrapper";
import { useState } from "react";
import ShopifyImage from "../../shopify/ShopifyImage";
import { getPreviewImageFromProduct } from "library/utils/shopify/getters";

interface Props {
  className?: string;
}

const validateImage = (image) => {
  if (!image) return false;
  if (!image.altText) return false;
  return true;
};

const ProductCardSubmenu: React.FC<Props> = ({ className }: Props) => {
  const { shopifyProduct } = useProduct();
  const [isHovered, setIsHovered] = useState(false);
  const previewImage = getPreviewImageFromProduct(shopifyProduct);

  return (
    <Wrapper className={className} setIsHovered={setIsHovered}>
      <Container href={`/products/${shopifyProduct.handle}`}>
        {previewImage && <ShopifyImage ratio="6x4" image={previewImage} />}

        <Specs
          withSku={true}
          withSpecs={true}
          withStyle={true}
          withHandle={true}
          withColour={true}
          withColours={false}
        />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled(ProductCardWrapper)``;

const Specs = styled(ProductSpecs)`
  ${TypeBodyM};
`;

const Container = styled(Link)`
  display: grid;
  grid-template-columns: ${Columns(3)} 1fr;
  gap: var(--gutter);
`;

export default ProductCardSubmenu;

import { useRouter } from 'next/router';
import { useCustomer } from '../contexts/CustomerProvider'
import useFormAccount from './useFormAccount';

function useFormReset() {
  const router = useRouter();
  const {customerReset} = useCustomer();
  return useFormAccount(async ({
    password,
  }) => {
    const {
      url
    } = router.query;

    if(!url) {
      throw "Incorrect url, please try clicking the link in your reset email"
    }

    // Attempt to reset customer
    await customerReset({
      password,
      resetUrl: url,
    });

    return "Successfully activated"
  })
}

export default useFormReset
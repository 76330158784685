import styled from 'styled-components'

import { useNavigation } from "../../contexts/NavigationProvider";
import { Columns } from '../../styles/functions';
import { Wrapper } from '../../styles/mixins';
import SanityMenu from "../sanity/SanityMenu"
import HeaderSubmenuReference from './HeaderSubmenuReference';

interface P {
  className?: string
  
}
const HeaderSubmenuAbout: React.FC<P> = ({ 
  className,
  ...props
}: P) => {

  const navigation = useNavigation();
  const { 
    features, 
    menu1, 
    menu2, 
    menu3, 
    // loggedInMenu, 
  } = navigation?.find((m) => m._id == "submenu-about");

  return (
    <Submenu className={className}>
      <Container>
        <SanityMenu layout="SUBMENU" links={menu1}  />
        <SanityMenu layout="SUBMENU" links={menu2}  />
        <SanityMenu layout="SUBMENU" links={menu3}  />
        <div></div>
        { features?.map((reference) => (
          <Reference reference={reference} key={reference._key} /> 
        ))}
      </Container>
    </Submenu>
  )
}

const Submenu = styled.div`
/* background: var(--color-background); */
  ${Wrapper};
  padding-top: 24px;
  padding-bottom: 32px;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 
    ${Columns(3)} ${Columns(3)} ${Columns(3)} ${Columns(9)} ${Columns(3)} ${Columns(3)};
  grid-gap: 0 var(--gutter);
`

const Reference = styled(HeaderSubmenuReference)`
  /* grid-column: auto / -1; */
`

export default HeaderSubmenuAbout
import BlockContent from "@sanity/block-content-to-react";
import styled, { css } from "styled-components";

import SanityContentTable from "../content/SanityContentTable";
import SanityContentFootnote from "../content/SanityContentFootnote";
import SanityContentLink from "../content/SanityContentLink";
import SanityContentImage from "../content/SanityContentImage";
import SanityContentImagePair from "../content/SanityContentImagePair";
import {
  Hover,
  Indent,
  TypeCaptionM,
  TypeHeadingS,
  TypeHeadingXS,
} from "../../../styles/mixins";
import {
  Breakpoint,
  Columns,
  MobileBreakpoint,
} from "../../../styles/functions";
import SanityContentComposite from "../content/SanityContentComposite";
import SanityContentVideo from "../content/SanityContentVideo";
import SanityContentButton from "../content/SanityContentButton";
import SanityContentText from "../content/SanityContentText";
import SanityContentAccordion from "../content/SanityContentAccordion";
import SanityContentStockists from "../content/SanityContentStockists";
import ArticleSectionLanding from "../../article/ArticleSectionLanding";
import SanityContentCollection from "../content/SanityContentCollection";
import SanityContentCountdown from "./SanityContentCountdown";

interface Props {
  className?: string;
  blocks: any;
  layout:
    | "BANNER"
    | "ARTICLE"
    | "EDITORIAL"
    | "INFO"
    | "KNOWLEDGE"
    | "STOCKISTS"
    | "OVERLAY"
    | "CLUB_CAPTION"
    | "DEFAULT";
}

const serializers = (layout) => ({
  types: {
    blockCountdown: (props) => {
      return <ContentCountdown end={props.node?.end} />;
    },
    blockFootnote: (props) => {
      return <ContentFootnote blocks={props.node?.content} />;
    },
    blockImage: (props) => {
      return (
        <ContentImage image={props.node?.image} caption={props.node?.caption} />
      );
    },
    blockImagePair: (props) => {
      return <ContentImagePair images={props.node} />;
    },
    blockComposite: (props) => {
      return (
        <ContentComposite
          anchor={props.node?.anchor}
          number={props.node?.number}
          title={props.node?.title}
          content={props.node?.content}
          images={props.node?.images}
          products={props.node?.products}
        />
      );
    },
    blockText: (props) => {
      return (
        <ContentText
          link={props.node?.link}
          notes={props.node?.notes}
          excerpt={props.node?.excerpt}
        />
      );
    },
    blockTable: (props) => {
      return (
        <ContentTable
          layout={layout}
          title={props.node?.title}
          table={props.node?.table}
        />
      );
    },
    blockVideo: (props) => {
      return (
        <ContentVideo video={props.node?.video} caption={props.node?.caption} />
      );
    },
    blockButton: (props) => {
      return <ContentButton link={props.node?.link} />;
    },
    blockAccordion: (props) => {
      return (
        <ContentAccordion
          title={props.node?.title}
          anchor={props.node?.anchor}
          rows={props.node?.rows}
        />
      );
    },
    blockStockistGroup: (props) => {
      return (
        <ContentStockists
          title={props.node?.title}
          anchor={props.node?.anchor}
          regions={props.node?.regions}
        />
      );
    },
    blockCollection: (props) => {
      return (
        <ContentCollection
          title={props.node?.title}
          excerpt={props.node?.excerpt}
          products={props.node?.products}
        />
      );
    },
    articleLanding: (props) => {
      return <ArticleSectionLanding section={props.node} />;
    },
    articleComposite: (props) => {
      return (
        <ContentComposite
          number={props.node?.number}
          title={props.node?.title}
          anchor={props.node?.anchor}
          content={props.node?.content}
          images={props.node?.images}
          products={props.node?.products}
        />
      );
    },
    articleVideo: (props) => {
      return (
        <ContentVideo video={props.node?.video} caption={props.node?.caption} />
      );
    },
    articleImage: (props) => {
      return (
        <ContentImage image={props.node?.image} caption={props.node?.caption} />
      );
    },
    articleButton: (props) => {
      return <ContentButton link={props.node?.link} />;
    },
  },
  marks: {
    annotationLink: (props) => {
      return <ContentLink link={props.mark}>{props.children[0]}</ContentLink>;
    },
    annotationLinkEmail: (props) => {
      return <ContentLink link={props.mark}>{props.children[0]}</ContentLink>;
    },
  },
});

const SanityContent: React.FC<Props> = ({
  blocks,
  layout,
  className,
}: Props) => {
  return (
    <Content
      className={className}
      blocks={blocks}
      serializers={serializers(layout)}
      renderContainerOnSingleChild={true}
      $layout={layout}
    />
  );
};

const ContentComposite = styled(SanityContentComposite)``;
const ContentCountdown = styled(SanityContentCountdown)``;
const ContentFootnote = styled(SanityContentFootnote)``;
const ContentImage = styled(SanityContentImage)``;
const ContentVideo = styled(SanityContentVideo)``;
const ContentImagePair = styled(SanityContentImagePair)``;
const ContentTable = styled(SanityContentTable)``;
const ContentButton = styled(SanityContentButton)``;
const ContentLink = styled(SanityContentLink)``;
const ContentAccordion = styled(SanityContentAccordion)``;
const ContentStockists = styled(SanityContentStockists)``;
const ContentText = styled(SanityContentText)``;
const ContentCollection = styled(SanityContentCollection)``;

const Content = styled(BlockContent)<{ $layout }>`
  word-wrap: break-word;

  ${(props) =>
    props.$layout == "DEFAULT" &&
    css`
    > {
      * + * {
        margin-top: 1em;
      }

      ol {
        list-style: decimal-leading-zero inside;
      }

      ul {
        list-style: disc inside;
      }
    }

    .link-inline > * {
        opacity: 0.5;
        
        ${Hover} {
          opacity: 1;
        }}
      }
  `}

  ${(props) =>
    props.$layout == "ARTICLE" &&
    css`
      > {
        *:not(div) {
          ${TypeHeadingXS};
          ${Indent}
        }

        div {
          margin-top: var(--spacing-120);
          margin-bottom: var(--spacing-120);

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: var(--spacing-200);
          }
        }
      }

      > p {
        ${Breakpoint("mobile", "min")} {
          margin-left: ${Columns(8, 0)};
          width: ${Columns(13, 0)};
        }
      }

      ${ContentImage} {
        ${Breakpoint("mobile", "min")} {
          &.sanity-content-image--portrait {
            margin-left: ${Columns(7, 0)};
            width: ${Columns(10)};
          }

          &.sanity-content-image--landscape {
            margin-left: ${Columns(3, 0)};
            width: ${Columns(18)};
          }
        }
      }

      ${ContentVideo} {
        ${Breakpoint("mobile", "min")} {
          margin-left: ${Columns(3, 0)};
          width: ${Columns(18)};
        }
      }

      ${ContentButton} {
        display: flex;
        justify-content: space-around;
      }

      ${ContentComposite} {
        ${Breakpoint("mobile", "min")} {
          grid-template-columns: ${Columns(4)} ${Columns(3)} ${Columns(9)} ${Columns(
              1,
            )} ${Columns(7)};
        }
      }
    `}

${(props) =>
    props.$layout == "EDITORIAL" &&
    css`
      > {
        *:not(div) {
          ${TypeHeadingS};
          ${Indent}
        }

        div {
          margin-top: var(--spacing-120);
          margin-bottom: var(--spacing-120);

          &:last-child {
            margin-bottom: var(--spacing-200);
          }
        }

        h1,
        h2,
        h3,
        ul {
          ${Breakpoint("mobile", "min")} {
            margin-left: ${Columns(8, 0)};
          }
          margin-bottom: var(--spacing-16);
        }

        * + h1,
        * + h2,
        * + h3 {
          margin-top: var(--spacing-64);
        }
      }

      > p {
        ${Breakpoint("mobile", "min")} {
          margin-left: ${Columns(8, 0)};
          width: ${Columns(15, 0)};
        }
      }

      ${ContentImage} {
        ${Breakpoint("mobile", "min")} {
          margin-left: ${Columns(8, 0)};
          width: ${Columns(10, 0)};
        }
        ${MobileBreakpoint} {
          margin-right: ${Columns(1, 0)};
        }
      }

      ${ContentImagePair} {
        ${Breakpoint("mobile", "min")} {
          margin-left: ${Columns(4, 0)};
          width: ${Columns(20)};
        }
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0 var(--gutter);
      }

      ${ContentFootnote} {
        ${Breakpoint("mobile", "min")} {
          margin-left: ${Columns(8, 0)};
        }
        width: 100%;
        max-width: 280px;
        margin-top: var(--spacing-24);
      }

      ${ContentVideo} {
        ${Breakpoint("mobile", "min")} {
          margin-left: ${Columns(3, 0)};
          width: ${Columns(18)};
        }
      }

      ${ContentButton} {
        display: flex;
        justify-content: space-around;
      }

      ${ContentAccordion} {
        ${Breakpoint("mobile", "min")} {
          margin-left: ${Columns(11, 0)};
          width: ${Columns(10)};
        }
      }
    `}

${(props) =>
    props.$layout == "KNOWLEDGE" &&
    css`
      margin-bottom: var(--spacing-200);

      ${ContentAccordion} {
        ${Breakpoint("mobile", "min")} {
          margin-left: ${Columns(8, 0)};
          width: ${Columns(10)};
        }
        + ${ContentAccordion} {
          margin-top: var(--spacing-40);
        }
      }
    `}

${(props) =>
    props.$layout == "STOCKISTS" &&
    css`
      margin-bottom: var(--spacing-200);

      ${ContentStockists} {
        ${Breakpoint("mobile", "min")} {
          margin-left: ${Columns(8, 0)};
          width: ${Columns(15)};
        }
      }
    `}

  ${(props) =>
    props.$layout == "INFO" &&
    css`
    > {
      * {
        ${TypeHeadingXS};
        ${Indent}
      }
      
      h1,h2,h3,ul {
        margin-left: var(--indent);
      }

      h1,h2,h3 {
        text-transform: uppercase;
        &:before {
          display: none;
        }
      }
      
      * {
      +h1,
      +h2,
      +h3 {
        margin-top: var(--spacing-64);
      }
    }
  `}

${(props) =>
    props.$layout == "CLUB_CAPTION" &&
    css`
      ol {
        counter-reset: list;
        li {
          &:before {
            ${TypeCaptionM};
            content: counter(list, decimal-leading-zero) " ";
            display: inline-flex;
            position: absolute;
            left: -40px;
            top: 2px;
          }
          position: relative;
          counter-increment: list;
          margin-left: 40px;

          + li {
            margin-top: 0.5em;
          }
        }
      }
    `}

${(props) =>
    props.$layout == "BANNER" &&
    css`
      > * {
        display: flex;
        vertical-align: baseline;
        flex-wrap: wrap;
        gap: 0 1ch;
      }
      button {
        display: inline-flex;
      }
    `}
`;

export default SanityContent;

import { ReactNode } from 'react'

interface P {
  providerList: any[],
  children: React.ReactNode
}
const UtilProviders: React.FC<P> = ({ 
  providerList,
  children,
}: P) => {
  const [[Provider, providerProps = {}], ...remainingProviders] = providerList;

  // Return next provider
  if(remainingProviders.length > 0) {
    return (
      <Provider {...providerProps}>
        <UtilProviders providerList={remainingProviders} >
        {children}
        </UtilProviders>
      </Provider>
    );
  } 

  // Return Provider
  return <Provider {...providerProps}>
    {children}
  </Provider>
}

export default UtilProviders
import styled from 'styled-components'
import OverlayBanner from '../overlay/OverlayBanner';
import { useHeaderSubmenu } from '../../contexts/HeaderSubmenuProvider';
import { useMeasure } from 'react-use';
import { useEffect } from 'react';
import { Breakpoint } from '../../styles/functions';
import HeaderMobile from './HeaderMobile';
import UtilWrapper from '../util/UtilWrapper';
import { TypeBodyM } from '../../styles/mixins';
import { useInterface } from '../../contexts/InterfaceProvider';
import { useCart } from '../../contexts/CartProvider';
import { useProduct } from '../../contexts/ProductProvider';
import ErrorBoundary from '../ErrorBoundary';

type Props = {
  setHeaderHeight: any
  className?: string,
  children?: React.ReactNode,
}

const HeaderMobileWrapper: React.FC<Props> = ({
  setHeaderHeight,
}: Props) => {

  const { hideSubmenu } = useHeaderSubmenu();

  const [ measureRef, { height } ] = useMeasure<HTMLDivElement>();

  useEffect(() => {
    if(height > 0) setHeaderHeight(height)
  }, [height]);

  return (
    <ErrorBoundary>
    <Container onMouseLeave={hideSubmenu}>
      <div ref={measureRef}>
        <OverlayBanner />

        <HeaderMobile />
      </div>
    </Container>
    </ErrorBoundary>
  )
}

const Container = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9050;

  ${Breakpoint('tablet', 'min')} {
    display: none;
  }
`

export default HeaderMobileWrapper

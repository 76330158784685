import { createGlobalStyle } from 'styled-components'

import {
  mapCSSVariables,
  mapCSSResponsive,
} from 'frontend/src/utils/styles'

import { Reset, Normalise } from 'frontend/src/styles/normalise'
import { VarColours, VarLayout } from 'frontend/src/styles/variables'
import Fonts from 'frontend/src/styles/fonts'

export const LayoutStyles = createGlobalStyle`
${Reset},
${Normalise}
${Fonts}

:root {
  ${mapCSSResponsive(VarLayout)}

  ${mapCSSVariables(VarColours, 'color')}

  background: var(--color-background);
  color: var(--color-foreground);
}

em {
  font-style: italic;
}

:focus {
  outline: none;
  /* outline: 1px solid rgba(0,0,0,0.2); */
}
`

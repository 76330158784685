import LinkInline from '../link/LinkInline';

type Props = {
  link: {
    title: string,
    email: string,
  },
  children?: React.ReactNode,
  [key: string]: any,
}

const SanityLinkEmail: React.FC<Props> = ({
  children, 
  link,
  ...props
}: Props) => {
  
  return (
      <LinkInline href={`mailto:${link.email}`} {...props}>
        {children || link.title}
      </LinkInline>
  )
}

export default SanityLinkEmail

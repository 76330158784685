import { findNearestDefaultImageRatio } from 'library/utils/image';
import SanityImageWithCaption from './../SanityImageWithCaption';

interface Props {
  className?: string,
  image: any
  caption: any
}

const SanityContentImage: React.FC<Props> = ({ 
  image, 
  caption, 
  className,
  ...props 
}: Props) => {
  const aspectRatio = image.asset?.metadata?.dimensions?.aspectRatio || 1;
  const ratio = findNearestDefaultImageRatio(aspectRatio);

  const klass = `${className} sanity-content-image sanity-content-image--${aspectRatio < 1 ? 'portrait' : 'landscape'}`
  
  return <SanityImageWithCaption 
    ratio={ratio}
    image={image} 
    caption={caption} 
    className={klass} 
  />
}

export default SanityContentImage
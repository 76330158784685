import styled from 'styled-components'

import { useNavigation } from "../../contexts/NavigationProvider";
import { Columns } from '../../styles/functions';
import { Wrapper } from '../../styles/mixins';
import SanityMenu from "../sanity/SanityMenu"
import HeaderSubmenuReference from './HeaderSubmenuReference';

import {useCustomer} from 'frontend/src/contexts/CustomerProvider';

interface P {
  className?: string
  
}
const HeaderSubmenuClub: React.FC<P> = ({ 
  className,
  ...props
}: P) => {

  const navigation = useNavigation();
  const { 
    article, 
    menu1, 
    loggedOutMenu, 
    loggedInMenu, 
  } = navigation?.find((m) => m._id == "submenu-club");

  const {
    isLoggedIn
  } = useCustomer();

  return (
    <Submenu className={className}>
      <Container>
        <SanityMenu layout="SUBMENU" links={menu1}  />
        {
          isLoggedIn ? 
          <SanityMenu layout="SUBMENU" links={loggedInMenu}  />
          :
          <SanityMenu layout="SUBMENU" links={loggedOutMenu}  />
        }
        
        { article && <Reference reference={article} /> }
      </Container>
    </Submenu>
  )
}

const Submenu = styled.div`
  ${Wrapper};
  padding-top: 24px;
  padding-bottom: var(--spacing-32);
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 
    ${Columns(3)} ${Columns(3)} ${Columns(9)} ${Columns(9)};
  grid-gap: 0 var(--gutter);
`

const Reference = styled(HeaderSubmenuReference)`
  grid-column: auto / -1;
`

export default HeaderSubmenuClub
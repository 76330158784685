import { GraphQLClient } from 'graphql-request'
import { getSdk } from './generated'
import { wait } from '../../utils';

const ENDPOINT = process.env.NEXT_PUBLIC_SHOPIFY_API_ENDPOINT!
const ACCESS_TOKEN = process.env.NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN!

export const client = new GraphQLClient(ENDPOINT, {
  headers: { 'X-Shopify-Storefront-Access-Token': ACCESS_TOKEN },
})

export const ShopifySdk = getSdk(client);

// Rate limit function
// const rateLimit = 1;
// const limit = pLimit(rateLimit);

export const rateLimitRequests = async (promises: Promise<any>[]) => {
  // return Promise.all(
  //   promises.map((promise) => limit(() => promise))
  // )

  let results: any[] = [];
  for(let i = 0;i < promises.length;i += 1) {
    const promise = promises[i];
    const result = await promise;
    results = [
      ...results,
      result,
    ];

    // rate limiter
    if(i != promises.length) wait(50);
  }

  return results;
}
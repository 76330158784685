import styled from "styled-components";
import useFormRegister from "../../hooks/useFormRegister";
import ButtonUnderline from "../button/ButtonUnderline";
import ButtonPrimary from "../button/ButtonPrimary";

import FormInputEmail from "./FormInputEmail";
import FormInputPassword from "./FormInputPassword";
import { FormControls, FormErrors, FormSuccess } from "./FormStyles";
import FormInputText from "./FormInputText";
import FormInputCheckbox from "./FormInputCheckbox";

interface P {
  setMode: any;
  [key: string]: any;
}
const FormRegister: React.FC<P> = ({ setMode, ...props }: P) => {
  const { disabled, errors, onSubmit, register, submitErrors, successMessage } =
    useFormRegister();

  return (
    <Container onSubmit={onSubmit} {...props}>
      {successMessage && (
        <FormSuccess style={{ marginBottom: "var(--spacing-16)" }}>
          {successMessage}
        </FormSuccess>
      )}

      {submitErrors && (
        <FormErrors style={{ marginBottom: "var(--spacing-16)" }}>
          {submitErrors.join(", ")}
        </FormErrors>
      )}

      <FormInputText
        error={errors.firstName}
        label="First Name"
        name="firstName"
        register={register}
        required
      />

      <FormInputText
        error={errors.lastName}
        label="Last Name"
        name="lastName"
        register={register}
        required
      />

      <FormInputEmail error={errors.email} register={register} required />

      <FormInputPassword error={errors.password} register={register} required />

      <FormInputCheckbox
        name="acceptsMarketing"
        label="I'd  like to join the Club Yu Mei Newsletter"
        error={errors.acceptsMarketing}
        register={register}
        style={{
          marginTop: "var(--spacing-16)",
          marginBottom: "var(--spacing-32)",
        }}
      />

      <FormControls>
        <ButtonPrimary disabled={disabled}>Register</ButtonPrimary>
        <ButtonUnderline onClick={() => setMode("LOGIN")}>
          Back to login
        </ButtonUnderline>
      </FormControls>
    </Container>
  );
};

const Container = styled.form``;

export default FormRegister;

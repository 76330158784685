import styled from "styled-components";
import { useSlideshow } from "src/contexts/SlideshowProvider";
// import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'

interface P {
  children: React.ReactNode;
  [key: string]: any;
}
const GlobalSlider: React.FC<P> = ({ children, ...props }: P) => {
  const { emblaRef } = useSlideshow();

  return (
    <Slider ref={emblaRef} {...props}>
      <Container className="embla__container">{children}</Container>
    </Slider>
  );
};

const Slider = styled.div`
  overflow: hidden;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  gap: 0;

  > * {
    cursor: grab;
  }
`;

export default GlobalSlider;

import { useState } from 'react'
import styled, { css } from 'styled-components'
import { TypeBodyM } from '../../styles/mixins'
import SanityContent from '../sanity/content/SanityContent'
import SvgMinus from '../svg/SvgMinus'
import SvgPlus from '../svg/SvgPlus'
import TransitionAccordion from '../transition/TransitionAccordion'

interface Props {
  title: string | React.ReactNode
  children?: React.ReactNode
  className?: string
  rowState?: any[]
}
const GlobalAccordionRow: React.FC<Props> = ({ 
  title,
  children,
  className,
  rowState = useState(false)
}: Props) => {
  const [ isOpen, setOpen ] = rowState;

  const onToggle = function() {
    setOpen(!isOpen)
  }

  return (
    <Container className={className}>
      <Heading onClick={onToggle}>
        <span>{title}</span>
        <Icon>
          { isOpen && <SvgMinus /> }
          { !isOpen && <SvgPlus /> }
        </Icon>
      </Heading>

      <TransitionAccordion isOpen={isOpen}>
        <ContentWrap>
          {children}
        </ContentWrap>
      </TransitionAccordion> 
    </Container>
  )
}

const Container = styled.div`
  ${TypeBodyM};
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid var(--color-charcoal200);
  border-bottom: 1px solid var(--color-charcoal200);
  margin-top: -1px;
`

const Icon = styled.div`
  display: flex;
  height: 16px;
`

const Heading = styled.button`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const ContentWrap = styled.div`
  padding-top: var(--spacing-24);
  overflow: hidden;
  max-height: 100%;
`

export default GlobalAccordionRow
import styled from 'styled-components'

export const GridContainer = styled.div<{
  active: boolean
}>`
  position: fixed;
  top: 0;
  height: 100%;
  left: var(--inset);
  width: var(--container);
  z-index: 9999;
  display: flex;
  pointer-events: none;
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
`

export const GridColumns = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  column-gap: var(--gutter);
`

export const GridColumn = styled.div`
  position: relative;
  display: flex;
  opacity: 0.07;
  background: red;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-right: red;
  }
`

import styled, { css } from 'styled-components'
import { useInterface } from '../../contexts/InterfaceProvider'
import { Breakpoint, MobileBreakpoint } from '../../styles/functions'
import { TransitionDefault, TypeBodyM } from '../../styles/mixins'
import SvgClose from '../svg/SvgClose'

interface P {
  isActive: boolean
  setIsActive?:any
  title: string
  children: React.ReactNode
  backgroundColor?: string
  borderColor?: string
  [key:string]: any
}

const OverlayPopupWrap: React.FC<P> = ({ 
  isActive,
  setIsActive,
  title,
  children,
  backgroundColor = 'background',
  borderColor = 'charcoal200',
  ...props
}: P) => {
  const { closeOverlay } = useInterface();

  const onClose = setIsActive ? () => setIsActive(false) : closeOverlay

  return (
    <Wrap $isActive={isActive} {...props}>
      <Container 
        $isActive={isActive} 
        $backgroundColor={backgroundColor}
        $borderColor={borderColor}
      >
        <Title>
          {title}


          <Close onClick={onClose}>
            <SvgClose />
          </Close>
        </Title>
        {children}
      </Container>
      <Background onClick={onClose}  $isActive={isActive} />
    </Wrap>
  )
}

const Wrap = styled.div<{ $isActive }>`
  position: fixed;
  left:0;width: 100vw;
  top:0;height: calc(var(--1vh) * 100);
  z-index: 9999;
  pointer-events: none;
  overflow: hidden;
  display: flex;

  ${props => props.$isActive && css`
    > * {
      pointer-events: auto;
    }
  `}
`

const Background = styled.div<{$isActive}>`
  position: absolute;
  left:0;right:0;
  top:0;bottom:0;
  z-index: -1;
  background: var(--color-charcoal);
  opacity: 0;
  transition: ${TransitionDefault};

  cursor: pointer;

  ${props => props.$isActive && css`
    pointer-events: auto;
    opacity: 0.25;
  `}
`

const Container = styled.div<{ 
  $isActive, 
  $backgroundColor, 
  $borderColor 
}>`
  position: relative;
  margin: auto;
  width: 490px;
  padding: 16px var(--inset) 32px;
  transition: ${TransitionDefault};
  pointer-events: none;
  z-index: 10;
  
  display: flex;
  flex-direction: column;
  opacity: 0;


  ${MobileBreakpoint} {
    max-width: 100%;
    width: 490px;
  }

${props => props.$isActive && css`
  opacity: 1;
  pointer-events: auto;
`}

${props => props.$backgroundColor && css`
  background: var(--color-${props.$backgroundColor});
  --border-color: var(--color-${props.$borderColor});
  --ButtonPrimary-color: var(--color-${props.$backgroundColor});
`}
`

const Title = styled.h3`
  ${TypeBodyM};
  padding-bottom: var(--spacing-24);
  margin-bottom: 8px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  

  ${MobileBreakpoint} {
    margin-bottom: 16px;
  }
`

const Close = styled.button`
  svg {
    width: 12px;
    height: 12px;
  }

  /* ${Breakpoint('mobile', 'min')} { */
    /* display: none */
  /* } */
`

export default OverlayPopupWrap
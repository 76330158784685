// import { useEffect } from 'react'
// import { useRouter } from 'next/router'

import { 
  transformKlaviyoAddToCartProductArguments,
  transformKlaviyoAddToCartProduct,
  transformklaviyoViewProduct,
} from "library/utils/shopify/transform/klaviyo";
import { isServer } from "../utils/env";
// const KLAVIYO_ID = process.env.NEXT_PUBLIC_KLAVIYO_ID

export const klaviyoViewProduct = (context) => {
  if(isServer()) return;
  const object = transformklaviyoViewProduct(context);

  window._learnq = [
    ...(window._learnq || []),
    [
      "track",
      "Viewed Product",
      object,
    ]
  ];
}

export const klaviyoAddToCart = (context: transformKlaviyoAddToCartProductArguments) => {
  if(isServer()) return;
  const object = transformKlaviyoAddToCartProduct(context)

  window._learnq = [
    ...(window._learnq || []),
    [
      "track",
      "Added to Cart",
      object
    ]
  ];
}
import { composeGid, parseGid } from "@shopify/admin-graphql-api-utilities";
import { base64ToString } from "../..";

export function convertShopifyDocumentId(id: number | string) {
  return String(typeof id === "string" ? parseGid(base64ToString(id)) : id);
}

export function convertProductIdToShopifyGid(productId: number) {
  return composeGid("Product", productId);
}

export function convertVariantIdToShopifyGid(variantId: number) {
  return composeGid("ProductVariant", variantId);
}

export function convertShopifyNumberIdToPath(
  id: number,
  type: "Product" | "ProductVariant" = "Product"
) {
  return `gid://shopify/${type}/${id}`;
}

export function convertShopifyPathIdToNumber(gid: string) {
  const result = gid?.match(/shopify\/.*\/([0-9]+)/);
  return `${parseInt(result?.[1] || "")}`;
}

export function convertShopifyGidToNumber(gid: string) {
  return gid?.match(/[^\/]+$/i)?.[0] || "";
}

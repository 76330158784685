import TypesetRender from "./TypesetRender";

const Generator = (textStyle) => {
  function Default({
    caption,
    captionAlign = "BOTTOM",
    content,
    children,
    indent,
    className,
    style,
  }: Omit<TypesetProps, "textStyle">) {
    return (
      <TypesetRender
        indent={indent}
        className={className}
        textStyle={textStyle}
        caption={caption || ""}
        captionAlign={captionAlign}
        content={content}
        style={style}
      >
        {children}
      </TypesetRender>
    );
  }

  return Default;
};

export function TypesetDefault({
  indent,
  children,
  caption,
  captionAlign = "BOTTOM",
  content,
  className,
  textStyle,
  style,
}: TypesetProps) {
  return (
    <TypesetRender
      indent={indent}
      className={className}
      textStyle={textStyle}
      caption={caption || ""}
      captionAlign={captionAlign}
      content={content}
      style={style}
    >
      {children}
    </TypesetRender>
  );
}

export const TypesetHeadingM = Generator("TypeHeadingM");
export const TypesetHeadingS = Generator("TypeHeadingS");
export const TypesetHeadingXS = Generator("TypeHeadingXS");

export const TypesetBodyM = Generator("TypeBodyM");

export const TypesetCaptionL = Generator("TypeCaptionL");
export const TypesetCaptionM = Generator("TypeCaptionM");

// import { mapCSSResponsive } from '@helpers/styles'
import { css } from 'styled-components'
import { Breakpoint } from '../functions';

export * from './animation';
export * from './links';
export * from './text';
export * from './form';

export const Wrapper = css`
  width: var(--container);
  margin-left: var(--inset);
`

export const ScreenReader = css`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`

export const Mobile = Breakpoint('mobile');

export default {}

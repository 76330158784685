import { css } from 'styled-components'

export default css`
@font-face {
  font-family: 'rhymes';
  src: url('/fonts/rhymes-ultralight.woff2') format('woff2'),
    url('/fonts/rhymes-ultralight.woff') format('woff');
  font-display: block;
  font-weight: 300;
}
  @font-face {
    font-family: 'rhymes';
    src: url('/fonts/rhymes-ultralight-italic.woff2') format('woff2'),
      url('/fonts/rhymes-ultralight-italic.woff') format('woff');
    font-display: block;
    font-weight: 300;
    font-style: italic;
  }

  /* @font-face {
    font-family: 'akzidenz-grotesk';
    src: url('/fonts/akzidenz-grotesk-pro.woff2') format('woff2'),
      url('/fonts/akzidenz-grotesk-pro.woff') format('woff');
    font-display: block;
  } */
`

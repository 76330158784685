import styled from 'styled-components';

export const FormControls = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FormErrors = styled.div`
  margin-top: var(--spacing-16);
`

export const FormSuccess = styled.div`
  margin-top: var(--spacing-16);
`
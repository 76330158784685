import NextLink from "next/link";
import { memo } from "react";
import styled from "styled-components";

type Props = {
  href?: string;
  children?: React.ReactNode;
  [key: string]: any;
};

const LinkRender: React.FC<Props> = ({ children, href, ...props }: Props) => {
  const rest = {};

  const isExternal =
    (href?.startsWith("http") &&
      href.indexOf("yumei") < 0 &&
      href.indexOf("localhost") < 0) ||
    href?.startsWith("tel") ||
    href?.startsWith("mailto");

  if (isExternal) {
    rest["rel"] = "noreferrer";
    rest["target"] = "_blank";
  }

  if (href && isExternal) {
    return (
      <a href={href} {...rest} {...props}>
        {children}
      </a>
    );
  }

  if (href && !isExternal) {
    // Disable prefetch on mobile devices
    if (
      typeof window != "undefined" &&
      window?.matchMedia("(hover: none)")?.matches
    ) {
      rest["prefetch"] = false;
    }

    return (
      <Nlink href={href} {...rest} {...props}>
        {children}
      </Nlink>
    );
  }

  return <ButtonLink {...props}>{children}</ButtonLink>;
};

const ButtonLink = styled.button`
  cursor: pointer;
  display: inline-flex;
  vertical-align: baseline;
`;

const Nlink = styled(NextLink)`
  &:focus {
    opacity: 0.5;
  }
`;

export default memo(LinkRender);

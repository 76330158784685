import { useRouter } from "next/router";
import { useEffect } from "react";
import styled from "styled-components";
import { useCart } from "../../contexts/CartProvider";
import { pixelCheckout } from "../../hooks/usePixel";
import { MobileBreakpoint } from "../../styles/functions";
import { TypeBodyM } from "../../styles/mixins";
import ButtonPrimary from "../button/ButtonPrimary";

interface P {
  checkoutActive: boolean;
  checkoutRequired: boolean;
  setCheckoutRequired: any;
}
const CartCheckout: React.FC<P> = ({
  checkoutActive,
  checkoutRequired,
  setCheckoutRequired,
  ...props
}: P) => {
  const { cart } = useCart();
  const router = useRouter();

  useEffect(() => {
    if (checkoutActive) setCheckoutRequired(false);
  }, [checkoutActive, setCheckoutRequired]);

  const onClick = () => {
    if (!checkoutActive) {
      setCheckoutRequired(true);
    } else {
      try {
        pixelCheckout(cart, { cart });
        router.push(cart?.checkoutUrl);
      } catch (e: any) {
        console.log("Checkout", e);
        window.location = cart?.checkoutUrl;
      }
    }
  };

  return (
    <Button onClick={onClick} disabled={checkoutRequired} {...props}>
      Proceed to Checkout
    </Button>
  );
};

const Button = styled(ButtonPrimary)`
  width: 100%;

  ${MobileBreakpoint} {
    margin-left: -16px;
    margin-bottom: -8px;
    width: calc(100% + 32px);
    padding: 12px 16px;
    justify-content: flex-start;
    ${TypeBodyM};
  }
`;

export default CartCheckout;

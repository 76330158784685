import { BezierDefault } from "frontend/src/styles/mixins";
import styled, { css } from "styled-components";
import SanityMedia from "../../sanity/SanityMedia";
import ShopifyImage from "../../shopify/ShopifyImage";
import { getPreviewImageFromProduct } from "library/utils/shopify/getters";
import { useState } from "react";
import { useEffectOnce } from "react-use";

interface P {
  isHovered: boolean;
  product: any;
  shopifyProduct: any;
}
const ProductCardMedia: React.FC<P> = ({
  isHovered,
  product,
  shopifyProduct,
  ...props
}: P) => {
  const previewImage = getPreviewImageFromProduct(shopifyProduct);

  // Only video on devices with hover
  const hoverMedia = product.gallery?.[0];
  const [showHover, setShowHover] = useState(false);
  useEffectOnce(() => {
    if (
      typeof window != "undefined" &&
      !window?.matchMedia("(hover: none)")?.matches
    ) {
      setShowHover(true);
    }
  });

  return (
    <Container {...props}>
      <ShopifyMedia
        image={previewImage}
        alt={shopifyProduct.title}
        ratio="4x3"
      />

      {showHover && hoverMedia && (
        <HoverMedia
          $isHovered={isHovered}
          media={hoverMedia}
          autoplay={true}
          ratio="3x4"
          layout="fill"
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const ShopifyMedia = styled(ShopifyImage)``;

const HoverMedia = styled(SanityMedia)<{ $isHovered }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  opacity: 0;
  object-fit: cover;
  object-position: center;
  transition: opacity 0.3s ${BezierDefault};

  > * {
    min-height: 100%;
  }

  ${(props) =>
    props.$isHovered &&
    css`
      opacity: 1;
    `}

  @media (hover:none) {
    display: none;
  }
`;

export default ProductCardMedia;

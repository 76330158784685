import Cookies from "js-cookie";
import { useEffect, useState } from "react";

type Props = {
  name: string;
  initial?: boolean;
};

export const bannerPreviouslyClosed = (name) => !!Cookies.get(name);

export const useBanner = ({ name, initial = false }: Props) => {
  const [isOpen, setIsOpen] = useState(initial);

  useEffect(() => {
    if (!!Cookies.get(name)) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, []);

  const close = () => {
    setIsOpen(false);
    Cookies.set(name, "closed", { expires: 365 });
  };

  return {
    previouslyClosed: !!Cookies.get(name),
    isOpen,
    close,
  };
};

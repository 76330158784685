import { css } from "styled-components";

export const BezierDefault = `
cubic-bezier(0,0.5,0.48,1)
`;
export const BezierSlide = `
cubic-bezier(0.99, 0, 0.16, 0.99)
`;

export const TransitionDefault = `
all 0.3s ${BezierDefault};
`;

export const TransitionSlide = `
top 0.6s ${BezierSlide};
`;

import useEmblaCarousel from "embla-carousel-react";
import React, { useContext, createContext, useState, useEffect } from "react";

type State = {
  settings: any;
  emblaApi: any;
  emblaRef: any;
  slideIndex: number;
};

interface SlideshowProviderProps {
  settings: any;
  plugins?: any;
  children: React.ReactNode;
}

export const SlideshowContext = createContext<State>({
  settings: {},
  emblaApi: null,
  emblaRef: null,
  slideIndex: 0, // Default selected index
});

export function useSlideshow() {
  return useContext(SlideshowContext);
}

const SlideshowProvider: React.FC<SlideshowProviderProps> = ({
  children,
  settings,
  plugins,
}: SlideshowProviderProps) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: false,
      ...settings,
    },
    plugins || [],
  );

  useEffect(() => {
    if (emblaApi) {
      const onSelect = () => {
        setSlideIndex(emblaApi.selectedScrollSnap());
      };

      emblaApi.on("select", onSelect);
      // return () => emblaApi.off("select", onSelect); // Clean up the event listener
    }
  }, [emblaApi]);

  const value = {
    settings,
    emblaApi,
    emblaRef,
    slideIndex,
  };

  return (
    <SlideshowContext.Provider value={value}>
      {children}
    </SlideshowContext.Provider>
  );
};

export default SlideshowProvider;

import CartUpsellProduct from "./CartUpsellProduct";
import ProductProvider from "src/contexts/ProductProvider";
import { useCartUpsellProducts } from "src/hooks/useCartUpsellProducts";
import styled from "styled-components";

type CartUpsellProps = {
  className?: string;
};

const CartUpsellProducts: React.FC<CartUpsellProps> = ({
  className,
}: CartUpsellProps) => {
  const { shopifyProducts } = useCartUpsellProducts();

  return (
    <Container className={className}>
      {shopifyProducts.map((shopifyProduct) => (
        <ProductProvider
          shopifyProduct={shopifyProduct}
          key={`CartUpsellProducts__product--${shopifyProduct.id}`}
        >
          <CartUpsellProduct />
        </ProductProvider>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default CartUpsellProducts;

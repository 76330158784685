import { forwardRef } from 'react'
import { FieldError, UseFormRegister } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { BezierDefault, CheckboxStyle, TypeBodyM, TypeCaptionL } from '../../styles/mixins'
import SvgDropdown from '../svg/SvgDropdown'

// https://codesandbox.io/s/react-hook-form-typescript-xnb1u?file=/src/components/Input.tsx

interface P {
  name: string
  disabled: boolean
  required: boolean
  label: string
  type: string
  error?: FieldError
  className?: string
  register: UseFormRegister<any>
  [key: string]: any
}

const FormInputRender = forwardRef<any, P>(
  (
    { 
      disabled, 
      required, 
      error, 
      label, 
      name, 
      type = 'text', 
      className,
      register,
      ...rest 
    }: P,
    ref,
  ) => {
    let component;

    // if you won't use select, you can delete this part
    if (type === 'select') {
      component = (
        <>
          <Input
            as="select"
            aria-required={required}
            aria-invalid={!!error}
            disabled={disabled}
            required={required}
            id={name}
            name={name}
            ref={ref}
            {...register}
            {...rest}
          />
          <Dropdown />
        </>
      );
    }

    // if you won't use textarea, you can delete this part
    if (type === 'checkbox') {
      component = (
        <InputCheckbox
          aria-required={required}
          aria-invalid={!!error}
          disabled={disabled}
          required={required}
          id={name}
          name={name}
          type="checkbox"
          ref={ref}
          {...register}
          {...rest}
        />
      );
    }

    // if you won't use textarea, you can delete this part
    if (type === 'textarea') {
      component = (
        <InputArea
          aria-required={required}
          aria-invalid={!!error}
          disabled={disabled}
          required={required}
          id={name}
          name={name}
          ref={ref}
          {...register}
          {...rest}
        />
      );
    }

    // if you won't use input, you can delete this part
    if (type !== 'checkbox' && type !== 'select' && type !== 'textarea') {
      component = (
        <Input
          type={type}
          aria-required={required}
          aria-invalid={!!error}
          disabled={disabled}
          required={required}
          id={name}
          name={name}
          ref={ref}
          {...register}
          {...rest}
        />
      );
    }

    return (
      <Wrapper htmlFor={name} className={className}>
        <InputWrap $type={type}>
          {component}
          <Label>
            {label}
          </Label>
        </InputWrap>
        {error && (
          <Error>
            {error?.message || error.toString()}
          </Error>
        )}
      </Wrapper>
    );
  },
);

FormInputRender.displayName = "FormInputRender"

const Wrapper = styled.label`
display: block;
  margin-bottom: 4px;

  /* ::placeholder {
    color: var(--color-charcoal600);
  } */
`

const Label = styled.span`
  color: var(--color-charcoal);
  display: block;
  position: absolute;
  top: 12px;
  left: 0;
  transform-origin: top left;
  pointer-events: none;
  transition: all 0.1s ${BezierDefault};;
`

const Error = styled.div`
  color: var(--color-grey);
  margin-top: 2px;
`

const InputWrap = styled.div<{$type: string}>`
  position: relative;
  margin-top: 8px;

  ${props => props.$type == 'checkbox' && css`
    cursor: pointer;
  `}
`

const Input = styled.input`
  ${TypeBodyM};
  width: 100%;
  padding: 12px 0 4px;

  border-bottom: 1px solid var(--color-charcoal400);

  &::placeholder {
    visibility: hidden;
  }

  &:not(:placeholder-shown) + ${Label} {
    top: 0px;
    transform: scale(0.7);
  }

  &:focus,
  &:not(:placeholder-shown):not(select) {
    border-color: var(--color-charcoal600);
  }

  &:required + ${Label} {
    &:after {
      content: '*';
    }
  }
`

const InputArea = styled.textarea`
  ${TypeCaptionL};
  width: 100%;
  padding: 8px 8px 4px;
  height: 5em;
  width: 100%;
  max-width: 100%;
  min-height: 8em;
  border: 1px solid var(--color-charcoal400);
  margin-top: 32px;

  + ${Label} {
    position: absolute;
    top:-22px;
  }

  &::placeholder {
    /* visibility: hidden; */
  }

  /* &:not(:placeholder-shown) + ${Label} {
    top: 0px;
    transform: scale(0.7);
  } */

  &:required + ${Label} {
    &:after {
      content: '*';
    }
  }
`

const InputCheckbox = styled.input`
  ${CheckboxStyle};

  + ${Label} {
    top: -3px;
    left: 20px;
  }

  &:required + ${Label} {
    &:after {
      content: '*';
    }
  }
`

const Dropdown = styled(SvgDropdown)`
  position: absolute;
  right: 2px;
  top: 18px;
`

export default FormInputRender

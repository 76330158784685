import { FC } from "react";
import { ImageProps } from "next/legacy/image";
import { getClient } from "library/services/sanity";
import { useNextSanityImage } from "next-sanity-image";
import { SanityImage as SanityImageType } from "../../types/sanityImage";
import GlobalImage from "frontend/src/components/global/GlobalImage";
import { ratioToNumber } from "library/utils/image";
import { sanityImageBuilder } from "library/utils/sanity";

type NextImageProps = Omit<ImageProps, "src" | "placeholder" | "blurDataURL">;

interface SanityImageProps extends NextImageProps {
  image: SanityImageType;
  isPreview?: boolean;
  ratio?: string;
  width?: number;
  height?: number;
  className?: string;
}

const SanityImage: FC<SanityImageProps> = ({
  image,
  ratio,
  className,
  isPreview = false,
  ...props
}) => {
  const sanityClient = getClient(isPreview);

  const ratioNumber = ratio ? ratioToNumber(ratio) : null;
  const sanityImageProps = useNextSanityImage(sanityClient, image, {
    imageBuilder: sanityImageBuilder({ ratio: ratioNumber }),
  });

  const combinedProps: any = {
    layout: "responsive",
    ...props,
    ...sanityImageProps,
  };

  if (combinedProps.layout == "fill") {
    delete combinedProps.width;
    delete combinedProps.height;
    combinedProps.objectFit = "cover";
  }

  return (
    <GlobalImage
      {...combinedProps}
      className={className}
      alt={image?.alt || ""}
    />
  );
};

SanityImage.displayName = "SanityImage";

export default SanityImage;

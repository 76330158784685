
import styled from 'styled-components'
import OverlaySlideWrap from './OverlaySlideWrap'
import FormLocation from '../form/FormLocation'

interface P {
  isActive: boolean
}

const OverlayLocation: React.FC<P> = ({ 
  isActive,
}: P) => {
  const title = `Choose Your Shipping Region / Currency`

  return (
    <Container isActive={isActive} title={title}>
      <FormLocation />
    </Container>
  )
}

const Container = styled(OverlaySlideWrap)``

export default OverlayLocation

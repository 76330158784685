// import { LayoutStyles } from 'frontend/src/styles/layout'
// import NextLink from 'next/link'
import { AnimatePresence, motion } from 'framer-motion'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHeaderSubmenu } from '../../contexts/HeaderSubmenuProvider'
import { useProducts } from '../../contexts/ProductsProvider'
import ProductCard from '../product/card/ProductCard'

type Props = {
  className?: string,
}

const HeaderSubmenuProduct: React.FC<Props> = ({className}: Props) => {
  const { 
    shopifyProducts,
    fetchShopifyProductById,
  } = useProducts();
  
  const { reference } = useHeaderSubmenu();
  
  const [ shopifyProduct, setShopifyProduct ] = useState<any>(false);

  const fetchProduct = useCallback(async (id) => {
    const fetchedProduct = await fetchShopifyProductById(id);
    setShopifyProduct(fetchedProduct);
  }, [shopifyProducts])

  useEffect(() => {
    if(reference?._type == "product") fetchProduct(reference?.store?.id);
  }, [fetchProduct, reference])


  return (
    // <Header  className={className} onMouseLeave={onMouseLeave}>
    
    <Wrapper className={className}>
      <AnimatePresence>
      {
        shopifyProduct && (
          <CardWrapper
            key={shopifyProduct.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            >
            <Product 
              layout="SUBMENU" 
              product={reference} 
              shopifyProduct={shopifyProduct} 
            />
          </CardWrapper>
        )
      }
      </AnimatePresence>
    </Wrapper>
  )
}


const Wrapper = styled.div`
display: grid;
`

const CardWrapper = styled(motion.div)`
  position: relative;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
display: grid;
`

const Product = styled(ProductCard)`
grid-row: 1 / -1;
grid-column: 1 / -1;
`

export default HeaderSubmenuProduct

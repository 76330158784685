import cacheData from 'memory-cache'

const CACHE_MINUTES = 5;

export const fetchWithMemoryCache = async (
  key: string,
  fetcher: () => any,
  expireMinutes = CACHE_MINUTES
) => {
  const result = cacheData.get(key)

  // Return if cached
  if (result) {
    return result 
  }

  // Fetch and cache
  const expiry = expireMinutes * 60 * 1000
  const response = await fetcher()
  cacheData.put(key, response, expiry)
  return response
}

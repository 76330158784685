import styled from 'styled-components'
import { Wrapper } from '../../styles/mixins'

type UtilWrapperProps = {
  className?: string
  children?: React.ReactNode
}

const UtilWrapper: React.FC<UtilWrapperProps> = ({ children, className }: UtilWrapperProps) => {
  return (
    <Container className={className}>
      {children}
    </Container>
  )
}

const Container = styled.div`
  ${Wrapper}
`
export default UtilWrapper

import { ProductFieldsFragment } from "./../../services/shopify/generated";
import { ProductVariantEdge, SelectedOption } from "../../services/shopify";
import { convertProductIdToShopifyGid } from "./transform";

export const SHOPIFY_CUSTOMER_TOKEN_COOKIE = "shopifyCustomerToken";
export const SHOPIFY_CART_ID_COOKIE = "shopifyCartId";
export const SHOPIFY_LOCATION_COOKIE = "shopifyLocation";
export const SHOPIFY_DETECT_COOKIE = "shopifyDetect";
export const SHOPIFY_COOKIE_EXPIRY = 30;
export const SHOPIFY_DEFAULT_LOCATION = "NZ";
export const SHOPIFY_DEFAULT_CURRENCY = "NZD";

type ShopifyImageUrlType = {
  src: string;
  width: number;
  height?: number;
  quality?: string;
};

export function constructShopifyImageUrl({
  src,
  width,
  height,
  quality,
}: ShopifyImageUrlType) {
  const url = `${src}?`;
  const dotIndex = url.lastIndexOf(".");
  const format = `${width}x${height && `${Math.ceil(height)}_crop_center`}`;

  return [url.slice(0, dotIndex), `_${format}`, url.slice(dotIndex)].join("");
}

export function constructShopifyImageUrlLarge(src?: string) {
  if (!src) return undefined;
  return constructShopifyImageUrl({ src, width: 1600 });
}

export function constructShopifyImageUrlThumb(src?: string) {
  if (!src) return undefined;
  return constructShopifyImageUrl({ src, width: 400, height: 600 });
}

export function constructShopifyImageUrlBlur(src?: string) {
  if (!src) return undefined;
  return constructShopifyImageUrl({ src, width: 64 });
}

export function findShopifyProductById(
  shopifyProducts: ProductFieldsFragment[],
  id: number
) {
  const gid = convertProductIdToShopifyGid(id);
  const result = shopifyProducts?.find(
    (shopifyProduct: ProductFieldsFragment) => {
      return gid == shopifyProduct.id;
    }
  );
  return result;
}

export function findSelectedVariant(variants: any, options: any[]) {
  const variant = variants.edges?.find((variant: ProductVariantEdge) =>
    variant.node.selectedOptions.every((variantOption: SelectedOption) =>
      options.some((option: SelectedOption) => {
        return (
          option.name === variantOption.name &&
          option.value === variantOption.value
        );
      })
    )
  );
  return variant?.node || null;
}

export function findActiveOptionValue({ selectedOptions, name }: any) {
  const selectedOption = selectedOptions.find(
    (optionGroup: any) => optionGroup.name == name
  );

  return selectedOption.value;
}

export const mapEdges = <T>(object: { edges?: { node: T }[] } | T[]): T[] => {
  if ("edges" in object && Array.isArray(object.edges)) {
    return object.edges.map((edge) => edge.node);
  }
  return object as T[];
};

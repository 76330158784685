import { getSpecsFromProduct, getStyleFromProduct } from 'library/utils/shopify/getters';
import { useContext, createContext, useReducer,  } from 'react';

export interface State {
  item: any
  isUpdating: boolean 
  selectedQuantity: number
  style: string
  colour: string
  number: string
  specs: any
}

type Action = 
    | { type: "SET_UPDATING", value: boolean, } 
    | { type: "SET_QUANTITY", value: number } 

// Item Provider
export const ItemContext = createContext<State & {dispatch}>(null!);

const itemReducer = (state, action: Action) => {
  switch (action.type) {
    case 'SET_UPDATING': {
      return {
        ...state,
        number: action.value
      }
    }
    default: {
      throw new Error('Invalid action')
    }

  }
}

export const ItemProvider = ({
  children, 
  item, 
}: any) => {
  const number = item.merchandise.sku || '000000';
  const { style, colour, } = getStyleFromProduct(item.merchandise.product);
  const specs = getSpecsFromProduct(item.merchandise.product);

  const initialState: State = {
    // Data
    item,

    // Cart
    selectedQuantity: 1,
    isUpdating: false,

    // Content
    number,
    style,
    colour,
    specs,
  }

  const [ state, dispatch, ] = useReducer(itemReducer, initialState);

  const value = {
    ...state,
    dispatch,
  }

  return (
    <ItemContext.Provider value={value} >
      {children}  
    </ItemContext.Provider>
  )
}

export function useItem() {
  return useContext(ItemContext);
}

export default ItemProvider;
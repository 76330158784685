import SanityLinkAction from "./SanityLinkAction";
import SanityLinkUrl from "./SanityLinkUrl";
import SanityLinkEmail from "./SanityLinkEmail";
import SanityLinkScroll from "./SanityLinkScroll";
import SanityLinkReference from "./SanityLinkReference";
import SanityLinkPhone from "./SanityLinkPhone";

type Props = {
  link: any;
  children: React.ReactNode;
};

const SanityAnnotationLink: React.FC<Props> = ({
  link,
  children,
  ...props
}: Props) => {
  return (
    <>
      {(link.href && (
        <SanityLinkUrl {...props} link={link}>
          {children}
        </SanityLinkUrl>
      )) ||
        (link.email && (
          <SanityLinkEmail {...props} link={link}>
            {children}
          </SanityLinkEmail>
        )) ||
        (link.phone && (
          <SanityLinkPhone {...props} link={link}>
            {children}
          </SanityLinkPhone>
        )) ||
        (link.action && (
          <SanityLinkAction {...props} link={link}>
            {children}
          </SanityLinkAction>
        )) ||
        (link.destination && (
          <SanityLinkScroll {...props} link={link}>
            {children}
          </SanityLinkScroll>
        )) ||
        (link.reference && (
          <SanityLinkReference {...props} link={link}>
            {children}
          </SanityLinkReference>
        ))}
    </>
  );
};

export default SanityAnnotationLink;


import styled from 'styled-components'
import OverlaySlideWrap from './OverlaySlideWrap'
import SanityContent from '../sanity/content/SanityContent'
import { TypeBodyM, TypeHeadingXS } from '../../styles/mixins'
import { useSettings } from '../../contexts/SettingsProvider'
import { useInterface } from '../../contexts/InterfaceProvider'
import FormLogin from '../form/FormLogin'
import FormReset from '../form/FormReset'
import FormRegister from '../form/FormRegister'
import FormActivate from '../form/FormActivate'
import FormRecover from '../form/FormRecover'

interface P {
  isActive: boolean
}

const OverlayAccount: React.FC<P> = ({ 
  isActive,
}: P) => {
  const title = `Join`;
  const { overlayAccount } = useSettings();
  const {
    accountForm,
    setAccountForm
  } = useInterface();

  return (
    <Container 
      title={title} 
      isActive={isActive} 
      borderColor="charcoal400"
      backgroundColor="pistachio"
    >
      <Heading>{overlayAccount.title}</Heading>

      <Content layout="DEFAULT" blocks={overlayAccount.content} />

      { accountForm == 'LOGIN' && <FormLogin setMode={setAccountForm} /> }
      { accountForm == 'RESET' && <FormReset setMode={setAccountForm} /> }
      { accountForm == 'RECOVER' && <FormRecover setMode={setAccountForm} /> }
      { accountForm == 'ACTIVATE' && <FormActivate setMode={setAccountForm} /> }
      { accountForm == 'REGISTER' && <FormRegister setMode={setAccountForm} /> }
    </Container>
  )
}

const Container = styled(OverlaySlideWrap)`
  justify-content: space-between;
  ${TypeBodyM}
`

const Heading = styled.h3`
  ${TypeHeadingXS};
  margin-top: 8px;
  margin-bottom: var(--spacing-32);
`

const Content = styled(SanityContent)`
  margin-bottom: var(--spacing-40);
`

export default OverlayAccount

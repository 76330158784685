import styled from 'styled-components'
import { TypeBodyM } from '../../styles/mixins'

interface P {
 [key: string]: any 
}

const CartEmpty: React.FC<P> = ({ 
  ...props
}: P) => {
  // TODO: theme setting

  return (
    <Container {...props}>
      Your Cart is Empty
    </Container>
  )
}

const Container = styled.div`
  ${TypeBodyM};
  padding-top: 8px;
  padding-bottom: var(--spacing-16);
  border-bottom: 1px solid var(--color-charcoal200);
`

export default CartEmpty
import { FC, useState } from 'react'
import { TypeCaptionL } from '../../../styles/mixins'
import styled from 'styled-components'
import SanityVideo from './../SanityVideo'
import SanityBlocks from './../SanityBlocks'
import SanityVideoPoster from './../SanityVideoPoster'
import SvgPlay from '../../svg/SvgPlay'
import OverlayVideo from '../../overlay/OverlayVideo'

interface SanityVideoProps {
  className?: string
  caption?: any
  video?: any
}

const SanityContentVideo: FC<SanityVideoProps> = ({
  className,
  caption,
  video,
  ...props
}) => {
  const ratio = video?.aspectRatio?.replace(":", "x") || '1x1';

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  
  return (
    <Content className={className}>
      <PosterWrap onClick={() => setIsOverlayOpen(true)}>
        <Play>
          <SvgPlay />
        </Play>
        <SanityVideoPoster 
          ratio={ratio} 
          video={video} 
          {...props} 
        />
      </PosterWrap>
      
      {caption && <Caption blocks={caption} />}

      {
        isOverlayOpen &&
        <OverlayVideo
          video={video}
          ratio={ratio}
          caption={caption}
          setIsOverlayOpen={setIsOverlayOpen}
        />
      }
    </Content>
  )
}

const Content = styled.div`
  
`

const PosterWrap = styled.div`
  position: relative;
  cursor: pointer;
`

const Play = styled.div`
  z-index: 10;
  position: absolute;
  top: 50%;
  left:50%;
  transform: translateX(-50%) translateY(-50%);
`

const Caption = styled(SanityBlocks)`
  ${TypeCaptionL};
  margin-top: 8px;
`

export default SanityContentVideo

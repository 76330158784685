import styled from "styled-components";
import { TypeBodyM } from "../../styles/mixins";
import { useCartUpsellProducts } from "src/hooks/useCartUpsellProducts";
import ProductProvider from "src/contexts/ProductProvider";
import CartUpsellProduct from "../cart/CartUpsellProduct";
import SlideshowProvider, {
  useSlideshow,
} from "src/contexts/SlideshowProvider";
import GlobalSlider from "../global/GlobalSlider";

interface P {
  className?: string;
}

const Page: React.FC = () => {
  const { slideIndex } = useSlideshow();

  return <span>{`${slideIndex + 1}`}</span>;
};

const NextButton: React.FC = () => {
  const { emblaApi } = useSlideshow();
  const onClick = () => emblaApi.scrollNext();

  return (
    <button disabled={!emblaApi?.canScrollNext()} onClick={onClick}>
      Next
    </button>
  );
};

const PrevButton: React.FC = () => {
  const { emblaApi } = useSlideshow();
  const onClick = () => emblaApi.scrollPrev();

  return (
    <button disabled={!emblaApi?.canScrollPrev()} onClick={onClick}>
      Prev
    </button>
  );
};

const OverlayCartUpsellMobile: React.FC<P> = ({ className }: P) => {
  const { shopifyProducts } = useCartUpsellProducts();
  const slideshowSettings = {
    // dragFree: true,
    containScroll: "keepSnaps",
    align: "start",
  };

  const key = JSON.stringify(shopifyProducts.map((p) => p.id));

  return (
    <Container className={className}>
      <SlideshowProvider settings={slideshowSettings} key={JSON.stringify(key)}>
        <Title>
          <span>Recommended Pairings</span>{" "}
          {shopifyProducts.length > 1 && (
            <Controls>
              <PrevButton />
              <Page />/ <span>{shopifyProducts.length}</span>
              <NextButton />
            </Controls>
          )}
        </Title>
        {shopifyProducts.length && (
          <GlobalSlider>
            {shopifyProducts.map((shopifyProduct) => (
              <Slide key={`CartUpsellProducts__product--${shopifyProduct.id}`}>
                <ProductProvider shopifyProduct={shopifyProduct}>
                  <CartUpsellProduct />
                </ProductProvider>
              </Slide>
            ))}
          </GlobalSlider>
        )}
      </SlideshowProvider>
    </Container>
  );
};

const Container = styled.div`
  background: var(--color-charcoal100);
  padding: 0 var(--inset);
`;

const Title = styled.h3`
  ${TypeBodyM};
  border-bottom: 1px solid var(--border-color);
  padding-bottom: var(--spacing-16);
  margin-bottom: var(--spacing-16);
  justify-content: space-between;
  display: flex;
  padding: 8px 0;
  margin-top: -8px;
`;

const Slide = styled.div`
  flex: 0 0 100%; /* Slide covers 50% of the viewport */
  min-width: 0;
  margin-right: var(--gutter);
`;

const Controls = styled.div`
  display: flex;
  gap: 5px;

  button:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export default OverlayCartUpsellMobile;

import useNearScreen from "frontend/src/hooks/useNearScreen";

interface P {
  children: React.ReactNode;
}

const GlobalReveal: React.FC<P> = ({ children, ...props }: P) => {
  const { ref, isNearScreen } = useNearScreen();

  return (
    <div
      ref={ref}
      style={{
        transition: "opacity 0.1s ease",
        opacity: isNearScreen ? "initial" : 0,
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export default GlobalReveal;

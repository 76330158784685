import styled, { css } from 'styled-components'
import { ReactNode } from 'react';
import { Hover, UnstyledLinkInline } from '../../styles/mixins';

interface P {
  className?: string,
  disabled?: boolean,
  children?: ReactNode,
  onClick?: () => void,
}

const ButtonUnderline: React.FC<P> = ({ 
  className, 
  children, 
  disabled, 
  onClick, 
  ...props
}: P) => {
    return (
      <Wrapper className={className + ' button-inline'} {...props}>
        <Button 
          as="button" 
          onClick={onClick} 
          $disabled={disabled}
        >
          {children}
        </Button>
      </Wrapper>
  )
}



const Wrapper = styled.span`
  ${UnstyledLinkInline};
`

const Button = styled.button<{$disabled: boolean | undefined}>`
  display: inline-flex;
  color: var(--ButtonUnderline-color);
  text-decoration: underline;

  :focus,
  ${Hover} {
  color: var(--ButtonUnderline-hover-color);
  }
  
  ${props => props.$disabled && css`
    color: var(--color-charcoal300);
    pointer-events: none;
  `}
`

export default ButtonUnderline

function SvgSearch() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M6.75 11C6.75 8.65279 8.65279 6.75 11 6.75C13.3472 6.75 15.25 8.65279 15.25 11C15.25 13.3472 13.3472 15.25 11 15.25C8.65279 15.25 6.75 13.3472 6.75 11ZM11 5.25C7.82436 5.25 5.25 7.82436 5.25 11C5.25 14.1756 7.82436 16.75 11 16.75C12.3174 16.75 13.5314 16.3069 14.501 15.5617L17.7695 18.8302L18.8301 17.7696L15.5616 14.5011C16.3069 13.5315 16.75 12.3175 16.75 11C16.75 7.82436 14.1756 5.25 11 5.25Z" fill="#414042"/>
</svg>


  )
}

export default SvgSearch;
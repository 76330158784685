import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import SanityContentFootnote from './SanityContentFootnote';
import SanityContentLink from './SanityContentLink';

interface Props {
  className?: string,
  blocks: any,
}

const serializers = {
  types: {
    blockFootnote: (props) => {
      return (
        <ContentFootnote blocks={props.node?.content} />
      );
    },
  },
  marks: {
    annotationLink: (props) => {
      return (
        <ContentLink link={props.mark} >
          {props.children[0]}
        </ContentLink>
      );
    },
    annotationLinkEmail: (props) => {
      return (
        <ContentLink link={props.mark} >
          {props.children[0]}
        </ContentLink>
      );
    },
  },
}

const SanityContentInner: React.FC<Props> = ({ blocks, className }: Props) => {
    return (
      <Content 
        className={className}
        blocks={blocks} 
        serializers={serializers} 
        renderContainerOnSingleChild={true}
      />
  )
}

// const ContentComposite = styled(SanityContentComposite)``;
const ContentFootnote = styled(SanityContentFootnote)``;
// const ContentImage = styled(SanityContentImage)``;
// const ContentVideo = styled(SanityContentVideo)``;
// const ContentImagePair = styled(SanityContentImagePair)``;
// const ContentTable = styled(SanityContentTable)``;
// const ContentButton = styled(SanityContentButton)``;
const ContentLink = styled(SanityContentLink)``;
// const ContentAccordion = styled(SanityContentAccordion)``;
// const ContentStockists = styled(SanityContentStockists)``;

const Content = styled(BlockContent)`
  word-wrap: break-word;

  ${ContentFootnote} {
    margin-top: var(--spacing-32);
  }
`

export default SanityContentInner
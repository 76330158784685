import { withIsPreview } from "../../../types/fetch";
import { withHandle } from "../../../types/fetch";
import { wait } from "../../../utils";
import { getClient } from "../client";
import { GroqDefault } from "../groq";
import { filterDocumentToSingleItem } from "../helpers";

type FetchProps = {
  query: string;
  isPreview?: boolean;
  params?: {
    [key: string]: string;
  };
};

type ParamsProps = {
  queryParams?: string;
  queryModifiers?: string;
  params?: {
    [key: string]: string;
  };
  query?: string;
  isPreview?: boolean;
};

export async function clientFetch({ isPreview, params, query }: FetchProps) {
  try {
    const client = getClient(isPreview);
    const response = await client.fetch(query, params || {});
    wait(100);

    return response;
  } catch (e: any) {
    console.error(e);
    throw new Error(JSON.stringify(e, null, 2));
  }
}

export async function fetchByParams({
  queryParams,
  params = {},
  query = "",
  queryModifiers = "",
  isPreview = false,
}: ParamsProps) {
  return clientFetch({
    isPreview,
    params,
    query: query
      ? query
      : `*[${queryParams}] ${queryModifiers} { ${GroqDefault} }`,
  });
}

export async function fetchByHandle({
  handle,
  type,
  isPreview = false,
}: withHandle & withIsPreview & { type: string }) {
  const response = await fetchByParams({
    isPreview,
    queryParams: "_type == $type && slug.current == $handle",
    params: {
      handle,
      type,
    },
  });

  return filterDocumentToSingleItem(response);
}

import { css } from "styled-components";

const CheckboxActive = css`
  content: " ";
  position: absolute;
  left:2px;
  right:2px;
  top:2px;
  bottom:2px;
  background: var(--color-foreground);
`

export const CheckboxStyle = css<{$isActive?}>`
  vertical-align: baseline;
  position: relative;
  width: 10px;
  height: 10px;
  margin: auto 8px auto 0;

  &:before {
    content: " ";
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    border: 1px solid var(--color-foreground);
  }

  ${props => props.$isActive && css`
    &:after {
      ${CheckboxActive}
    }
  `}

  &:checked {
    &:after {
      ${CheckboxActive}
    }
  }
`
import { FC } from 'react'
import { FieldError, UseFormRegister } from 'react-hook-form'
import FormInputRender from './FormInputRender'

// How to type register properly:
// https://codesandbox.io/s/react-hook-form-typescript-xnb1u?file=/src/components/Input.tsx

interface P {
  error?: any
  register: UseFormRegister<any>
  required?: boolean
  name: string
  label: string
  [key: string]: any
}

const FormInputCheckbox: FC<P> = ({ 
  required,
  register,
  name = "text",
  label = "Text",
  ...props
}) => 
  <FormInputRender
    type="checkbox"
    register={register}
    required={required}
    label={label}
    {...register(name, {
      required,
    })}
    {...props}
  />

export default FormInputCheckbox

import {
  getColoursFromProduct,
  getSpecsFromProduct,
  getStyleFromProduct,
} from "library/utils/shopify/getters";
import {
  ProductFieldsFragment,
  VariantFieldsFragment,
} from "library/services/shopify";
import { findSelectedVariant } from "library/utils/shopify";
import { useContext, createContext, useReducer, useEffect } from "react";
export interface ProductProviderState {
  product: any;
  shopifyProduct: ProductFieldsFragment;
  isAdding: boolean;
  isSingleVariant: boolean;
  selectedOptions: any[];
  selectedVariant: VariantFieldsFragment;
  selectedQuantity: number;
  style: string;
  colour: string;
  number: string;
  colours: any[];
  specs: any;
}

type ProductProviderAction =
  | { type: "SET_PRODUCT"; value: any }
  | { type: "SET_QUANTITY"; value: any }
  | { type: "SET_OPTIONS"; value: any }
  | { type: "SET_ADDING"; value: boolean }
  | { type: "SET_VARIANT"; value: any }
  | { type: "SET_NUMBER"; value: string };

// Product Provider
export const ProductContext = createContext<
  ProductProviderState & { dispatch }
>(null!);

const productReducer = (
  state: ProductProviderState,
  action: ProductProviderAction,
) => {
  switch (action.type) {
    case "SET_PRODUCT": {
      return {
        ...state,
        ...action.value,
      };
    }
    case "SET_VARIANT": {
      return {
        ...state,
        selectedVariant: action.value,
      };
    }
    case "SET_NUMBER": {
      return {
        ...state,
        number: action.value,
      };
    }
    case "SET_OPTIONS": {
      return {
        ...state,
        selectedOptions: action.value,
      };
    }
    case "SET_ADDING": {
      return {
        ...state,
        isAdding: action.value,
      };
    }
    default: {
      throw new Error("Invalid action");
    }
  }
};

export const ProductProvider = ({ children, product, shopifyProduct }: any) => {
  const initialVariant = shopifyProduct.variants.edges[0].node;
  const number = initialVariant.sku || "000000";
  const { style, colour } = getStyleFromProduct(shopifyProduct);
  const specs = getSpecsFromProduct(shopifyProduct);
  const colours = product && getColoursFromProduct(product);

  const initialState: ProductProviderState = {
    // Data
    product,
    shopifyProduct,

    // Cart
    selectedQuantity: 1,
    isSingleVariant: shopifyProduct.variants.edges?.length == 1,
    selectedOptions: initialVariant.selectedOptions,
    selectedVariant: initialVariant,
    isAdding: false,

    // Content
    number,
    style,
    colour,
    colours,
    specs,
  };

  const [state, dispatch] = useReducer(productReducer, initialState);

  // Update the product when the arguments change
  useEffect(() => {
    dispatch({
      type: "SET_PRODUCT",
      value: {
        product,
        shopifyProduct,
      },
    });
  }, [product, shopifyProduct]);

  // Update the variant when selected options change
  useEffect(() => {
    const newVariant = findSelectedVariant(
      shopifyProduct.variants,
      state.selectedOptions,
    );

    if (newVariant)
      dispatch({
        type: "SET_VARIANT",
        value: newVariant,
      });
  }, [state?.selectedOptions, shopifyProduct, dispatch]);

  // Update the number when variant changes
  useEffect(() => {
    if (state.selectedVariant)
      dispatch({
        type: "SET_NUMBER",
        value: state.selectedVariant.sku,
      });
  }, [state.selectedVariant, dispatch]);

  const value = {
    ...state,
    dispatch,
  };

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
};

export function useProduct() {
  return useContext(ProductContext);
}

export default ProductProvider;

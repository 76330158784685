import { DEFAULT_RATIOS } from '../config/ratios';

export function ratioToNumber(ratio: string): number {
  const [width, height] = ratio.split('x').map((v) => parseFloat(v));
  return width / height;
}

export function numberToRatio(number: number) {
  return `${number}x1`
}


export const findNearestRatio = (ratios: number[], ratio: number): string => {
  const number = ratios.reduce((c,r) => {
    if(Math.abs(r - ratio) < Math.abs(c - ratio)) {
      c = r;
    }
    return c;
  }, 1);

  return `${number}x1`
}

export const findNearestDefaultImageRatio = (number: number | undefined) => {
  if(!number) return "1x1";
  return findNearestRatio(DEFAULT_RATIOS, number);
}

import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useInterface } from '../../contexts/InterfaceProvider'
import { useSettings } from '../../contexts/SettingsProvider'
import { TransitionSlide, TypeBodyM, Wrapper } from '../../styles/mixins'
import FormNewsletter from '../form/FormNewsletter'
import SanityLinkAction from '../sanity/SanityLinkAction'
import SanityMenu from '../sanity/SanityMenu'
import UtilWrapper from '../util/UtilWrapper'
import OverlayMmenuRow from './OverlayMmenuRow'

interface P {
  isActive: boolean
  backgroundColor?: string
  borderColor?: string
}

const OverlayMmenu: React.FC<P> = ({ 
  isActive,
  ...props
}: P) => {
  const router = useRouter();
  const { closeOverlay } = useInterface();
  const {overlayMmenu} = useSettings();
  const [openIndex, setOpenIndex] = useState(-1);
  const rowStateGenerator = (index: number) => {
    return [
      index == openIndex,
      () => index == openIndex ? setOpenIndex(-1) : setOpenIndex(index)
    ]
  }

  // Close mobile menu if its active 
  // While route changes
  useEffect(() => {
    if(isActive) closeOverlay();
  }, [router.asPath])

  return (
    <Wrap $isActive={isActive} {...props}>
      <Container 
        $isActive={isActive} 
      >
        <Inner>
          {
            overlayMmenu.menu1.map((row, index) => 
              <OverlayMmenuRow 
                row={row} 
                rowState={rowStateGenerator(index)} 
                key={row._key}
              />
            )
          }
          <Menu layout="SUBMENU" links={overlayMmenu.menu2} />

        </Inner>
        <UtilWrapper>
          <Newsletter />
        </UtilWrapper>
        <Login>
          <UtilWrapper>
            <SanityLinkAction link={{
              action: 'ACCOUNT',
            }} />
          </UtilWrapper>
        </Login>
      </Container>
      <Background onClick={closeOverlay}  $isActive={isActive} />
    </Wrap>
  )
}


const Background = styled.div<{$isActive}>`
  position: absolute;
  left:0;right:0;
  top:0;bottom:0;
  z-index: -1;
  background: var(--color-charcoal);
  opacity: 0;
  transition: ${TransitionSlide};
  cursor: pointer;

  ${props => props.$isActive && css`
    pointer-events: auto;
    opacity: 0.25;
  `}
`

const Wrap = styled.div<{ $isActive }>`
  position: fixed;
  left:0;
  width: 100vw;
  top:var(--header-height);
  height: calc(var(--1vh) * 100 - var(--header-height));
  z-index: 9999;
  pointer-events: none;
  overflow: hidden;

  ${props => props.$isActive && css`
    > * {
      pointer-events: auto;
    }
  `}
`

const Container = styled.div<{ 
  $isActive, 
}>`
  position: absolute;
  left:auto;
  right: 0;
  top:100%;
  height: calc(var(--1vh) * 100 - var(--header-height));
  width: 100%;
  pointer-events: auto;
  padding: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-background);
  border-color: var(--color-charcoal200);
  transition: ${TransitionSlide};

${props => props.$isActive && css`
  top: 0;
`}
`

const Inner = styled.div`
  ${Wrapper};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding-top: 40px;
  
  .sanity-link--depth-1 {
    >.sanity-link {
      &__link,
      &__link a {
        display: block;
      }
    }
  }
`

const Menu = styled(SanityMenu)`
  margin-top: var(--spacing-40);
  margin-bottom: 120px;
`

const Newsletter = styled(FormNewsletter)`
  margin-top: 16px;
  margin-bottom: 16px;
`

const Login = styled.div`
  ${TypeBodyM};
  background: var(--color-pistachio);
  button {
    padding: 12px 0;
    width: 100%;
  }
  .button-inline {
    display: block;
  }
`

export default OverlayMmenu
import {
  useContext,
  createContext,
  useReducer,
  useCallback,
  useEffect,
} from "react";

import Cookies from "js-cookie";
import {
  cartCreate,
  cartLinesAdd,
  cartLinesRemove,
  cartLinesUpdate,
  cartRetrieve,
  cartNoteUpdate as cartNoteUpdateService,
  cartBuyerUpdate as cartBuyerUpdateService,
} from "library/services/shopify/cart";

import {
  CartFieldsFragment,
  CartLineInput,
  CartLineUpdateInput,
  CountryCode,
  Country,
  CartBuyerIdentityInput,
} from "library/services/shopify";

import {
  SHOPIFY_CART_ID_COOKIE,
  SHOPIFY_LOCATION_COOKIE,
  SHOPIFY_DETECT_COOKIE,
  SHOPIFY_COOKIE_EXPIRY,
  SHOPIFY_DEFAULT_LOCATION,
  mapEdges,
} from "library/utils/shopify";
import { useRouter } from "next/router";
import { wait } from "library/utils";

const cookieOptions = { expires: SHOPIFY_COOKIE_EXPIRY };

function getCartCount(cart?: CartFieldsFragment) {
  if (!cart || !cart?.lines) return 0;

  const lineItems = mapEdges(cart.lines);
  return lineItems.reduce(
    (sum: number, line) => (sum += (line as any)?.quantity || 0),
    0,
  );
}

function getCartUpsellProductIds(cart?: CartFieldsFragment) {
  if (!cart || !cart?.lines) return [];
  const lineItems = mapEdges(cart.lines);
  const lineItemProductIds = lineItems.map(
    (line) => (line as any)?.merchandise.product.id,
  );

  const allItems = lineItems.reduce((collect: string[], line: any) => {
    // if (line) return collect;

    const newItems = JSON.parse(
      line?.merchandise.product.upsellProducts?.value || "[]",
    )
      .filter((v: any) => !collect.includes(v)) // Not already in collection
      .filter((v: any) => !lineItemProductIds.includes(v)); // Not already in cart

    return [...collect, ...newItems];
  }, []);

  return allItems;
}

function getCountryByCode(countries: Country[], code: string) {
  return (
    countries.find((v) => v.isoCode.toUpperCase() == code.toUpperCase()) ||
    countries.find((v) => v.isoCode.toUpperCase() == SHOPIFY_DEFAULT_LOCATION)
  );
}

function getAvailableCode(countries: string[], code: string) {
  return (
    countries.find((country) => country.toUpperCase() == code.toUpperCase()) ||
    SHOPIFY_DEFAULT_LOCATION
  );
}

export interface State {
  cart: CartFieldsFragment | null;
  count: number;
  country: Country;
  countryCode: CountryCode;
  availableCountries: Country[];
  isUpdating: boolean;
  isDetectOverlayActive: boolean;
  upsellProductIds: string[];
}

type Action =
  | { type: "SET_CART"; value: any }
  | { type: "SET_COUNT"; value: number }
  | { type: "SET_UPDATING"; value: boolean }
  | { type: "SET_COUNTRY_CODE"; value: CountryCode }
  | { type: "SET_DETECT_OVERLAY_ACTIVE"; value: boolean }
  | { type: "SET_UPSELL_PRODUCT_IDS"; value: string[] };

// Cart Provider
export const CartContext = createContext<State | any>({ cart: null });

const cartReducer: (state: State, action: Action) => State = (
  state,
  action,
) => {
  switch (action.type) {
    case "SET_CART": {
      return {
        ...state,
        cart: action.value,
      };
    }
    case "SET_COUNT": {
      return {
        ...state,
        count: action.value,
      };
    }
    case "SET_UPDATING": {
      return {
        ...state,
        isUpdating: action.value,
      };
    }
    case "SET_DETECT_OVERLAY_ACTIVE": {
      return {
        ...state,
        isDetectOverlayActive: action.value,
      };
    }
    case "SET_COUNTRY_CODE": {
      const country = getCountryByCode(state.availableCountries, action.value);
      if (!country) return state;
      Cookies.set(SHOPIFY_LOCATION_COOKIE, action.value, cookieOptions);
      return {
        ...state,
        country,
        countryCode: action.value,
      };
    }
    case "SET_UPSELL_PRODUCT_IDS": {
      return {
        ...state,
        upsellProductIds: action.value,
      };
    }
    default: {
      console.log(`Invalid action ${action}`);
      throw new Error(`Invalid action`);
    }
  }
};

export const CartProvider: React.FC = ({ localization, children }: any) => {
  const router = useRouter();

  const initialState: State = {
    cart: null,
    count: 0,
    isUpdating: false,
    isDetectOverlayActive: false,
    upsellProductIds: [],

    country: localization.country,
    countryCode: localization.country.isoCode,
    availableCountries: localization.availableCountries,
  };

  const [state, dispatch] = useReducer(cartReducer, initialState);

  const getCartId = (locale: string) => `${SHOPIFY_CART_ID_COOKIE}-${locale}`;

  const updateCart = useCallback(
    async (locale: string, newCart: any) => {
      if (!newCart) return;

      Cookies.set(getCartId(locale), newCart.id, cookieOptions);

      dispatch({
        type: "SET_CART",
        value: newCart,
      });

      dispatch({
        type: "SET_COUNT",
        value: getCartCount(newCart),
      });

      dispatch({
        type: "SET_UPSELL_PRODUCT_IDS",
        value: getCartUpsellProductIds(newCart),
      });

      dispatch({
        type: "SET_UPDATING",
        value: false,
      });
    },
    [state.countryCode],
  );

  useEffect(() => {
    async function confirmLocation() {
      // Check cookie
      const hasDetectCookie = Cookies.get(SHOPIFY_DETECT_COOKIE);
      if (!!hasDetectCookie) return;

      // Set cookie so we dont confirm multiple times
      Cookies.set(SHOPIFY_DETECT_COOKIE, "true", cookieOptions);

      // Fetch geoIP version of location
      const response = await fetch("/api/detect", { method: "get" });
      const { country_code: detectedCountryCode } = await response?.json();
      const countryCode = getAvailableCode(
        router.locales || [],
        detectedCountryCode,
      );
      const detectedCountry = getCountryByCode(
        state.availableCountries,
        countryCode,
      );

      // Return if country code is already correct
      if (detectedCountry?.isoCode == state.countryCode) return;

      // Change location based on new country code (if locale exists)
      const { pathname, asPath, query } = router;

      router.push({ pathname, query }, asPath, {
        locale: detectedCountry?.isoCode.toLowerCase(),
      });
      // router.replace(router.asPath);

      // Otherwise set new country code
      if (detectedCountry)
        dispatch({
          type: "SET_COUNTRY_CODE",
          value: detectedCountry.isoCode,
        });

      // If location not in NZ, open the location overlay
      // if(detectedCountry.isoCode == 'NZ') return;

      await wait(6000);

      dispatch({
        type: "SET_DETECT_OVERLAY_ACTIVE",
        value: true,
      });
    }

    async function cartCreateAndUpdate() {
      const createdCart = await cartCreate({
        buyerIdentity: {
          countryCode: state.countryCode,
        },
      });

      return await updateCart(state.countryCode, createdCart);
    }

    async function cartGetOrCreate() {
      dispatch({
        type: "SET_UPDATING",
        value: true,
      });

      await confirmLocation();

      const cartId = Cookies.get(getCartId(state.countryCode));
      const retrievedCart = cartId ? await cartRetrieve(cartId) : null;

      if (!retrievedCart) {
        await cartCreateAndUpdate();
        return;
      }

      await updateCart(state.countryCode, retrievedCart);
    }

    cartGetOrCreate();
  }, [updateCart, state.countryCode]);

  const cartLineAdd = useCallback(
    async (line: CartLineInput) => {
      if (!state.cart) return;
      const updatedCart = await cartLinesAdd([line], state.cart.id);
      updateCart(state.countryCode, updatedCart);
    },
    [state.cart, state.countryCode],
  );

  const cartLineRemove = useCallback(
    async (lineId: string) => {
      if (!state.cart) return;
      const updatedCart = await cartLinesRemove([lineId], state.cart.id);
      updateCart(state.countryCode, updatedCart);
    },
    [state.cart, state.countryCode],
  );

  const cartLineUpdate = useCallback(
    async (line: CartLineUpdateInput) => {
      if (!state.cart) return;
      const updatedCart = await cartLinesUpdate([line], state.cart.id);
      updateCart(state.countryCode, updatedCart);
    },
    [state.cart, state.countryCode],
  );

  const cartNoteUpdate = useCallback(
    async (note: string) => {
      if (!state.cart) return;
      const updatedCart = await cartNoteUpdateService(note, state.cart.id);
      updateCart(state.countryCode, updatedCart);
    },
    [state.cart, state.countryCode],
  );

  const cartBuyerUpdate = useCallback(
    async (buyerIdentity: CartBuyerIdentityInput) => {
      if (!state?.cart?.id) return;
      const updatedCart = await cartBuyerUpdateService(
        buyerIdentity,
        state.cart.id,
      );
      updateCart(state.countryCode, updatedCart);
    },
    [state.cart],
  );

  const value = {
    ...state,
    cartLineAdd,
    cartLineRemove,
    cartLineUpdate,
    cartNoteUpdate,
    cartBuyerUpdate,
    dispatch,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export function useCart() {
  return useContext(CartContext);
}

export default CartProvider;

import styled from "styled-components";
import OverlaySlideWrap from "./OverlaySlideWrap";
import { useCart } from "../../contexts/CartProvider";
import CartEmpty from "../cart/CartEmpty";
import CartItems from "../cart/CartItems";
import CartShipping from "../cart/CartShipping";
import CartTotals from "../cart/CartTotals";
import CartControls from "../cart/CartControls";
import { TypeCaptionL } from "../../styles/mixins";
import { useState } from "react";
import CartCheckout from "../cart/CartCheckout";
import { useAcceptDuties } from "../../hooks/useAcceptDuties";
import OverlayCartUpsellDesktop from "./OverlayCartUpsellDesktop";
import OverlayCartUpsellMobile from "./OverlayCartUpsellMobile";
import { Breakpoint } from "src/styles/functions";

interface P {
  isActive: boolean;
}

const OverlayCart: React.FC<P> = ({ isActive }: P) => {
  const { count, cart } = useCart();
  const title = `Bag ${count}`;
  const { isAccepted: checkoutActive, setIsAccepted: setCheckoutActive } =
    useAcceptDuties();
  const [checkoutRequired, setCheckoutRequired] = useState(false);
  const { upsellProductIds } = useCart();

  return (
    <Container isActive={isActive} title={title}>
      <Top>
        {count > 0 && (
          <>
            <Items />
            {upsellProductIds.length > 0 && <UpsellMobile />}
          </>
        )}
        {count == 0 && <Empty />}
      </Top>

      <Bottom>
        <Shipping />
        <CartTotals />

        <CartControls
          checkoutActive={checkoutActive}
          setCheckoutActive={setCheckoutActive}
          checkoutRequired={checkoutRequired}
        />

        <CartCheckout
          checkoutActive={checkoutActive}
          checkoutRequired={checkoutRequired}
          setCheckoutRequired={setCheckoutRequired}
        />
      </Bottom>

      {upsellProductIds.length > 0 && <UpsellDesktop isActive={isActive} />}
    </Container>
  );
};

const Container = styled(OverlaySlideWrap)`
  ${TypeCaptionL};
  justify-content: space-between;
`;

const UpsellDesktop = styled(OverlayCartUpsellDesktop)`
  ${Breakpoint("tablet", "max")} {
    display: none;
  }
`;
const UpsellMobile = styled(OverlayCartUpsellMobile)`
  ${Breakpoint("tablet", "min")} {
    display: none;
  }
`;

const Shipping = styled(CartShipping)`
  margin-top: auto;
  padding-top: 8px;
`;

const Top = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  margin: 0 calc(-1 * var(--inset)) 0 calc(-1 * var(--inset));
`;

const Items = styled(CartItems)`
  padding: 0 var(--inset);
`;
const Empty = styled(CartEmpty)`
  margin: var(--spacing-8) var(--inset) var(--spacing-16);
`;

const Bottom = styled.div`
  flex-grow: 0;
`;

export default OverlayCart;

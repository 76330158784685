import GlobalAccordionRow from '../../global/GlobalAccordionRow'
import SanityContent from './SanityContent'

interface Props {
  title: string
  content: any 
  className?: string
}
const SanityContentAccordionRow: React.FC<Props> = ({ 
  title,
  content,
  className,
}: Props) => {
  return (
    <GlobalAccordionRow title={title} className={className} >
      <SanityContent blocks={content} layout="DEFAULT" />
    </GlobalAccordionRow>
  )
}

export default SanityContentAccordionRow
import { useNextSanityImage } from "next-sanity-image";
import { getClient } from "library/services/sanity";
import {
  getBaseUrlFromLocale,
  getGPCFromProduct,
  getNumberFromProduct,
} from "library/utils/shopify/getters";
import { useRouter } from "next/router";
import { SHOPIFY_DEFAULT_LOCATION } from "library/utils/shopify";

const localisedUrl = (path: string, locale: string) => {
  const baseUrl = getBaseUrlFromLocale(SHOPIFY_DEFAULT_LOCATION);
  return `${baseUrl}${path}`;
};

const generateHrefLangLinks = (path: string) => {
  const { locales } = useRouter();
  const hrefLangLinks = (locales || []).map((loc) => {
    const url = localisedUrl(path, loc);
    return {
      href: url,
      hrefLang: `en-${loc}`,
    };
  });

  return [
    ...hrefLangLinks,
    {
      href: getBaseUrlFromLocale(SHOPIFY_DEFAULT_LOCATION),
      hrefLang: "x-default",
    },
  ];
};

export const formatSeo = (path: string, seo: any = {}) => {
  let imageUrl, title, description;

  if (seo.title) title = seo.title;
  if (seo.description) description = seo.description;

  if (seo.image) {
    const sanityClient = getClient();
    const imageProps: any = useNextSanityImage(sanityClient, seo.image);
    imageUrl = imageProps.loader({ width: 800 });
  }

  const locale = useRouter().locale || SHOPIFY_DEFAULT_LOCATION;

  return {
    canonical: localisedUrl(path, locale),
    languageAlternates: generateHrefLangLinks(path),
    title,
    description,
    openGraph: {
      images: [
        {
          url: imageUrl,
        },
      ],
    },
  };
};

export const formatSanitySeo = (path: string, resource: any = {}) => {
  let imageUrl, title, description;

  const { seo = {} } = resource;

  if (seo.title) title = seo.title;
  if (seo.description) description = seo.description;

  if (seo.image) {
    const sanityClient = getClient();
    const imageProps: any = useNextSanityImage(sanityClient, seo.image);
    imageUrl = imageProps.loader({ width: 800 });
  }

  if (!title) {
    if (resource?.title) title = resource.title;
    if (resource?.store?.title) title = resource.store.title;
  }

  const locale = useRouter().locale || SHOPIFY_DEFAULT_LOCATION;

  return {
    canonical: localisedUrl(path, locale),
    languageAlternates: generateHrefLangLinks(path),
    title,
    description,
    openGraph: {
      images: [
        {
          url: imageUrl,
        },
      ],
    },
  };
};

export const formatSanityProductSeo = (
  path: string,
  product: any = {},
  locale,
) => {
  const base = formatSanitySeo(path, product);
  const { shopifyProduct } = product;
  const variant = shopifyProduct.variants.edges[0].node;

  let currency = "USD";
  if (locale == "NZ") currency = "NZD";
  if (locale == "AU") currency = "AUD";
  if (locale == "EU") currency = "EUR";
  if (locale == "UK") currency = "GBP";
  if (locale == "CA") currency = "CAD";

  const baseUrl = getBaseUrlFromLocale(locale);

  const price = {
    amount: variant.price.amount,
    currency,
  };

  return {
    ...base,
    openGraph: {
      ...base.openGraph,
      url: `${baseUrl}/products/${shopifyProduct.handle}`,
      price,
      locale,
      google_product_category: getGPCFromProduct(shopifyProduct),
      // 'product:availability':  'available for order',
      // 'product:retailer_item_id':  variant.sku,
      // 'product:price': price,
    },
    additionalMetaTags: [
      {
        property: "product:category",
        content: getGPCFromProduct(shopifyProduct),
      },
      {
        property: "product:item_group_id",
        content: getNumberFromProduct(shopifyProduct),
      },
      {
        property: "product:locale",
        content: locale,
      },
      {
        property: "product:brand",
        content: "Yu Mei",
      },
      {
        property: "product:condition",
        content: "new",
      },
    ],
  };
};

import styled, { css } from 'styled-components'
import SvgClose from 'frontend/src/components/svg/SvgClose'
import FormNewsletter from 'frontend/src/components/form/FormNewsletter'
import { TypesetHeadingXS } from '../typeset/Typeset'
import { Hover, TransitionDefault, TransitionSlide, Wrapper } from '../../styles/mixins'
import UtilWrapper from 'frontend/src/components/util/UtilWrapper';
import { useSettings } from '../../contexts/SettingsProvider';
import SanityContent from '../sanity/content/SanityContent'
import { Breakpoint, Columns, MobileBreakpoint } from '../../styles/functions';
import { useNewsletter } from '../../hooks/useNewsletter'


function OverlayNewsletter() {
  const settings = useSettings();
  const newsletterHandler = useNewsletter();
  const { isOpen, close } = newsletterHandler;

  return (
    <Overlay>
      <Container $isActive={isOpen} >
        <Inner>
          <Message>
            <SanityContent 
              layout="DEFAULT" 
              blocks={settings.overlayNewsletter.content} 
            />
          </Message>
          <NewsletterForm newsletterHandler={newsletterHandler} />
          <Accept onClick={close}>
            <SvgClose />
          </Accept>
        </Inner>
      </Container>
      <Background onClick={close} $isActive={isOpen} />
    </Overlay>
  )
}

const Overlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top:0;
  z-index: 9999999999;
  pointer-events: none;
`

const Container = styled.section<{$isActive}>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: ${TransitionDefault};
  background: var(--color-pistachio);
  pointer-events: none;
  opacity: 0;

  ${props => props.$isActive && css`
    pointer-events: auto;
    opacity: 1;
  `}
`

const Inner = styled(UtilWrapper)`
  ${Wrapper};
  padding: var(--spacing-16) 0 var(--spacing-32);
  position: relative;
`

const Message = styled(TypesetHeadingXS)`
  padding-bottom: var(--spacing-32);

  margin-right: auto;
${Breakpoint('mobile', 'min')} {
  width: ${Columns(7)};
}

${MobileBreakpoint} {
  width: ${Columns(11)};
}
`

const NewsletterForm = styled(FormNewsletter)`
  
  ${Breakpoint('mobile', 'min')} {
    width: ${Columns(6)};
    margin-right: auto;
  }
`

const Accept = styled.button`
  position: absolute;
  right:0;
  top: var(--spacing-16);

  svg {
    ${TransitionDefault}
  }

  ${Hover} {
    svg {
      opacity: 0.5;
    }
  }}
`


const Background = styled.div<{$isActive}>`
  position: absolute;
  left:0;right:0;
  top:0;bottom:0;
  z-index: -1;
  background: var(--color-charcoal);
  opacity: 0;
  transition: ${TransitionSlide};
  z-index: 5;
  cursor: pointer;

  ${props => props.$isActive && css`
    pointer-events: auto;
    opacity: 0.25;
  `}
`

export default OverlayNewsletter

import { FC, useEffect, useState } from 'react'
import { useEvent, useLocalStorage } from 'react-use'
import { GridContainer, GridColumns, GridColumn } from './UtilGrid.styles'

interface Props {
  columns?: number
  fixed?: boolean
  gutter?: string
  margin?: string
  name?: string
}

const UtilGrid: FC<Props> = ({ columns = 24, name = 'utilGrid' }) => {
  const [active, setActive] = useState(false)
  const [storage, setStorage] = useLocalStorage(name, false)

  const useColumns = (count: number) => Array(count).fill(null)

  function toggleActive() {
    setStorage(!active)
  }

  function onKeyDown(event: KeyboardEvent) {
    if (
      event.altKey &&
      event.ctrlKey &&
      event.shiftKey &&
      event.code === 'KeyG'
    )
      toggleActive()
  }

  useEvent('keydown', onKeyDown)

  useEffect(() => {
    setActive(!!storage)
  }, [storage])

  return (
    <GridContainer active={active}>
      <GridColumns>
        {useColumns(columns).map((_, i) => (
          <GridColumn key={i} />
        ))}
      </GridColumns>
    </GridContainer>
  )
}

export default UtilGrid

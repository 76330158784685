import { useHeaderSubmenu } from "../../contexts/HeaderSubmenuProvider";
import { useInterface } from "../../contexts/InterfaceProvider";
import { useCart } from "../../contexts/CartProvider";
import LinkInline from "../link/LinkInline";
import { signOut } from "next-auth/react";
import { useCustomer } from "../../contexts/CustomerProvider";
import { useRouter } from "next/router";
import { useState } from "react";
import { useEffectOnce } from "react-use";
import { UnstyledLinkInline } from "../../styles/mixins";
import styled from "styled-components";

type Props = {
  link: any;
  children?: any;
  [key: string]: any;
};

const SanityLinkAction: React.FC<Props> = ({
  link,
  children,
  ...props
}: Props) => {
  const router = useRouter();

  const { count, country } = useCart();
  const { setOverlay, setAccountForm } = useInterface();
  const { showSubmenu } = useHeaderSubmenu();
  const { isLoggedIn } = useCustomer();

  // TODO: Put this in the README/Docs
  // Making the title # uses the programmatic version
  const checkTitle = (title) => {
    return title != "#" && title;
  };

  const [isDesktop, setIsDesktop] = useState(true);
  useEffectOnce(() => {
    if (
      typeof window != "undefined" &&
      window?.matchMedia("(hover: none)")?.matches
    ) {
      setIsDesktop(false);
    }
  });

  const actions = [
    {
      name: "SUBMENU_SHOP",
      title: "Shop",
      actions: {
        onMouseEnter: () => isDesktop && showSubmenu("SHOP"),
        onClick: () =>
          isDesktop ? router.push("/collections/all") : showSubmenu("SHOP"),
      },
    },
    {
      name: "SUBMENU_CLUB",
      title: "Club Yu Mei",
      actions: {
        onMouseEnter: () => isDesktop && showSubmenu("CLUB"),
        onClick: () =>
          isDesktop ? router.push("/join-club-yu-mei") : showSubmenu("CLUB"),
      },
    },
    {
      name: "SUBMENU_ABOUT",
      title: "About",
      actions: {
        onMouseEnter: () => isDesktop && showSubmenu("ABOUT"),
        onClick: () =>
          isDesktop ? router.push("/about") : showSubmenu("ABOUT"),
      },
    },
    {
      name: "SEARCH",
      title: "Search",
      actions: {
        onClick: () => setOverlay("SEARCH"),
      },
    },
    {
      name: "ACCOUNT",
      title: checkTitle(link.title)
        ? link.title
        : !!isLoggedIn
        ? "Account"
        : "Login",
      actions: {
        onClick: () => {
          if (isLoggedIn) {
            router.push("/account");
          } else {
            setAccountForm("LOGIN");
            setOverlay("ACCOUNT");
          }
        },
      },
    },
    {
      name: "REGISTER",
      title: checkTitle(link.title) ? link.title : "Join Club Yu Mei",
      actions: {
        onClick: () => {
          setAccountForm("REGISTER");
          setOverlay("ACCOUNT");
        },
      },
    },
    {
      name: "RESET",
      title: checkTitle(link.title) ? link.title : "Reset Password",
      actions: {
        onClick: () => {
          setAccountForm("RESET");
          setOverlay("ACCOUNT");
        },
      },
    },
    {
      name: "LOGOUT",
      title: "Log Out",
      actions: {
        onClick: () =>
          signOut({
            callbackUrl: "/",
          }),
      },
    },
    {
      name: "CURRENCY",
      title: checkTitle(link.title) ? link.title : country.currency.isoCode,
      actions: {
        onClick: () => setOverlay("LOCATION"),
      },
    },
    {
      name: "LOCATION",
      title: checkTitle(link.title) ? (
        link.title
      ) : (
        <>
          {country.name}
          &nbsp;/&nbsp;
          {country.currency.isoCode}
          &nbsp;
          {country.currency.symbol}
        </>
      ),
      actions: {
        onClick: () => setOverlay("LOCATION"),
      },
    },
    {
      name: "CART",
      title: `Bag ${count}`,
      actions: {
        onClick: () => setOverlay("CART"),
      },
    },
    {
      name: "SHIPPING",
      title: children,
      actions: {
        onClick: () => setOverlay("SHIPPING"),
      },
    },
  ];

  const action = actions.find((a) => a.name == link.action);

  if (!action) return <></>;

  const combinedProps = {
    ...action.actions,
    ...props,
  };

  // delete combinedProps['href'];

  return <StyledLink {...combinedProps}>{children || action.title}</StyledLink>;
};

const StyledLink = styled(LinkInline)`
  ${UnstyledLinkInline}
`;

export default SanityLinkAction;

import { useRouter } from 'next/router';
import { useCustomer } from '../contexts/CustomerProvider'
import useFormAccount from './useFormAccount';
import { pixelRegistration } from './usePixel';

function useFormActivate() {
  const router = useRouter();
  const {customerActivate} = useCustomer();
  return useFormAccount(async ({
    password,
  }) => {
    const {
      url
    } = router.query;

    if(!url) {
      throw "Incorrect url, please try clicking the link in your activation email"
    }

    // Attempt to activate customer
    await customerActivate({
      password,
      activationUrl: url,
    });

    return "Successfully activated"
  })
}

export default useFormActivate
import styled from 'styled-components'
import ButtonPrimary from 'frontend/src/components/button/ButtonPrimary';
import { TypeCaptionL } from '../../styles/mixins';
import { useNewsletter } from '../../hooks/useNewsletter';

interface ComponentProps {
  newsletterHandler?: any,
  className?: string
}

const FormNewsletter: React.FC<ComponentProps> = function({
  newsletterHandler,
  className,
}: ComponentProps) {
  const handler = newsletterHandler || useNewsletter();
  const { successful, submitErrors, onSubmit, handleSubmit, register } = handler;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={className}>
      {successful && (
        <SuccessMessage>
          Thank you for signing up
        </SuccessMessage>
      )}

      {!successful && (
        <>
            <Input
              {...register('email', { required: true })}
              type="email"
              placeholder="Email Address"
              required
            />

            <HiddenLabel htmlFor="email">
              Email Address*
            </HiddenLabel>
            
            <SubmitButton>
              Subscribe
            </SubmitButton>

          {submitErrors && <SubmitErrors>{submitErrors}</SubmitErrors>}
        </>
      )}
    </Form>
  )
}

export const HiddenLabel = styled.label`
  overflow: hidden;
  height: 0px;
  position: absolute;
  display: block;
  margin-left: -9999px;
`

const Form = styled.form`
  user-select: none;
  display: flex;
`

const Input = styled.input`
  ${TypeCaptionL};
  border: 1px solid var(--color-charcoal200);
  background: var(--color-charcoal100);
  padding: 8px;
  margin-right: 4px;
  flex-shrink: 1;
  flex-grow: 1;
  width: auto;

&:focus {
  border: 1px solid var(--color-charcoal300);
}
`

const SubmitErrors = styled.div`
  margin-top: 20px;
  color: var(--color-earth);
  font-style: italic;
`

const SubmitButton = styled(ButtonPrimary)`
  --ButtonPrimary-background: var(--color-charcoal100);
  --ButtonPrimary-border: var(--color-charcoal200);
  --ButtonPrimary-color: var(--color-charcoal);

  &&:hover {
    /* --ButtonPrimary-background: transparent; */
  --ButtonPrimary-background: var(--color-charcoal100);
    --ButtonPrimary-border: var(--color-charcoal);
    --ButtonPrimary-color: var(--color-charcoal);
  }

  &&:active {
    --ButtonPrimary-background: var(--color-charcoal);
    --ButtonPrimary-border: var(--color-charcoal);
    --ButtonPrimary-color: var(--color-white);
  }
`

const SuccessMessage = styled.h2`
  ${TypeCaptionL};
`


export default FormNewsletter

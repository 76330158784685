import {
  findNearestDefaultImageRatio,
  ratioToNumber,
} from "library/utils/image";
import { ImageProps } from "next/legacy/image";
import styled from "styled-components";
import GlobalImage from "../global/GlobalImage";
import { constructShopifyImageUrl } from "library/utils/shopify";

interface ShopifyImageProps {
  className?: string;
  ratio?: string;
  image: {
    altText?: string | null;
    height?: number | null;
    width?: number | null;
    originalSrc: string;
  };
  layout?: ImageProps["layout"];
  [key: string]: any;
}

const shopifyImageLoader =
  ({ ratio }) =>
  ({ src, width, quality }) => {
    const finalWidth = Math.min(width, 1600);
    const height = Math.ceil(finalWidth * ratio);

    return constructShopifyImageUrl({
      src,
      width: finalWidth,
      height,
      quality,
    });
  };

const ShopifyImage: React.FC<ShopifyImageProps> = ({
  image,
  ratio,
  className,
  layout = "responsive",
  ...props
}: ShopifyImageProps) => {
  if (!image) return <></>;
  const imageWidth = image?.width ? image.width : 400;
  const imageHeight = image?.height ? image.height : 400;

  const nearestRatio = findNearestDefaultImageRatio(imageHeight / imageWidth);
  const baseRatio = ratioToNumber(ratio || nearestRatio);
  const loader = shopifyImageLoader({ ratio: baseRatio });

  const baseWidth = Math.min(imageWidth, 1200);
  const baseHeight = Math.ceil(baseWidth * baseRatio);
  const blurUrl = loader({
    src: `${image.originalSrc}`,
    width: 64,
    quality: 50,
  });

  const combinedProps: any = {
    sizes: "100vw",
    alt: image.altText,
    src: image.originalSrc,
    width: baseWidth,
    height: baseHeight,
    blurDataURL: blurUrl,
    placeholder: "blur",
    layout,
    loader,
    className,
    ...props,
  };

  if (combinedProps.layout == "fill") {
    delete combinedProps.width;
    delete combinedProps.height;
    combinedProps.objectFit = combinedProps.objectFit || "cover";
  }

  return <Image {...combinedProps} />;
};

const Image = styled(GlobalImage)``;

export default ShopifyImage;

import {
  CartBuyerIdentityInput,
  CartFieldsFragment,
  CartInput,
  CartLineInput,
  CartLineUpdateInput,
  ShopifySdk,
} from '.'

// export async function cartBuyerIdentityUpdate(
//   cartId: string,
//   buyerIdentity: CartBuyerIdentityInput
// ) {
//   try {
//     const data = await ShopifySdk.cartBuyerIdentityUpdate({
//       buyerIdentity,
//       cartId,
//     })
//     return data?.cartBuyerIdentityUpdate?.cart || null
//   } catch (e: any) {
//     console.error('Error updating buyer identity', { cause: e.message })
//     return null
//   }
// }

export async function cartCreate(cartInput?: CartInput) {
  try {
    const data = await ShopifySdk.cartCreateMutation({ cartInput })
    return data?.cartCreate?.cart || null
  } catch (e: any) {
    console.error('Error creating cart', { cause: e.message })
    return null
  }
}

export async function cartLinesAdd(
  lines: CartLineInput | CartLineInput[],
  cartId: string
): Promise<CartFieldsFragment | null> {
  try {
    const data = await ShopifySdk.cartLinesAdd({ lines, cartId })
    return data?.cartLinesAdd?.cart || null
  } catch (e: any) {
    console.error('Error adding item to cart: ', { cause: e.message })
    return null
  }
}

export async function cartLinesRemove(
  lineIds: string[],
  cartId: string
): Promise<CartFieldsFragment | null> {
  try {
    const data = await ShopifySdk.cartLinesRemove({
      cartId,
      lineIds,
    })
    return data?.cartLinesRemove?.cart || null
  } catch (e: any) {
    console.error('Error removing item from cart: ', { cause: e.message })
    return null
  }
}

export async function cartLinesUpdate(
  lines: CartLineUpdateInput[],
  cartId: string
): Promise<CartFieldsFragment | null> {
  try {
    const data = await ShopifySdk.cartLinesUpdate({ cartId, lines })
    return data?.cartLinesUpdate?.cart || null
  } catch (e: any) {
    console.error('Error updating item in cart: ', { cause: e.message })
    return null
  }
}

export async function cartNoteUpdate(
  note: string,
  cartId: string
): Promise<CartFieldsFragment | null> {
  try {
    const data = await ShopifySdk.cartNoteUpdate({ cartId, note })
    return data?.cartNoteUpdate?.cart || null
  } catch (e: any) {
    console.error('Error updating item in cart: ', { cause: e.message })
    return null
  }
}

export async function cartBuyerUpdate(
  buyerIdentity: CartBuyerIdentityInput,
  cartId: string
): Promise<CartFieldsFragment | null> {
  try {
    const data = await ShopifySdk.cartBuyerUpdate({ buyerIdentity, cartId })
    return data?.cartBuyerIdentityUpdate?.cart || null
  } catch (e: any) {
    console.error('Error updating item in cart: ', { cause: e.message })
    return null
  }
}

export async function cartRetrieve(
  id: string,
): Promise<CartFieldsFragment | null> {
  try {
    const data = await ShopifySdk.cartById({ id })
    return data?.cart || null
  } catch (e: any) {
    console.error('Cart retrieve error: ', { cause: e.message })
    return null
  }
}

import styled from 'styled-components'
import { TypeCaptionM } from '../../../styles/mixins';
import PriceRange from '../../price/PriceRange';

interface Props {
  product?: any
  shopifyProduct?: any
  withColour?: boolean
  className?: string
}
const ProductCardPrice: React.FC<Props> = ({ 
  shopifyProduct,
  className,
}: Props) => {

  const minComparePrice = parseFloat(shopifyProduct.compareAtPriceRange.minVariantPrice.amount);
  const hasSale = minComparePrice > 0 
  
  return (
    <Container className={className}>
      {hasSale && <Sale priceRange={shopifyProduct.compareAtPriceRange} />
}
      <PriceRange priceRange={shopifyProduct.priceRange} />
    </Container>
  )
}

const Container = styled.div`
  margin-top: 8px;
  ${TypeCaptionM}
`

const Sale = styled(PriceRange)`
  color: var(--color-charcoal400);
  text-decoration: line-through;
`

export default ProductCardPrice
import styled from "styled-components";
import useFormLogin from "../../hooks/useFormLogin";
import ButtonPrimary from "../button/ButtonPrimary";
import ButtonUnderline from "../button/ButtonUnderline";

import FormInputEmail from "./FormInputEmail";
import FormInputPassword from "./FormInputPassword";
import { FormControls, FormErrors } from "./FormStyles";

interface P {
  setMode: any;
  [key: string]: any;
}
const FormLogin: React.FC<P> = ({ setMode, ...props }: P) => {
  const { disabled, errors, onSubmit, register, submitErrors } = useFormLogin();

  return (
    <Container onSubmit={onSubmit} {...props}>
      {submitErrors && (
        <FormErrors style={{ marginBottom: "var(--spacing-16)" }}>
          {submitErrors.join(", ")}
        </FormErrors>
      )}

      <FormInputEmail error={errors.email} register={register} required />

      <FormInputPassword
        error={errors.password}
        register={register}
        required
        style={{
          marginBottom: "var(--spacing-32)",
        }}
      />

      <FormControls
        style={{
          marginBottom: "var(--spacing-24)",
        }}
      >
        <ButtonPrimary disabled={disabled}>Login</ButtonPrimary>
        <ButtonUnderline onClick={() => setMode("RECOVER")}>
          Forgot your password?
        </ButtonUnderline>
      </FormControls>

      <div>
        <p>Not a member yet?</p>

        <ButtonUnderline onClick={() => setMode("REGISTER")}>
          Join Club Yu Mei
        </ButtonUnderline>
      </div>
    </Container>
  );
};

const Container = styled.form``;

export default FormLogin;

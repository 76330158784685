import styled from "styled-components";
import ButtonUnderline from "../button/ButtonUnderline";
import ButtonPrimary from "../button/ButtonPrimary";

import { FormControls, FormErrors, FormSuccess } from "./FormStyles";
import FormInputPassword from "./FormInputPassword";
import useFormReset from "../../hooks/useFormReset";

interface P {
  setMode: any;
  [key: string]: any;
}
const FormReset: React.FC<P> = ({ setMode, ...props }: P) => {
  const { disabled, errors, onSubmit, register, submitErrors, successMessage } =
    useFormReset();

  return (
    <Container onSubmit={onSubmit} {...props}>
      {successMessage != "" && (
        <FormSuccess style={{ marginBottom: "var(--spacing-16)" }}>
          {successMessage}
        </FormSuccess>
      )}

      {submitErrors && (
        <FormErrors style={{ marginBottom: "var(--spacing-16)" }}>
          {submitErrors.join(", ")}
        </FormErrors>
      )}

      <FormInputPassword
        error={errors.password}
        register={register}
        required
        style={{
          marginBottom: "var(--spacing-32)",
        }}
      />

      <FormControls>
        <ButtonPrimary disabled={disabled}>Reset your password</ButtonPrimary>
        <ButtonUnderline onClick={() => setMode("LOGIN")}>
          Back to login
        </ButtonUnderline>
      </FormControls>
    </Container>
  );
};

const Container = styled.form``;

export default FormReset;

import { getStyleFromProduct } from "library/utils/shopify/getters";
import styled from "styled-components";
import { TypeBodyM, TypeCaptionM } from "../../../styles/mixins";

interface Props {
  product?: any;
  shopifyProduct?: any;
  withColour?: boolean;
  className?: string;
}
const SanityProductHeading: React.FC<Props> = ({
  product,
  withColour,
  shopifyProduct,
  className,
}: Props) => {
  const { style, colour } = getStyleFromProduct(shopifyProduct);

  return (
    <Container className={className}>
      <Style>{style}</Style>
      {withColour && colour && <Colour>{colour}</Colour>}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 8px;
  flex-grow: 1;
`;

const Style = styled.h3`
  ${TypeBodyM}
  display: grid;
  grid-template-columns: min(50%, 40px) 1fr;
  overflow: hidden;

  &:before {
    ${TypeCaptionM}
    content: 'Style';
    display: inline-flex;
    margin-top: 2px;
  }
`;

const Colour = styled(Style)`
  &:before {
    content: "Colour";
  }
`;

export default SanityProductHeading;

import styled from 'styled-components'
import PriceRender from './PriceRender'

interface P {
  type?: 'from'
  priceRange: {
    minVariantPrice: any
    maxVariantPrice: any
  }
}

const PriceRange: React.FC<P> = ({ 
  priceRange,
  type = 'from',
  ...props
}: P) => {
  const { minVariantPrice, maxVariantPrice } = priceRange;
  const hasPriceRange = minVariantPrice.amount != maxVariantPrice.amount;

  return (
    <Container {...props}>
      { hasPriceRange && <>from&nbsp;</>}
      <PriceRender amount={minVariantPrice.amount} currencyCode={minVariantPrice.currencyCode} />
    </Container>
  )
}

const Container = styled.div``

export default PriceRange
// import styled from 'styled-components'

import ArticleCardGrid from "./ArticleCardGrid"
import ArticleCardOverview from "./ArticleCardOverview"
import ArticleCardRelated from "./ArticleCardRelated"
import ArticleCardSubmenu from "./ArticleCardSubmenu"

interface P {
  layout: "RELATED" | "SUBMENU" | "OVERVIEW" | "GRID"
  index?: number
  article: any
}

const ArticleCard: React.FC<P> = ({ 
  layout,
  article,
  index,
  ...props
}: P) => {

  return (
    <>
    {
      layout == "RELATED" && (
        <ArticleCardRelated article={article} {...props} />
      )
    }
    {
      layout == "SUBMENU" && (
        <ArticleCardSubmenu article={article} {...props} />
      )
    }
      {
        layout == "OVERVIEW" && (
          <ArticleCardOverview article={article} index={index} {...props} />
        )
      }
      {
        layout == "GRID" && (
          <ArticleCardGrid article={article} {...props} />
        )
      }
    </>
  )
}

// const Wrapper = styled.div``

export default ArticleCard
// import { LayoutStyles } from 'frontend/src/styles/layout'
import NextLink from 'next/link'
import styled from 'styled-components'
import { LinkInline as LinkInlineStyle } from '../../styles/mixins'

type Props = {
  className?: string,
}

const LinkStudio: React.FC<Props> = ({className}: Props) => {
  const href = "https://1of1studio.com/";
return (
    <Wrapper className={className + ' link-inline'}>
    {
      href && (
        // <NextLink 
        //   href={href}
        //   passHref
        // >
          <a target="_blank" href={href} rel="noreferrer">1/1</a>
        /* </NextLink> */
      )
    }
    </Wrapper>
  )
}


const Wrapper = styled.span`
  ${LinkInlineStyle}
`

const Link = styled.a``

export default LinkStudio

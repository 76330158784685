
import traverse from "traverse"
import { convertSanityRefToShopifyProductNumber } from "../../utils/sanity"

export function formatDate(dateString: string) {
  const [fullYear, month, day] = dateString.split("-")
  return `${day}.${month}.${fullYear.substr(-2)}`
}

export function filterDocumentToSingleItem(docs: any[]) {
  const documents = docs || []
  const overlayed = documents.reduce((map, doc) => {
    if (!doc._id) {
      throw new Error('Ensure that `_id` is included in query projection')
    }

    const isDraft = doc._id.startsWith('drafts.')
    const id = isDraft ? doc._id.slice(7) : doc._id
    return isDraft || !map.has(id) ? map.set(id, doc) : map
  }, new Map())

  return Array.from(overlayed.values())?.[0] as any
}

export function getShopifyProductIdsFromSanityDocument(
  document: any,
) {
  return traverse(document).reduce(function (acc: any, node: any) {
    const getRef = (node: any) => node?.product?._ref || node?._id || node?._type == 'product' && node?._ref;
    const getNumber = (node: any) => getRef(node) ? 
      convertSanityRefToShopifyProductNumber(getRef(node)) :
      null

    if(['product', 'productWithVariant'].includes(node?._type)) {
      if(!getRef(node)) return acc;
      return [ getNumber(node), ...acc, ]
    } 

    return acc;
  }, []);
}

export function getShopifyProductIdsFromSanityDocuments(documents: any[]) {
  return documents.map(getShopifyProductIdsFromSanityDocument).flat();
}
// import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import { TypeBodyM } from '../../../styles/mixins';
import SanityContentAccordionRow from './SanityContentAccordionRow';


interface Props {
  className?: string,
  title?: string,
  anchor?: string,
  rows: any[],
}

const SanityContentAccordion: React.FC<Props> = ({ 
  title, 
  anchor, 
  rows, 
  className, 
}: Props) => {
    return (
      <Content className={className} data-anchor={anchor}>
        { title && <Heading>{title}</Heading> }
        { rows.map((row) => (
          <Row title={row.title} content={row.content} key={row._key} />
        )) }
      </Content>
  )
}


const Content = styled.div`

`

const Heading = styled.div`
  ${TypeBodyM};
  margin-bottom: var(--spacing-24);
`

const Row = styled(SanityContentAccordionRow)`
  
`

// export const Styled = styled(SanityContentFootnote)

export default SanityContentAccordion
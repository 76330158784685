import styled from 'styled-components'
import { usePrice } from '../../hooks/usePrice';
import { TypeCaptionM } from '../../styles/mixins';
import PriceRender from '../price/PriceRender';
// import { TypeBodyM, TypeCaptionL } from '../../styles/mixins';

interface Props {
  item?: any
  className?: string
}
const CartItemPrice: React.FC<Props> = ({ 
  item,
  className,
}: Props) => {
  return (
    <Container className={className}>
      <PriceRender display="CART" amount={item.estimatedCost.totalAmount.amount} currencyCode={item.estimatedCost.totalAmount.currencyCode} />
    </Container>
  )
}

const Container = styled.div`
  /* ${TypeCaptionM} */
`

export default CartItemPrice
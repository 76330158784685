import { formatDate } from 'library/services/sanity/helpers'
import styled from 'styled-components'
import { Breakpoint, Columns, MobileBreakpoint } from '../../styles/functions'
import { BezierDefault, TypeBodyM, TypeHeadingXS } from '../../styles/mixins'
import Link from '../link/LinkRender'

import SanityImage from "../sanity/SanityImage" 

interface P {
  article: any,
  [key: string]: any
}
const ArticleCardGrid: React.FC<P> = ({ 
  article,
  ...props
}: P) => {

  const date = formatDate(article.publishedAt);
  
  return (
    <Wrapper {...props}>
      <LinkWrap href={`/journal/${article.slug.current}`}>
        <Image alt={article.title} ratio="1x1" image={article.image} />
        <Details>
          <time itemProp="datePublished" dateTime={article.publishedAt}>{date}</time>
          <div>Words by {article.author}</div>
          <Title>
            {article.title}
          </Title>
        </Details>
      </LinkWrap>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-top: var(--spacing-16);
  padding-bottom: var(--spacing-16);
  border-top: 1px solid var(--color-charcoal500);
  width: 100%;
  
  ${Breakpoint('mobile', 'min')} {
    width: ${Columns(8)};
  }
`

const Image = styled(SanityImage)`
  filter: saturate(0);
  mix-blend-mode: multiply;
  transition: filter 0.3s ${BezierDefault};
`

const LinkWrap = styled(Link)`
  display: grid;
  grid-template-columns: ${Columns(4)} 1fr;
  gap: var(--gutter);

  &:hover ${Image} {
    filter: saturate(1);
  }
  
  ${MobileBreakpoint} {
    grid-template-columns: ${Columns(6)} 1fr;
  }
`

const Details = styled.div`
  ${TypeBodyM}
`

const Title = styled.h3`
  ${TypeHeadingXS};
  margin-top: var(--spacing-16);
`

export default ArticleCardGrid
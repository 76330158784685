// import { LayoutStyles } from 'frontend/src/styles/layout'
// import NextLink from 'next/link'
import styled from "styled-components";
// import SvgLogo from '../svg/SvgLogo'
import SanityMenu from "../sanity/SanityMenu";
import { Breakpoint, Columns } from "../../styles/functions";
import { useNavigation } from "../../contexts/NavigationProvider";
import UtilWrapper from "../util/UtilWrapper";
import { TypeBodyM, TypeCaptionL, TypeCaptionM } from "../../styles/mixins";
import LinkStudio from "../link/LinkStudio";
import FormNewsletter from "../form/FormNewsletter";
import ErrorBoundary from "../ErrorBoundary";
import FooterLogos from "./FooterLogos";

type Props = {
  className?: string;
};

const FooterMobile: React.FC<Props> = ({ className }: Props) => {
  const navigation = useNavigation();
  const footer = navigation?.find((m) => m._id == "footer-mobile");

  if (!footer) return null;

  const { menu1, menu2, menu3, menu4, menu5, menu6 } = footer;
  const year = new Date().getFullYear();

  return (
    <ErrorBoundary>
      <Footer className={className}>
        <Top>
          <SanityMenu layout="SUBMENU" links={menu1} />
          <SanityMenu layout="SUBMENU" links={menu2} />
          <SanityMenu layout="SUBMENU" links={menu3} />
          <SanityMenu layout="SUBMENU" links={menu4} />
          <SanityMenu layout="SUBMENU" links={menu5} />
          <SanityMenu layout="SUBMENU" links={menu6} />
          <FooterLogos footer={footer} />
        </Top>
        <Bottom>
          <Newsletter>
            <NewsletterHeading>Subscribe to our Newsletter</NewsletterHeading>
            <FormNewsletter />
          </Newsletter>

          <Copy>&copy; {year} Yu Mei</Copy>

          <Studio />
        </Bottom>
      </Footer>
    </ErrorBoundary>
  );
};

const Footer = styled.footer`
  padding-top: 16px;
  padding-bottom: 56px;
  background: var(--color-background);

  ${Breakpoint("mobile", "min")} {
    display: none;
  }
`;

const Top = styled(UtilWrapper)`
  grid-template-columns: 1fr 1fr;
  gap: 32px var(--gutter);
  display: grid;
  margin-bottom: 120px;
`;

const Bottom = styled(UtilWrapper)`
  display: flex;
  flex-wrap: wrap;
`;

const Newsletter = styled.div`
  /* width: ${Columns(6)}; */
  margin-bottom: 8px;
  width: 100%;
`;

const NewsletterHeading = styled.h2`
  ${TypeBodyM};
  margin-bottom: 16px;
`;

const Copy = styled.div`
  ${TypeCaptionM};
  color: var(--color-charcoal600);
`;

const Studio = styled(LinkStudio)`
  ${TypeCaptionM};
  color: var(--color-charcoal600);
  margin-left: 16px;
`;

export default FooterMobile;

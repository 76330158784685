import { CustomerActivateByUrlMutationVariables, CustomerActivateInput, CustomerUpdateMutationVariables, CustomerCreateMutationVariables, CustomerResetByUrlMutationVariables } from './generated';
import traverse from "traverse";
import {
  CustomerAccessTokenCreateInput,
  ShopifySdk,
} from '.'

const handleUserErrors = (response: any) => {
  const errors = traverse(response).reduce(function (acc: any[], node: any) {
    if(node?.customerUserErrors) {
      acc = [
        ...node.customerUserErrors.map((e: any) => e.message),
        ...acc
      ]
    }

    return acc;
  }, []);
  if(errors.length > 0) throw errors.join(', ');
}

const handleError = (error: any) => {
  // Handle Graphql Error
  if(error?.response?.errors) {
    throw new Error(error.response.errors.map((e: any) => e?.message || e).join(', '))
  }

  // Otherwise just bubble
  throw error;
}

export async function customerAccessTokenCreate(input: CustomerAccessTokenCreateInput) {
  try {
    const response = await ShopifySdk.customerAccessTokenCreate({input})
    handleUserErrors(response)
    return response?.customerAccessTokenCreate?.customerAccessToken || null
  } catch (error: any) {
    handleError(error);
  }
}

export async function customerAccount(customerAccessToken: string) {
  try {
    const response = await ShopifySdk.customerAccount({customerAccessToken})
    handleUserErrors(response)
    return response?.customer || null
  } catch (error: any) {
    handleError(error);
  }
}

export async function customerRecover(email: string) {
  try {
    const response = await ShopifySdk.customerRecover({email})
    handleUserErrors(response)
    return response?.customerRecover || null
  } catch (error: any) {
    handleError(error);
  }
}

export async function customerRegister(input: CustomerCreateMutationVariables) {
  try {
    const response = await ShopifySdk.customerCreate(input);
    handleUserErrors(response)
    return response?.customerCreate?.customer || null
  } catch (error: any) {
    handleError(error);
  }
}

export async function customerUpdate({
  customer, 
  customerAccessToken,
}: CustomerUpdateMutationVariables) {
  try {
    const response = await ShopifySdk.customerUpdate({customer, customerAccessToken})
    handleUserErrors(response)
    return response?.customerUpdate?.customer || null
  } catch (error: any) {
    handleError(error);
  }
}

export async function customerActivateByUrl({
  activationUrl, 
  password,
}: CustomerActivateByUrlMutationVariables) {
  try {
    const response = await ShopifySdk.customerActivateByUrl({ activationUrl, password, })
    handleUserErrors(response)
    return response?.customerActivateByUrl || null
  } catch (error: any) {
    handleError(error);
  }
}

export async function customerResetByUrl({
  resetUrl, 
  password,
}: CustomerResetByUrlMutationVariables) {
  try {
    const response = await ShopifySdk.customerResetByUrl({ resetUrl, password, })
    handleUserErrors(response)
    return response?.customerResetByUrl || null
  } catch (error: any) {
    handleError(error);
  }
}
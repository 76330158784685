import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { CheckboxStyle } from '../../styles/mixins'

interface P {
  isActive: boolean
  children: React.ReactNode
  [key: string]: any
}
const ButtonCheckbox: React.FC<P> = ({ 
  isActive,
  children,
  ...props
}: P) => {

  return (
    <Button  {...props}>
      <Box $isActive={isActive} />
      <Content>{children}</Content>
    </Button>
  )
}

const Button = styled.button`
  display: flex;
  position: relative;
`

const Box = styled.div<{$isActive}>`
  ${
    CheckboxStyle
  } 
`

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
`

export default ButtonCheckbox
// import BlockContent from '@sanity/block-content-to-react';
import { findNearestDefaultImageRatio } from "library/utils/image";
import styled, { css } from "styled-components";
import {
  Breakpoint,
  Columns,
  MobileBreakpoint,
} from "../../../styles/functions";
import { Indent, TypeBodyM, TypeHeadingXS } from "../../../styles/mixins";
import ProductCardList from "../../product/card/ProductCardList";
import SanityBlocks from "./../SanityBlocks";
import SanityImageWithCaption from "./../SanityImageWithCaption";
// import { TypesetHeadingXS } from '../typeset/Typeset';
// import SanityContentImage from './SanityContentImage';
import { useState } from "react";
import OverlayGallery from "../../overlay/OverlayGallery";
import SanityImage from "./../SanityImage";
import SanityContentInner from "./SanityContentInner";

interface Props {
  className?: string;
  number: number;
  title: string;
  anchor: string;
  images: any;
  content: any;
  products: any;
}

const SanityContentComposite: React.FC<Props> = ({
  number,
  title,
  anchor,
  images,
  content,
  products,
  className,
  ...props
}: Props) => {
  const caption = `${number?.toString().padStart(2, "0")}.`;

  const [isOverlayActive, setIsOverlayActive] = useState(false);
  const [overlayIndex, setOverlayIndex] = useState(0);

  const slides = images?.map(({ image, caption, _key }) => (
    <SanityImage
      alt={image.alt || ""}
      ratio={findNearestDefaultImageRatio(
        image.asset?.metadata?.dimensions?.aspectRatio,
      )}
      image={image}
      key={_key}
    />
  ));

  const thumbnails = images?.map(({ image, caption, _key }) => (
    <SanityImage alt={image.alt || ""} ratio="1x1" image={image} key={_key} />
  ));

  const onClick = (index) => {
    setOverlayIndex(index);
    setIsOverlayActive(true);
  };

  return (
    <Wrapper className={className} data-anchor={anchor}>
      <Images>
        {images?.map(({ image, caption, _key }, index) => (
          <button onClick={() => onClick(index)} key={_key}>
            <SanityImageWithCaption
              ratio={findNearestDefaultImageRatio(
                image.asset?.metadata?.dimensions?.aspectRatio,
              )}
              image={image}
              caption={caption}
            />
          </button>
        ))}
      </Images>

      <Description>
        {number && title && <Heading $caption={caption}>{title}</Heading>}
        <Content blocks={content} />
      </Description>

      {products?.length && <Products layout="XS" products={products} />}

      {isOverlayActive && (
        <OverlayGallery
          slides={slides}
          thumbnails={thumbnails}
          setIsOverlayOpen={setIsOverlayActive}
          initialIndex={overlayIndex}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${Breakpoint("mobile", "min")} {
    display: grid;
    grid-template-columns: ${Columns(4)} ${Columns(7)} ${Columns(9)} ${Columns(
        1,
      )} ${Columns(3)};
    grid-gap: 0 var(--gutter);
  }

  ${MobileBreakpoint} {
    display: flex;
    flex-direction: column;
  }
`;

const Content = styled(SanityContentInner)`
  ${TypeHeadingXS}
  > p {
    ${Indent}
  }
`;

const Images = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-24);

  ${Breakpoint("mobile", "min")} {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    margin-bottom: auto;
    position: sticky;
    top: var(--header-height);
  }

  ${MobileBreakpoint} {
    display: none;
  }
`;

const Description = styled.div`
  ${Breakpoint("mobile", "min")} {
    grid-column: 3 / 5;
  }

  ${MobileBreakpoint} {
    margin-bottom: 32px;
  }
`;

const Heading = styled.h2<{ $caption: string }>`
  ${TypeHeadingXS};
  ${Indent}
  text-transform: uppercase;
  margin-bottom: 8px;

  &:before {
    ${TypeBodyM}
    ${(props) =>
      props.$caption &&
      css`
        content: "${props.$caption}";
      `}
  }
`;

const Products = styled(ProductCardList)`
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: var(--spacing-24) var(--gutter);
  margin-top: var(--spacing-40);
  display: grid;
  width: 100%;

  ${Breakpoint("mobile", "min")} {
    grid-column: 3 / 4;
  }

  ${Breakpoint("tablet", "max")} {
    grid-template-columns: 1fr 1fr;
  }
`;

export default SanityContentComposite;

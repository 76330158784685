import styled, { css } from 'styled-components'
import { Wrapper } from 'frontend/src/styles/mixins'
import { TypesetHeadingM } from 'frontend/src/components/typeset/Typeset';
import SanityButton from '../../sanity/SanityButton';

interface P {
  link?: any
  notes?: string
  excerpt?: any[]
  wrapper?: boolean
}


const SanityContentText: React.FC<P> = ({ 
  link,
  notes = '',
  excerpt = [],
  wrapper = true,
  ...props
}: P) => {

  return (
    <Section $wrapper={wrapper} {...props}>
      <SectionText 
        indent={5}
        caption={notes} 
        content={excerpt}  
        captionAlign="SUPER"
      />
      
      {link && <SectionButton link={link} />}
    </Section>
  )
}


const Section = styled.div<{$wrapper}>`
  ${(p) => p.$wrapper && Wrapper};
  margin-bottom: var(--spacing-80);
`

const SectionText = styled(TypesetHeadingM)`
  margin-bottom: var(--spacing-40);
`

const SectionButton = styled(SanityButton)`
`

export default SanityContentText

import styled from "styled-components";

import { useNavigation } from "../../contexts/NavigationProvider";
import { Columns } from "../../styles/functions";
import { Wrapper } from "../../styles/mixins";
import SanityMenu from "../sanity/SanityMenu";
import HeaderSubmenuProduct from "./HeaderSubmenuProduct";

interface P {
  className?: string;
}
const HeaderSubmenuShop: React.FC<P> = ({ className, ...props }: P) => {
  const navigation = useNavigation();

  const { menu1, menu2, menu3 } = navigation?.find(
    (m) => m._id == "submenu-shop",
  );

  return (
    <Submenu className={className}>
      <Container>
        <SanityMenu layout="SUBMENU" links={menu1} />
        <SanityMenu layout="SUBMENU" links={menu2} />
        <SanityMenu layout="SUBMENU" links={menu3} />

        <Reference />
      </Container>
    </Submenu>
  );
};

const Submenu = styled.div`
  ${Wrapper};
  padding-top: 24px;
  padding-bottom: 32px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: ${Columns(3)} ${Columns(3)} ${Columns(3)} ${Columns(8)} ${Columns(
      7,
    )};
  grid-gap: 0 var(--gutter);
`;

const Reference = styled(HeaderSubmenuProduct)`
  grid-column: auto / -1;
`;

export default HeaderSubmenuShop;

import styled, { css } from "styled-components";
import SvgClose from "frontend/src/components/svg/SvgClose";
import { TypesetCaptionL } from "../typeset/Typeset";
import { BezierDefault, Hover, TransitionDefault } from "../../styles/mixins";
import UtilWrapper from "frontend/src/components/util/UtilWrapper";
import { useSettings } from "../../contexts/SettingsProvider";
import SanityContent from "../sanity/content/SanityContent";
import TransitionAccordion from "../transition/TransitionAccordion";
import { bannerPreviouslyClosed, useBanner } from "../../hooks/useBanner";
import { MobileBreakpoint } from "../../styles/functions";
import { useRouter } from "next/router";

const fetchBanner = (path, banners) => {
  return banners.banners.find((_banner) => {
    // Previously closed
    const previouslyClosed = bannerPreviouslyClosed(_banner.title);
    if (previouslyClosed) return false;

    // Path not whitelisted
    if (_banner?.whitelist?.length) {
      const hasPath = _banner?.whitelist?.find((_path) => {
        return path == _path;
      });

      if (!hasPath) return false;
    }

    // Banner hasnt started
    const untilStart = Date.parse(_banner.start) - Date.now();
    if (untilStart > 0) return false;

    // Banner has ended
    if (_banner.end) {
      const untilEnd = Date.parse(_banner.end) - Date.now();
      if (untilEnd < 0) return false;
    }

    return true;
  });
};

function OverlayBanner() {
  const { asPath } = useRouter();
  const { banners } = useSettings();

  const banner = fetchBanner(asPath, banners);
  const { isOpen, close } = useBanner({ name: banner?.title, initial: false });

  if (!banner) return <></>;

  return (
    <TransitionAccordion isOpen={isOpen}>
      <Container $mode={banner.mode}>
        <Inner>
          <Message $mode={banner.mode}>
            <SanityContent layout="BANNER" blocks={banner.content} />
          </Message>

          <Accept onClick={close}>
            <SvgClose />
          </Accept>
        </Inner>
      </Container>
    </TransitionAccordion>
  );
}

const Container = styled.section<{ $mode: string }>`
  transition: opacity 0.3s ${BezierDefault};
  background: var(--color-background);
  color: var(--color-foreground);
  height: 100%;
  overflow: hidden;

  ${(p) =>
    (p.$mode == "default" &&
      css`
        /* --color-foreground: var(--color-foreground); */
        --color-background: var(--color-charcoal100);
      `) ||
    (p.$mode == "pistachio" &&
      css`
        --color-foreground: var(--color-charcoal100);
        --color-background: var(--color-pistachio);
      `) ||
    (p.$mode == "earth" &&
      css`
        --color-foreground: var(--color-white);
        --color-background: var(--color-earth);
      `) ||
    (p.$mode == "mushroom" &&
      css`
        --color-foreground: var(--color-white);
        --color-background: var(--color-earth);
      `) ||
    (p.$mode == "forest" &&
      css`
        --color-foreground: var(--color-white);
        --color-background: var(--color-earth);
      `) ||
    (p.$mode == "green" &&
      css`
        --color-foreground: var(--color-white);
        --color-background: var(--color-earth);
      `)}
`;

const Inner = styled(UtilWrapper)`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  position: relative;

  ${MobileBreakpoint} {
    padding: 4px 16px 6px;
  }
`;

const Message = styled(TypesetCaptionL)<{ $mode: string }>`
  > * {
    display: flex;
    align-items: baseline;
    gap: 0 10px;
  }
`;

const Accept = styled.button`
  position: absolute;
  right:0;

  svg {
    ${TransitionDefault}
    line {
      stroke: var(--color-foreground);
    }
  }

  ${Hover} {
    svg {
      opacity: 0.5;
    }
  }}
`;

export default OverlayBanner;

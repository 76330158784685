
import ShopifyImage from './ShopifyImage'

interface P {
  media: any
  ratio?: string
  [key: string]: any
}
const ShopifyMedia: React.FC<P> = ({ 
  media,
  ratio,
  ...props
}: P) => {

  if(media.mediaContentType == 'IMAGE') return <ShopifyImage ratio={ratio} image={media.image} {...props} />

  return <></>
}

// const Container = styled.div``

export default ShopifyMedia
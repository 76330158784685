import { FC } from 'react'
import GlobalVideo from 'frontend/src/components/global/GlobalVideo'
import styled from 'styled-components'
import GlobalImage from '../global/GlobalImage'

interface SanityVideoProps {
  autoplay?: boolean
  video: any
  
  ratio: string
}

const SanityVideoPoster: FC<SanityVideoProps> = ({
  autoplay,
  video,
  ratio,
  ...props
}) => {
  return (


    <Wrapper>
      {/* <Video
        url={video.streamUrl}
        ratio={ratio}
        {...props}
        {...(autoplay && { autoPlay: true, loop: true, muted: true })}
      /> */}
      <GlobalImage 
        alt={"Video Thumbnail"}
        src={video.thumbnailUrl} 
        width={1120} 
        height={630} 
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
`

const Video = styled(GlobalVideo)`
  display: flex;
  grid-column: 1 / -1;
  grid-row: 1 / -1;

  video {
    object-fit: cover;
    object-position: center;
  }
`

export default SanityVideoPoster

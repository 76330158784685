import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import SanityButton from './../SanityButton';

interface Props {
  className?: string,
  link: string
  
}

const SanityContentButton: React.FC<Props> = ({ 
  link, 
  className,
  ...props 
}: Props) => {

    return (
      <Content className={className}>
        <SanityButton link={link} />
      </Content>
  )
}

const Content = styled.div``

const Caption = styled.p``

export default SanityContentButton
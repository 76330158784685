import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { submitNewsletter } from "library/services/klaviyo";

const COOKIE_NAME = "newsletter";

interface NewsletterProps {
  email: string;
}

export const useNewsletter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [submitErrors, setSubmitErrors] = useState("");
  const [successful, setSuccessful] = useState(false);
  const { register, handleSubmit } = useForm<NewsletterProps>();

  // Open if the cookie doesnt exist
  useEffect(() => {
    if (!!Cookies.get(COOKIE_NAME)) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, []);

  // Close function
  const close = useCallback(() => {
    setIsOpen(false);
    Cookies.set(COOKIE_NAME, "closed", { expires: 365 });
  }, [setIsOpen]);

  // Process form
  const onSubmit: SubmitHandler<NewsletterProps> = async (formData) => {
    try {
      await submitNewsletter({
        input: formData,
      });
      setSuccessful(true);
      setTimeout(close, 3000);
    } catch (e: any) {
      console.error(e);
      if (!(e instanceof Error)) return;
      setSubmitErrors(e?.message);
    }
  };

  return {
    isOpen,
    close,
    submitErrors,
    successful,
    register,
    handleSubmit,
    onSubmit,
  };
};

import styled from "styled-components";

import { useProduct } from "../../contexts/ProductProvider";
import usePurchase from "../../hooks/usePurchase";

interface P {
  [key: string]: any;
}
const CartUpsellProductBuy: React.FC<P> = ({ ...props }: P) => {
  const { isAdding, selectedVariant } = useProduct();
  const { addToCart } = usePurchase();

  const disabled = !selectedVariant?.availableForSale;

  return (
    <form onSubmit={addToCart} {...props}>
      <Button disabled={disabled}>
        {disabled ? "Sold Out" : isAdding ? "Adding to Cart" : "Add to Cart"}
      </Button>
    </form>
  );
};

const Button = styled.button``;

export default CartUpsellProductBuy;

import { useProducts } from "frontend/src/contexts/ProductsProvider";

import ProductCardXS from "./ProductCardXS";
import ProductCardM from "./ProductCardM";
import ProductCardL from "./ProductCardL";

import ProductCardSubmenu from "./ProductCardSubmenu";
import ProductCardOverview from "./ProductCardOverview";
import ProductProvider from "frontend/src/contexts/ProductProvider";
import ProductCardS from "./ProductCardSmall";
import { findShopifyProductByRef } from "library/utils/products";
import GlobalReveal from "src/components/global/GlobalReveal";

interface Props {
  layout: "XS" | "S" | "M" | "L" | "SUBMENU" | "OVERVIEW";
  product: any;
  index?: number;
  shopifyProduct?: any;
  className?: string;
  activeIndex?: number;
  setActiveIndex?: any;
}

const ProductCard: React.FC<Props> = ({
  shopifyProduct,
  product,
  layout,
  index,
  activeIndex,
  setActiveIndex,
  className,
}: Props) => {
  let fetchedProduct;

  // Find matching shopify product
  const { shopifyProducts } = useProducts();
  if (!shopifyProduct) {
    fetchedProduct = findShopifyProductByRef(
      shopifyProducts,
      product._id || product._ref,
    );
  }

  // Fail gracefully
  if (!shopifyProduct && !fetchedProduct) return <></>;

  return (
    <ProductProvider
      product={product}
      shopifyProduct={shopifyProduct || fetchedProduct}
    >
      {layout == "SUBMENU" && <ProductCardSubmenu className={className} />}
      {layout == "OVERVIEW" && (
        <ProductCardOverview
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          className={className}
          index={index || 0}
        />
      )}
      {layout == "L" && (
        <GlobalReveal>
          <ProductCardL className={className} />
        </GlobalReveal>
      )}
      {layout == "M" && (
        <GlobalReveal>
          <ProductCardM className={className} />
        </GlobalReveal>
      )}
      {layout == "S" && (
        <GlobalReveal>
          <ProductCardS className={className} />
        </GlobalReveal>
      )}
      {layout == "XS" && (
        <GlobalReveal>
          <ProductCardXS className={className} index={index || 0} />
        </GlobalReveal>
      )}
    </ProductProvider>
  );
};

export default ProductCard;

import styled, { css } from "styled-components";
import { TypeCaptionL } from "../../styles/mixins/text";
import { BezierDefault, Mobile } from "../../styles/mixins";
interface Props {
  children: React.ReactNode;
  className?: string;
  href?: string;
  disabled?: boolean;
  elementType?;
  onClick?: any;
  [key: string]: any;
}

const ButtonPrimary: React.FC<Props> = ({
  children,
  elementType,
  ...rest
}: Props) => {
  return (
    <Button
      as={elementType ? elementType : rest.href ? "a" : "button"}
      disabled={rest.disabled}
      {...rest}
    >
      {children}
    </Button>
  );
};

const Button = styled.a<{ disabled: boolean | undefined }>`
  ${TypeCaptionL};
  display: inline-flex;
  padding: 3px 16px;
  --color-foreground: var(--ButtonPrimary-color);
  background: var(--ButtonPrimary-background);
  border: 1px solid var(--ButtonPrimary-border);
  color: var(--ButtonPrimary-color);
  border-radius: 2px;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;

  transition: all 0.3s ${BezierDefault};

  :hover,
  :focus {
    --ButtonPrimary-background: var(--ButtonPrimary-hover-background);
    --ButtonPrimary-color: var(--ButtonPrimary-hover-color);
    --ButtonPrimary-border: var(--ButtonPrimary-hover-border);
  }

  ${(props) =>
    props.disabled &&
    css`
      --ButtonPrimary-background: var(--ButtonPrimary-disabled-background);
      --ButtonPrimary-color: var(--ButtonPrimary-disabled-color);
      --ButtonPrimary-border: var(--ButtonPrimary-disabled-border);
      pointer-events: none;
    `}

  ${Mobile} {
    padding: 4px 8px;
  }
`;

export default ButtonPrimary;

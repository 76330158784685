import styled from 'styled-components'
import { TypeBodyM } from '../../styles/mixins';
import SanityStockistRegion from './SanityStockistRegion';
// import {SanityFileAsset} from '@sanity/asset-utils';

interface Props {
  className?: string,
  title: string,
  regions: any,
  anchor: string,
}
const SanityStockistGroup: React.FC<Props> = ({ 
  title, 
  anchor,
  regions, 
  className 
}: Props) => {
  
    return (
      <Wrapper className={className} data-anchor={anchor}>
        <Title>{title}</Title>
        {regions.map((region) => (
          <SanityStockistRegion 
            region={region} 
            key={region._key} 
          />
        ))}
      </Wrapper>
  )
}

const Wrapper = styled.div``

const Title = styled.h3`
  ${TypeBodyM};
  text-transform: initial;
  margin-left: 0;
  margin-bottom: 1em;
`

export default SanityStockistGroup

import { getStyleFromProduct } from 'library/utils/shopify/getters'
import styled, { css } from 'styled-components'
import { Columns } from '../../styles/functions'
import { BezierDefault, Hover, TypeBodyM, TypeCaptionM } from '../../styles/mixins'
import ShopifyMedia from '../shopify/ShopifyMedia'
import ProductCardPrice from './card/ProductCardPrice'

interface P {
  index?: number
  medias: any[],
  activeDetail: number,
  shopifyProduct: any
  layout: "COLLECTION" | "LAYOUT1" | "LAYOUT2" | "LAYOUT3"
  onImageMouseOver?: (index: number) => () => any,
  onImageClick?: (index: number) => () => any,

}
const ProductOverviewImages: React.FC<P> = ({ 
  medias,
  layout,
  index,
  activeDetail,
  onImageMouseOver,
  onImageClick,
  shopifyProduct,
  ...props
}: P) => {
  const noop = () => true

  const getNumber = typeof(index) !== 'undefined' 
    ? (_index) => `${index+1}.${_index}` 
    : (_index) => `${_index.toString().padStart(2, '0')}`

  const {style, colour} = getStyleFromProduct(shopifyProduct);

  // const isInactive = layout == "COLLECTION" ? 

  return (
    <Container 
      $layout={layout}
      className="product-overview-image__container"
      {...props}
    >
      {medias.map((media, _index) => 
        <Button 
          className="product-overview-image"
          onMouseOver={
            onImageMouseOver ? onImageMouseOver(_index) : noop
          }
          onClick={onImageClick ? onImageClick(_index) : noop}
          key={`shopify_media_${index}_${_index}`}
        > 
          <Counter>{getNumber(_index+1)}</Counter>
          <MediaContainer
              className="product-overview-image__media-container"
            >
            <Media 
              $isInactive={activeDetail > -1 && _index != activeDetail} 
              media={media} 
              className="product-overview-image__media"
              priority={false}
            />
            {
              layout == "COLLECTION" && _index == 0 && <div>
                <MediaCaption className="product-overview-image__caption">
                  <Detail $caption="Style">
                    {style}
                  </Detail>
                  <Detail $caption="Colour">
                    {colour}
                  </Detail>
                  <ProductCardPrice
                    shopifyProduct={shopifyProduct}
                  />
                </MediaCaption>
              </div>
            }
          </MediaContainer>
        </Button>
      )}
    </Container>
  )
}

const Container = styled.div<{$layout}>`
  display: contents;

${props => props.$layout == "LAYOUT1" && css`
  .product-overview-image {
    &:nth-child(1) {width: ${Columns(3)}};
    &:nth-child(2) {width: ${Columns(6)}};
    &:nth-child(3) {width: ${Columns(3)}};
    &:nth-child(4) {width: ${Columns(3)}};
    &:nth-child(5) {width: ${Columns(4)}};
    &:nth-child(6) {width: ${Columns(5)}};
  }
`} 

${props => props.$layout == "LAYOUT2" && css`
  .product-overview-image {
    &:nth-child(1) {width: ${Columns(4)}};
    &:nth-child(2) {width: ${Columns(4)}};
    &:nth-child(3) {width: ${Columns(4)}};
    &:nth-child(4) {width: ${Columns(6)}};
    &:nth-child(5) {width: ${Columns(3)}};
    &:nth-child(6) {width: ${Columns(3)}};
  }
`} 

${props => props.$layout == "LAYOUT3" && css`
  .product-overview-image {
    &:nth-child(1) {width: ${Columns(3)}};
    &:nth-child(2) {width: ${Columns(6)}};
    &:nth-child(3) {width: ${Columns(3)}};
    &:nth-child(4) {width: ${Columns(3)}};
    &:nth-child(5) {width: ${Columns(4)}};
    &:nth-child(6) {width: ${Columns(5)}};
  }
`} 
`

const Button = styled.button`
  margin-bottom: auto;
`

const Counter = styled.div`
  ${TypeCaptionM};
  content: counter( counter , decimal-leading-zero) ' ';
  counter-increment: counter;
  position: absolute;
  /* top: calc(-1 * (1em + 8px)); */
  z-index: 10;
`

const MediaContainer = styled.div`
  position: relative;
  background: var(--color-charcoal100);
`

const Media = styled(ShopifyMedia)<{$isInactive}>`
  position: relative;
  margin-top: calc(1em + 4px);
  /* background: var(--color-charcoal100); */
  margin-bottom: auto;

  transition: opacity 0.3s ${BezierDefault};

  ${props => props.$isInactive && css`
    opacity: 0;
  `}
`

const MediaCaption = styled.div`
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  z-index: 1;
  text-align: left;
`

const Detail = styled.div<{$caption}>`
  ${TypeBodyM};
  display: grid;
  gap: 5px;
  grid-template-columns: 40px 1fr;
  margin-top: 4px;

  &:before {
    ${TypeCaptionM};
    ${props => css`
      content: "${props.$caption}";
    `}
  }
`


export default ProductOverviewImages
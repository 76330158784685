import { css } from "styled-components";
import { mapCSSResponsive } from "../../utils/styles"
import { 
  VarTypeHeadingXL,
  VarTypeHeadingL,
  VarTypeHeadingM,
  VarTypeHeadingS,
  VarTypeHeadingXS,
  VarTypeBodyL,
  VarTypeBodyM,
  VarTypeCaptionL,
  VarTypeCaptionM 
} from "../variables";

export const TypeHeadingXL = mapCSSResponsive(VarTypeHeadingXL); 
export const TypeHeadingL = mapCSSResponsive(VarTypeHeadingL); 
export const TypeHeadingM = mapCSSResponsive(VarTypeHeadingM); 
export const TypeHeadingS = mapCSSResponsive(VarTypeHeadingS); 
export const TypeHeadingXS = mapCSSResponsive(VarTypeHeadingXS); 
export const TypeBodyL = mapCSSResponsive(VarTypeBodyL); 
export const TypeBodyM = mapCSSResponsive(VarTypeBodyM); 
export const TypeCaptionL = mapCSSResponsive(VarTypeCaptionL); 
export const TypeCaptionM = mapCSSResponsive(VarTypeCaptionM); 

export const Indent = css`
  &:before {
    content: ' ';
    display: inline-flex;
    width: var(--indent);
  }
`
import {
  BaseCartLineEdge,
  CartLineFieldsFragment,
  ProductFieldsFragment,
} from "../../../services/shopify";
import { convertShopifyPathIdToNumber } from ".";

export interface transformPixelViewProductArguments {
  shopifyProduct: ProductFieldsFragment;
}

export function transformPixelViewProduct({
  shopifyProduct,
}: transformPixelViewProductArguments) {
  const productNumber = shopifyProduct?.id
    ? convertShopifyPathIdToNumber(shopifyProduct.id)
    : "";

  return {
    content_type: "product_group",
    content_ids: [productNumber],
    content_name: shopifyProduct.title,
    content_category: shopifyProduct.productType,
    value: parseFloat(shopifyProduct.priceRange.minVariantPrice.amount),
    currency: shopifyProduct.priceRange.minVariantPrice.currencyCode,
  };
}

export function transformPixelAddToCartProduct({
  shopifyProduct,
  shopifyVariant,
  quantity,
}: any) {
  const productNumber = shopifyProduct?.id
    ? convertShopifyPathIdToNumber(shopifyProduct.id)
    : "";
  return {
    content_type: "product",
    content_ids: [productNumber],
    content_name: shopifyProduct.title,
    value: parseFloat(shopifyVariant.price.amount) * quantity,
    currency: shopifyVariant.price.currencyCode,
    contents: [
      {
        id: shopifyVariant.sku,
        quantity,
      },
    ],
  };
}

export function transformPixelSearchProducts({
  productIds,
  currency,
  query,
}: any) {
  return {
    content_type: "product_group",
    content_ids: productIds,
    currency: currency,
    search_string: query,
  };
}

export function transformPixelCheckout({ cart }: any) {
  const lines = cart.lines.edges.map((v: BaseCartLineEdge) => v.node);

  return {
    content_ids: lines.map((line: CartLineFieldsFragment) => {
      const shopifyProduct = line.merchandise.product;

      return shopifyProduct?.id
        ? convertShopifyPathIdToNumber(shopifyProduct.id)
        : line.merchandise.sku;
    }),
    contents: lines.map((line: CartLineFieldsFragment) => {
      const shopifyProduct = line.merchandise.product;
      return {
        id: shopifyProduct?.id
          ? convertShopifyPathIdToNumber(shopifyProduct.id)
          : line.merchandise.sku,
        quantity: line.quantity,
      };
    }),
    currency: lines[0]?.merchandise.price.currencyCode || "NZD",
    num_items: lines.reduce(
      (c: number, v: CartLineFieldsFragment) => c + v.quantity,
      0
    ),
    value: cart.estimatedCost.totalAmount.amount,
  };
}

import Link from "next/link";
import { useInterface } from "src/contexts/InterfaceProvider";
import styled from "styled-components";
import { TypeCaptionL } from "../../styles/mixins";
import ShopifyImage from "../shopify/ShopifyImage";
import CartItemPrice from "./CartItemPrice";
import CartItemQuantity from "./CartItemQuantity";
import CartItemSpecs from "./CartItemSpecs";

interface P {
  item: any;
}
const CartItem: React.FC<P> = ({ item, ...props }: P) => {
  const { closeOverlay } = useInterface();
  const onClick = () => closeOverlay();

  return (
    <Container {...props}>
      <Link
        href={`/products/${item.merchandise.product.handle}`}
        onClick={onClick}
      >
        <ShopifyImage ratio="4x3" image={item.merchandise.image} />
      </Link>
      <Details>
        <CartItemSpecs />
        <DetailsFooter>
          <CartItemQuantity item={item} />
          <CartItemPrice item={item} />
        </DetailsFooter>
      </Details>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 160px 1fr;
  gap: 0 var(--gutter);
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-charcoal200);
`;
const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailsFooter = styled.div`
  ${TypeCaptionL};
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export default CartItem;

// import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import { Hover, TypeBodyM } from '../../../styles/mixins';
import SanityBlocks from './../SanityBlocks';


interface Props {
  className?: string,
  blocks: any,
}

const SanityContentFootnote: React.FC<Props> = ({ blocks, className }: Props) => {
    return (
      <Footnote 
        className={className}
        blocks={blocks} 
      />
  )
}


const Footnote = styled(SanityBlocks)`
  white-space: pre-wrap;

  > * {
    ${TypeBodyM}
  }

  * + * {
    margin-top: 1em;
  }

  .link-inline > * {
    opacity: 0.5;
    text-decoration: underline;
    
    ${Hover} {
      opacity: 1;
    }}
  }
`

// export const Styled = styled(SanityContentFootnote)

export default SanityContentFootnote
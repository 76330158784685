// import styled, { css } from 'styled-components'
import SanityLinkRender from './SanityLinkRender';

type Props = {
  depth?: number,
  links?: any,
  children?: React.ReactNode,
  className?: string,
}

const SanityLinks: React.FC<Props> = ({children, links, className, depth = 1}: Props) => {
  const linksClassName = `${className || ''} sanity-links sanity-links--depth-${depth}`

  return (
    <ul className={linksClassName}>
      {
        links ? links.map(
          (link) => <SanityLinkRender depth={depth} link={link} key={link._key} />
        ) : children
      }
    </ul>
  )
}

export default SanityLinks

import { useCustomer } from '../contexts/CustomerProvider'
import useFormAccount from './useFormAccount';

function useFormLogin() {
  const {customerSignIn} = useCustomer();
  
  return useFormAccount(async (input) => {
    await customerSignIn(input);
    return "Successful login"
  })
}

export default useFormLogin

import { useProduct } from "frontend/src/contexts/ProductProvider";
import { TypeCaptionL } from "frontend/src/styles/mixins";
import styled from "styled-components";

interface Props {
  setIsHovered: any;
  children: React.ReactNode;
  className?: string;
}
const ProductCardWrapper: React.FC<Props> = ({
  children,
  setIsHovered,
  className,
}: Props) => {
  const { shopifyProduct, product } = useProduct();
  const isAvailable = !!shopifyProduct.variants.edges.find(
    (v) => v.node.availableForSale,
  );

  const label = !isAvailable ? "Sold Out" : product.collectionLabel;

  return (
    <Container
      className={className}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      {label && <Label>{label}</Label>}
      {children}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const Label = styled.div`
  ${TypeCaptionL}
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 20;
`;

export default ProductCardWrapper;

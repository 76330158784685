import { formatDate } from 'library/services/sanity/helpers'
import styled, { css } from 'styled-components'
import { Breakpoint, Columns, MobileBreakpoint } from '../../styles/functions'
import { TypeBodyM, TypeHeadingS } from '../../styles/mixins'
import Link from '../link/LinkRender'

import SanityImage from "../sanity/SanityImage"

interface P {
  article: any
  index?: number
  [key: string]: any
}
const ArticleCardOverview: React.FC<P> = ({ 
  article,
  index = 0,
  ...props
}: P) => {

  const layout = ["CENTER", "HALF", "HALF"]
  const mode = layout[index % layout.length]

  const date = formatDate(article.publishedAt);
  
  return (
    <Wrapper $mode={mode} {...props}>
      <LinkWrap href={`/journal/${article.slug.current}`}>
        <Image image={article.image} alt={article.title} />
        <Details>
          <div>
            <time itemProp="datePublished" dateTime={article.publishedAt}>{date}</time>
            <div>Words by {article.author}</div>
          </div>

          <Title>
            {article.title}
          </Title>
        </Details>
      </LinkWrap>
    </Wrapper>
  )
}

const Wrapper = styled.div<{$mode: string}>`
  padding-bottom: var(--spacing-120);
  ${props => props.$mode == "CENTER" && css`
    ${Breakpoint('mobile', 'min')} {
      margin-left: ${Columns(6,0)};
      margin-right: ${Columns(6,0)};
    }
  `}

  ${props => props.$mode == "HALF" && css`
    ${Breakpoint('mobile', 'min')} {
      width: ${Columns(12)};
    }
  `}

  ${MobileBreakpoint} {
    width: 100%;
  }
`

const Image = styled(SanityImage)`
  mix-blend-mode: multiply;
  filter: saturate(0);
  transition: filter 0.2s ease;
  min-height: auto;
`

const LinkWrap = styled(Link)`
  &:hover ${Image} {
    filter: saturate(1);
  }
`

const Details = styled.div`
  ${TypeBodyM}
  margin-top: var(--spacing-16);
  display: grid;
  grid-template-columns: ${Columns(4)} 1fr;
  gap: 0 var(--gutter);
  ${MobileBreakpoint} {
    grid-template-columns: 1fr;
  }
`


const Title = styled.h3`
  ${TypeHeadingS};

  ${MobileBreakpoint} {
    margin-top: 8px;
  }
`

export default ArticleCardOverview
import styled from 'styled-components';
import { TypeCaptionL } from '../../styles/mixins';
import SanityBlocks from './SanityBlocks';
import SanityImage from './SanityImage';

interface Props {
  className?: string,
  image: any
  caption: any
  ratio: string
}

const SanityImageWithCaption: React.FC<Props> = ({ 
  image, 
  caption, 
  className,
  ratio,
  ...props 
}: Props) => {
  
    return (
      <div className={className}>
        <SanityImage alt={image.alt || ''} image={image} ratio={ratio} />
        {caption && <Caption blocks={caption} />}
      </div>
  )
}

const Caption = styled(SanityBlocks)`
  ${TypeCaptionL};
  margin-top: 8px;
  text-align: left;
  a {
    text-decoration: underline;
  }
`

export default SanityImageWithCaption
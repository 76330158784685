import { css } from 'styled-components'
import { VarBreakpoints } from './variables'

export function Breakpoint(
  name: 'mobile' | 'tablet' | 'desktop' ,
  direction: 'min' | 'max' = 'max'
) {
  const width = VarBreakpoints[name]

  return `
    @media screen and (${direction}-width: ${width})
  `
}

export function MobileBreakpoint() {
  return `
  ${Breakpoint('mobile', 'max')} 
  `
}

export function TabletBreakpoint(children: any) {
  return `
    ${Breakpoint('mobile', 'min')} {
      ${Breakpoint('tablet', 'max')} {
        ${children}
      }
    }
  `
}

export function DesktopBreakpoint() {
  return `
    ${Breakpoint('tablet', 'min')} 
  `
}

export function Columns(count: number, gutterOffset = -1) {
  return `calc(
    (
      var(--column) * ${count}
    )
    + (
      var(--gutter) * ${gutterOffset}
    )
  )`
}

export function AspectRatio(ratio: string) {
  if(ratio.indexOf('x') == -1) throw Error('Ratio must be in the format of eg 3x4');

  const [width, height] = ratio.split('x');

  return css`
    position: relative;

    &::before {
      content: '';
      float: left;
      padding-top: calc((${height}/${width}) * 100%);
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  `
}

// export function columns(count: number, isEdge = false) {
//   const gutter = `--${isEdge ? 'hgutter' : 'gutter'}`
//   return `calc(100% / var(--columns) * ${count} - var(${gutter}))`
// }

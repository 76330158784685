import styled, { css } from "styled-components";
import { TypeCaptionL } from "../../styles/mixins";
import SanityBlocks from "./SanityBlocks";
import SanityImage from "./SanityImage";
import SanityLivestream from "./SanityLivestream";
import SanityVideo from "./SanityVideo";
import useNearScreen from "../../hooks/useNearScreen";

interface P {
  media: any;
  ratio: string;
  autoplay?: boolean;
  caption?: any;
  className?: string;
  [key: string]: any;
}
const SanityMedia: React.FC<P> = ({
  media,
  ratio,
  autoplay,
  caption,
  className,
  ...props
}: P) => {
  const { ref, isNearScreen } = useNearScreen();

  return (
    <Wrapper className={className} $isNearScreen={isNearScreen} ref={ref}>
      {media.type == "image" && (
        <SanityImage
          alt={media?.alt || ""}
          ratio={ratio}
          image={media.image}
          {...props}
        />
      )}
      {media.type == "video" && (
        <SanityVideo
          ratio={ratio}
          video={media.video}
          autoplay={autoplay}
          {...props}
        />
      )}
      {media.type == "livestream" && (
        <SanityLivestream
          ratio={ratio}
          livestream={media.livestream}
          autoplay={autoplay}
          {...props}
        />
      )}

      {caption && <Caption blocks={caption} />}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $isNearScreen: boolean }>`
  margin: 0;
  display: flex;
  flex-direction: column;

  ${(p) =>
    !p.$isNearScreen &&
    css`
      /* visibility: hidden; */
    `}
`;

const Caption = styled(SanityBlocks)`
  ${TypeCaptionL};
  margin-top: 8px;
  text-align: left;
  a {
    text-decoration: underline;
  }
`;

export default SanityMedia;

import { useContext, createContext  } from 'react';

type State = any
interface SettingsProviderProps {
  settings: any,
  children: React.ReactNode
}

export const SettingsContext = createContext<State>({
  settings: false,
});

export function useSettings() {
  return useContext(SettingsContext);
}

const SettingsProvider: React.FC<SettingsProviderProps> = ({ 
  children, 
  settings, 
}: SettingsProviderProps) => {
  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  )
}

export default SettingsProvider
import styled, { css } from 'styled-components'
import { Columns } from '../../styles/functions'
import { TypeBodyM, TypeCaptionL } from '../../styles/mixins'
import SanityBlocks from '../sanity/SanityBlocks'
import SanityVideo from '../sanity/SanityVideo'
import SvgClose from '../svg/SvgClose'
import UtilWrapper from '../util/UtilWrapper'

interface P {
  video: any
  ratio: string
  caption: any[]
  setIsOverlayOpen: any
}
const OverlayVideo: React.FC<P> = ({ 
  video,
  ratio,
  caption,
  setIsOverlayOpen,
  ...props
}: P) => {

  return (
    <Wrapper {...props}>
      <Controls>
        <ControlsInner>
          <Close onClick={() => setIsOverlayOpen(false)}>
            Close&nbsp;
            <SvgClose />
          </Close>
        </ControlsInner>
      </Controls>
      <VideoWrapper>
        <VideoInner>
          <Video 
            video={video}
            ratio={ratio}
            controls
          />
          
          {caption && <Caption blocks={caption} />}
        </VideoInner>
      </VideoWrapper>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  position: fixed;
  left:0;right:0;
  top:0;height:100%;
  z-index: 9060;
  background: var(--color-background);
`

const Controls = styled.div`
  padding-top: 12px;
  ${TypeBodyM};
`

const ControlsInner = styled(UtilWrapper)`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 10;
`

const Close = styled.button`
  display: flex;
  align-items: baseline;
  svg {
    transform: translateY(1px);
  }
`

const VideoWrapper = styled.div`
  position: absolute;
  top: var(--spacing-16);
  bottom: var(--spacing-16);
  left:0;right: 0;
  display: flex;
`

const VideoInner = styled.div`
  display: flex;
  justify-content: space-around;
  width: ${Columns(22)};
  position: relative;
  margin: auto;
  flex-direction: column; 
  padding-left: var(--inset);
  padding-right: calc(var(--inset) + var(--scroll-inset));
`

const Video = styled(SanityVideo)`
  /* position: absolute;
  left:0;right:0;
  top:0;bottom:0;
  object-fit: contain; */
  object-fit: contain;
  max-height: calc(100 * var(--1vh) - 60px); 
`

const Caption = styled(SanityBlocks)`
  ${TypeCaptionL};
  margin-top: 8px;
`

export default OverlayVideo
import styled, { css } from 'styled-components'
import { usePrice } from '../../hooks/usePrice'
import { TypeCaptionL, TypeCaptionM } from '../../styles/mixins';

interface P {
  amount: number
  showCurrency?: boolean
  display?: "DEFAULT" | "CART"
  currencyCode: string
}

const PriceRender: React.FC<P> = ({ 
  amount,
  currencyCode,
  display = "DEFAULT",
  showCurrency = true,
  ...props
}: P) => {
  const {
    symbol,
    hasHundredths,
    hundredths,
    number,
  } = usePrice(amount);

  return (
    <Container {...props}>
      {symbol}
      {number} 
      {hasHundredths && <>
        .{hundredths}
      </>}
      &nbsp;
      {showCurrency && <Currency $display={display}>{currencyCode}</Currency>}
    </Container>
  )
}

const Container = styled.span`
  display: inline-flex;
`

const Currency = styled.span<{$display}>`
${props => props.$display == "DEFAULT" && css`
  ${TypeCaptionM}
  margin-bottom: auto;
`}
  ${props => props.$display == "CART" && css`
    ${TypeCaptionL}
    /* margin-bottom: auto; */
  `}
`

export default PriceRender
import { useContext, createContext  } from 'react';

type State = any
interface ArticleProviderProps {
  article: any,
  children: React.ReactNode
}

export const ArticleContext = createContext<State>({
  article: false,
});

export function useArticle() {
  return useContext(ArticleContext);
}

const ArticleProvider: React.FC<ArticleProviderProps> = ({ 
  children, 
  article, 
}: ArticleProviderProps) => {
  return (
    <ArticleContext.Provider value={article}>
      {children}
    </ArticleContext.Provider>
  )
}

export default ArticleProvider
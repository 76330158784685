// import { LayoutStyles } from 'frontend/src/styles/layout'
// import NextLink from 'next/link'
import styled from 'styled-components'
import SvgLogo from '../svg/SvgLogo'
import SanityMenu from '../sanity/SanityMenu'
import { Breakpoint, Columns } from '../../styles/functions'
import { useNavigation } from '../../contexts/NavigationProvider'
import UtilWrapper from '../util/UtilWrapper'
import { useHeaderSubmenu } from '../../contexts/HeaderSubmenuProvider'
import LinkRender from '../link/LinkRender'

type Props = {
  className?: string,
}

const HeaderDesktop: React.FC<Props> = ({className}: Props) => {
  const { submenu, hideSubmenu, setReference } = useHeaderSubmenu();
  const navigation = useNavigation();
  const header = navigation?.find((m) => m._id == "header-desktop");
  const { mainMenu, accountMenu, } = header;

  const onMouseLeave = function() {
    setReference(false);
  }

  return (
      <Header 
        className={className} 
        onMouseLeave={onMouseLeave}>
        <Wrapper>
          <LinkRender href="/">
            <SvgLogo />
          </LinkRender>
          <MainLinks layout="MENU" links={mainMenu}  />
          <AccountLinks layout="MENU" links={accountMenu}  />
        </Wrapper>
      </Header>
  )
}


const Wrapper = styled(UtilWrapper)`
  grid-template-columns: ${Columns(3)} ${Columns(14)} ${Columns(7)};
  gap: var(--gutter);
  display: grid;
`

const Header = styled.header`
  padding-top: 12px;
  padding-bottom: 12px;
  background: var(--color-background);
`

const MainLinks = styled(SanityMenu)`

`

const AccountLinks = styled(SanityMenu)`
  /* display: flex; */
  align-items: flex-end;
  text-align: right;

  > *:nth-child(1) {
    align-self: flex-start;
    margin-right: auto;
  }
`

export default HeaderDesktop

import styled from "styled-components";
import Image, { ImageProps } from "next/legacy/image";

interface GlobalImageProps extends ImageProps {
  src: string;
  width: number;
  height?: number;
  className?: string;
  loader?: any;
}

const GlobalImage: React.FC<GlobalImageProps> = ({
  src,
  width,
  height,
  alt,
  className = "",
  layout = "responsive",
  ...props
}: GlobalImageProps) => {
  return (
    <Container className={className}>
      <Image
        src={src.replace("cdn.sanity.io", "1of1studio-sanity-cdn.b-cdn.net")}
        alt={alt}
        width={width}
        height={height}
        layout={layout}
        loading="eager"
        {...props}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
`;

export default GlobalImage;


import styled from 'styled-components'
import ArticleCard from '../article/ArticleCard'
import HeaderSubmenuFeature from './HeaderSubmenuFeature'

type Props = {
  reference: any,
  className?: string,
}

const HeaderSubmenuReference: React.FC<Props> = ({
  reference, 
  className,
}: Props) => {
  return (
    <Wrapper className={className}>
    {
      reference?._type == "article" && (
        <ArticleCard
          layout="SUBMENU" 
          article={reference}
        />
      )
    }
    {
      !reference?._type && (
        <HeaderSubmenuFeature
          feature={reference}
        />
      )
    }
    </Wrapper>
  )
}


const Wrapper = styled.div`
`

export default HeaderSubmenuReference

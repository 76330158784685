function SvgClose() {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="9.21049" y1="0.724647" x2="0.725206" y2="9.20993" stroke="#414042"/>
<line x1="9.27474" y1="9.21098" x2="0.789461" y2="0.725694" stroke="#414042"/>
</svg>

  )
}

export default SvgClose;
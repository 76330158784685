
import styled, { css } from 'styled-components'
import { useProduct } from '../../contexts/ProductProvider'
import { Hover, TypeBodyM, TypeCaptionM } from '../../styles/mixins'
import GlobalSpecs from '../global/GlobalSpecs'
import Link from '../link/LinkRender'

interface P {
  withSku: boolean
  withStyle: boolean
  withSpecs: boolean
  withHandle: boolean
  withColour: boolean
  withColours: boolean
}

const ProductSpecs: React.FC<P> = ({ 
  ...props
}: P) => {
  const {
    number,
    style,
    specs,
    colour,
    colours,
  } = useProduct();


  return (
    <Specs 
      number={number || '0000-0001'}
      styleName={style}
      specs={specs}
      colour={colour}
      colours={colours}
      {...props}
    />
  )
}

const Specs = styled(GlobalSpecs)`
`

export default ProductSpecs
// import styled from 'styled-components'
import SanityLinks from "./SanityLinks";
import SanityLinkAction from "./SanityLinkAction";
import SanityLinkUrl from "./SanityLinkUrl";
import SanityLinkEmail from "./SanityLinkEmail";
import styled from "styled-components";
import { TypeCaptionM, UnstyledLinkInline } from "../../styles/mixins";
import SanityLinkScroll from "./SanityLinkScroll";
import classnames from "classnames";
import { memo } from "react";
import SanityLinkPhone from "./SanityLinkPhone";

type Props = {
  link: any;
  depth?: number;
  elementType?: string;
  className?: string;
  children?: React.ReactNode;
};

const SanityLink: React.FC<Props> = ({
  className,
  link,
  children,
  depth = 1,
}: Props) => {
  const linkClassName = classnames(
    className,
    "sanity-link",
    `sanity-link--depth-${depth}`,
    link.links && "sanity-link--has-children",
  );

  let LinkRender = <></>;

  if (link.action) {
    LinkRender = (
      <SanityLinkAction
        className="sanity-link__link sanity-link__link--action "
        link={link}
      >
        {children}
      </SanityLinkAction>
    );
  } else if (link.email) {
    LinkRender = (
      <SanityLinkEmail
        className="sanity-link__link sanity-link__link--email "
        link={link}
      >
        {children}
      </SanityLinkEmail>
    );
  } else if (link.phone) {
    LinkRender = (
      <SanityLinkPhone
        className="sanity-link__link sanity-link__link--phone "
        link={link}
      >
        {children}
      </SanityLinkPhone>
    );
  } else if (link.destination) {
    LinkRender = (
      <SanityLinkScroll
        className="sanity-link__link sanity-link__link--scroll "
        link={link}
      >
        {children}
      </SanityLinkScroll>
    );
  } else if (link.href) {
    LinkRender = (
      <SanityLinkUrl
        className="sanity-link__link sanity-link__link--url "
        link={link}
      >
        {children}
      </SanityLinkUrl>
    );
  }

  return (
    <Link className={linkClassName} $colour={link.colour}>
      {LinkRender}

      {link.badge && <Badge>{link.badge}</Badge>}

      {link.links && (
        <>
          {link.link && link?.link?.toggle !== false ? (
            <LinkHeading
              elementType="span"
              link={link.link}
              className="sanity-link__sublink sanity-link__sublink--link"
              depth={depth + 1}
            />
          ) : (
            link.title && (
              <LinkTitle className="sanity-link__sublink sanity-link__sublink--title">
                {link.title || "&nbsp;"}
              </LinkTitle>
            )
          )}

          <SanityLinks depth={depth + 1} links={link.links} />
        </>
      )}
    </Link>
  );
};

const Link = styled.div<{ $colour?: string }>`
  color: var(--color-foreground);
  position: relative;
  z-index: 1;

  ${(p) =>
    p.$colour &&
    `
      --color-foreground: var(--color-${p.$colour});
    `}
`;

const LinkTitle = styled.h2`
  ${UnstyledLinkInline}
  pointer-events: none;
`;

const LinkHeading = styled(SanityLink)`
  ${UnstyledLinkInline}
`;

const Badge = styled.span`
  ${TypeCaptionM};
  display: inline-flex;
  color: var(--color-green);
  position: absolute;
  padding-left: 4px;
  padding-top: 3px;
  pointer-events: none;
`;

export default memo(SanityLink);

import styled, { css } from 'styled-components'
import { ReactNode } from 'react';
import { BezierDefault, BezierSlide, Hover,  } from '../../styles/mixins';

interface ButtonInlineProps {
  className?: string,
  disabled?: boolean,
  children?: ReactNode,
  onClick?: () => void,
}

const ButtonInline: React.FC<ButtonInlineProps> = ({ 
  className, 
  children, 
  disabled, 
  onClick, 
  ...props
}: ButtonInlineProps) => {
    return (
      <Wrapper className={className + ' button-inline'} {...props}>
        <Button 
          as="button" 
          onClick={onClick} 
          disabled={!!disabled}
        >
          {children}
        </Button>
      </Wrapper>
  )
}



const Wrapper = styled.span`
  display: inline-flex;
  vertical-align: baseline;
`

const Button = styled.button<{disabled: boolean | undefined}>`
  color: var(--ButtonInline-color);
  display: inline-flex;
  text-align: left;
  transition: all 0.3s ${BezierDefault};

  :disabled {
    color: var(--ButtonInline-disabled);
    pointer-events: none;
  }

  :focus,
  ${Hover} {
    color: var(--ButtonInline-hover);
  }
`

export default ButtonInline

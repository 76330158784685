import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import { TypeBodyM } from '../../../styles/mixins';
import SanityAnnotationLink from './../SanityAnnotationLink';

interface Props {
  children: React.ReactNode
  link: any
  
}

const SanityContentLink: React.FC<Props> = ({ 
  link, 
  children, 
  ...props 
}: Props) => {
    return (
      <SanityAnnotationLink 
        link={link} 
        {...props}
      >
        {children}
      </SanityAnnotationLink>
  )
}

// const Footnote = styled(BlockContent)`
//   white-space: pre-wrap;

//   > * {
//     ${TypeBodyM}
//   }

//   * + * {
//     margin-top: 1em;
//   }
// `

export default SanityContentLink
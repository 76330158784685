export const VarBreakpoints: StyleVariables = {
  mobile: "700px",
  tablet: "980px",
  desktop: "9999px",
};

export const VarColours: StyleVariables = {
  charcoal: "#414042",
  charcoal700: "#575659",
  charcoal600: "#757376",
  charcoal500: "#8D8D8D",
  charcoal400: "#B1B0AE",
  charcoal300: "#D1CFCE",
  charcoal200: "#E9E7E4",
  charcoal100: "#F5F4F3",
  white: "#FBFBFB",
  grey: "#8a8a8a",
  earth: "#583D34",
  mushroom: "#8F8577",
  forest: "#4E604C",
  green: "#70B369",
  red: "#ea3323",
  pistachio: "#F3F1CB",
  error: "#CF3B3B",
  foreground: "#414042",
  background: "#FBFBFB",
  background95: "#FBFBFBF2",
};

export const VarLayout: StyleResponsive = {
  desktop: {
    "--1vh": "1vh",
    "--inset": "16px",
    "--gutter": "16px",
    "--hgutter": "8px",
    "--columns": "24",
    "--scroll-inset": "4px",
    "--container": "calc(100vw - (var(--inset) * 2) - var(--scroll-inset))",
    "--column": `calc(
        (
          1 / var(--columns)
        ) 
        * (
          100vw 
          - (var(--inset) * 2)
          - var(--scroll-inset) 
        - (
          (var(--columns) - 1) 
          * var(--gutter))
        ) 
        + var(--gutter)
    )
    `,
    "--indent": "calc(var(--column) * 2 )",
    "--header-height": "80px",
    "--ButtonPrimary-background": "var(--color-charcoal)",
    "--ButtonPrimary-border": "var(--color-charcoal)",
    "--ButtonPrimary-color": "var(--color-white)",
    "--ButtonPrimary-hover-background": "transparent",
    "--ButtonPrimary-hover-border": "var(--color-charcoal)",
    "--ButtonPrimary-hover-color": "var(--color-charcoal)",
    "--ButtonPrimary-disabled-background": "var(--color-charcoal300)",
    "--ButtonPrimary-disabled-border": "var(--color-charcoal300)",
    "--ButtonPrimary-disabled-color": "var(--color-white)",
    "--ButtonInline-color": "var(--color-foreground)",
    "--ButtonInline-hover": "var(--color-charcoal400)",
    "--ButtonInline-disabled": "var(--color-charcoal400)",
    "--ButtonUnderline-color": "var(--color-charcoal600)",
    "--ButtonUnderline-hover-color": "var(--color-charcoal400)",

    "--spacing-page-top": "calc(var(--header-height) + var(--spacing-24))",
    "--spacing-4": "4px",
    "--spacing-8": "8px",
    "--spacing-16": "16px",
    "--spacing-24": "24px",
    "--spacing-32": "32px",
    "--spacing-40": "40px",
    "--spacing-64": "64px",
    "--spacing-80": "80px",
    "--spacing-120": "120px",
    "--spacing-200": "200px",
    "--spacing-300": "300px",
  },
  mobile: {
    "--inset": "16px",
    "--gutter": "8px",
    "--hgutter": "4px",
    "--columns": "12",
    "--scroll-inset": "0px",
    "--indent": "calc(var(--column) * 1 )",

    "--spacing-16": "8px",
    "--spacing-24": "16px",
    "--spacing-32": "24px",
    "--spacing-40": "32px",
    "--spacing-64": "40px",
    "--spacing-80": "64px",
    "--spacing-120": "80px",
    "--spacing-200": "120px",
    "--spacing-300": "200px",
  },
};

export const VarTypeHeadingXL: StyleResponsive = {
  desktop: {
    "font-size": "104px",
    "line-height": "1em",
    "font-family": "rhymes",
    "letter-spacing": "-0.04em",
    "font-weight": "300",
  },
  tablet: {
    "font-size": "86px",
    "line-height": "86px",
  },
  mobile: {
    "font-size": "48px",
    "line-height": "52px",
  },
};

export const VarTypeHeadingL: StyleResponsive = {
  desktop: {
    "font-size": "72px",
    "line-height": "76px",
    "font-family": "rhymes",
    "letter-spacing": "-0.03em",
    "font-weight": "300",
  },
  tablet: {
    "font-size": "68px",
    "line-height": "72px",
  },
  mobile: {
    "font-size": "44px",
    "line-height": "48px",
  },
};

export const VarTypeHeadingM: StyleResponsive = {
  desktop: {
    "font-size": "46px",
    "line-height": "48px",
    "font-family": "rhymes",
    "letter-spacing": "-0.03em",
    "font-weight": "300",
  },
  tablet: {
    "font-size": "40px",
    "line-height": "44px",
  },
  mobile: {
    "font-size": "30px",
    "line-height": "34px",
  },
};

export const VarTypeHeadingS: StyleResponsive = {
  desktop: {
    "font-size": "36px",
    "line-height": "38px",
    "font-family": "rhymes",
    "letter-spacing": "-0.02em",
    "font-weight": "300",
  },
  tablet: {
    "font-size": "32px",
    "line-height": "36px",
  },
  mobile: {
    "font-size": "24px",
    "line-height": "28px",
  },
};

export const VarTypeHeadingXS: StyleResponsive = {
  desktop: {
    "font-family": "rhymes",
    "font-weight": "300",
    "font-size": "24px",
    "line-height": "28px",
    "letter-spacing": "-0.01em",
  },
  tablet: {
    "font-size": "22px",
    "line-height": "24px",
  },
  mobile: {
    "font-size": "18px",
    "line-height": "22px",
  },
};

export const VarTypeBodyL: StyleResponsive = {
  desktop: {
    "font-family": "rhymes",
    "font-weight": "300",
    "font-size": "16px",
    "line-height": "20px",
    "letter-spacing": "-0.00em",
  },
  tablet: {
    "font-size": "16px",
    "line-height": "20px",
  },
  mobile: {
    "font-size": "14px",
    "line-height": "18px",
  },
};

export const VarTypeBodyM: StyleResponsive = {
  desktop: {
    "font-family": "akzidenz-grotesk, helvetica, arial, sans",
    "font-weight": "400",
    "font-size": "14px",
    "line-height": "16px",
    "letter-spacing": "-0.00em",
  },
  tablet: {
    "font-size": "14px",
    "line-height": "16px",
  },
  mobile: {
    "font-size": "13px",
    "line-height": "16px",
  },
};

export const VarTypeCaptionL: StyleResponsive = {
  desktop: {
    "font-family": "akzidenz-grotesk, helvetica, arial, sans",
    "font-weight": "400",
    "font-size": "12px",
    "line-height": "16px",
    "letter-spacing": "-0.00em",
  },
  tablet: {
    "font-size": "12px",
    "line-height": "16px",
  },
  mobile: {
    "font-size": "10px",
    "line-height": "14px",
  },
};

export const VarTypeCaptionM: StyleResponsive = {
  desktop: {
    "font-family": "akzidenz-grotesk, helvetica, arial, sans",
    "font-weight": "400",
    "font-size": "10px",
    "line-height": "12px",
    "letter-spacing": "-0.00em",
  },
  tablet: {
    "font-size": "10px",
    "line-height": "12px",
  },
  mobile: {
    "font-size": "8px",
    "line-height": "12px",
  },
};

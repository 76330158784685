import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Breakpoint, Columns, MobileBreakpoint } from '../../styles/functions'
import { BezierDefault, TypeBodyM } from '../../styles/mixins'
import SvgClose from '../svg/SvgClose'
import UtilWrapper from '../util/UtilWrapper'

const getNextIndex = (index, max) => {
  return index + 1 >= max ? 0 : index + 1;
}
const getPrevIndex = (index, max) => {
  return index - 1 < 0 ? max : index - 1;
}

interface P {
  slides: any[]
  thumbnails: []
  initialIndex: number
  setIsOverlayOpen: any
}
const OverlayGallery: React.FC<P> = ({ 
  slides,
  thumbnails,
  initialIndex,
  setIsOverlayOpen,
  ...props
}: P) => {

  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [nextIndex, setNextIndex] = useState(getNextIndex(initialIndex, slides.length));
  const [lastIndex, setLastIndex] = useState(getPrevIndex(initialIndex, slides.length));

  // Set based on index
  useEffect(() => {
    const nextIndex = getNextIndex(currentIndex, slides.length)
    const lastIndex = getPrevIndex(currentIndex, slides.length);
    setNextIndex(nextIndex);
    setLastIndex(lastIndex);
  }, [currentIndex, setNextIndex, setLastIndex]);

  const onClick = () => {
    const nextIndex = getNextIndex(currentIndex, slides.length);
    setCurrentIndex(nextIndex);
  }

  return (
    <Wrapper {...props}>
      <Controls>
        <ControlsInner>
          <Counter>
            {currentIndex+1}/{slides.length}
          </Counter>
          <Close onClick={() => setIsOverlayOpen(false)}>
            Close&nbsp;
            <SvgClose />
          </Close>
        </ControlsInner>
      </Controls>
      <Thumbnails>
        <ThumbnailsInner>
          <Thumbnail onClick={onClick}>
            {nextIndex+1}/{slides.length}
            {thumbnails[nextIndex]}
          </Thumbnail>
        </ThumbnailsInner>
      </Thumbnails>
      <Slideshow>
        <SlideshowInner>
          <Slides>
            { 
              slides.map((image, index) => 
                <Slide 
                  $isCurrent={index == currentIndex} 
                  $isLast={index == lastIndex}
                  key={`OverlayGallery__Slide--${index}`}
                  onClick={onClick}
                >
                  {slides[index]}
                </Slide>
            )}
          </Slides>
        </SlideshowInner>
      </Slideshow>
      <Background onClick={() => setIsOverlayOpen(false)} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  left:0;right:0;
  top:0;height:100%;
  z-index: 9060;
  ${TypeBodyM};
`

const Background = styled.div`
  position: absolute;
  left:0;right:0;
  top:0;bottom:0;
  cursor: pointer;
  background: var(--color-background95);
`

const Controls = styled.div`
  padding-top: 12px;
`

const ControlsInner = styled(UtilWrapper)`
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
`

const Counter = styled.div``

const Close = styled.button`
  display: flex;
  align-items: baseline;
  svg {
    transform: translateY(1px);
  }
`

const Slideshow = styled.div`
  position: absolute;
  top: 16px;
  bottom: 16px;
  left:0;right: 0;
`

const SlideshowInner = styled(UtilWrapper)`
  display: flex;
  justify-content: space-around;
  height: 100%;
`

const Slides = styled.div`
  width: ${Columns(12)};
  position: relative;

  ${MobileBreakpoint} {
    width: ${Columns(12)};
    margin-bottom: 200px;
    margin-top: 100px;
  }
`

const Slide = styled.button<{$isCurrent, $isLast}>`
  opacity: 0;
  transition: opacity 0.3s ${BezierDefault};;

  position: absolute;
  left:0;right:0;
  top:0;bottom:0;
  /* object-fit: contain; */

${props => props.$isCurrent && css`
  z-index: 10;
  opacity: 1;
`}

${props => props.$isLast && css`
  z-index: 5;
  opacity: 0;
`}
`

const Thumbnails = styled.div`
  position: absolute;
  bottom: 16px;
  left:0;right:0;
  z-index: 10;
`

const ThumbnailsInner = styled(UtilWrapper)`
  display: flex;
  justify-content: flex-end;
`

const Thumbnail = styled.button`
  width: 170px;
  text-align: left;
`

export default OverlayGallery

import styled from 'styled-components'
import { TypesetCaptionL } from '../typeset/Typeset'
import { useSettings } from '../../contexts/SettingsProvider'
import SanityContent from '../sanity/content/SanityContent'
import OverlaySlideWrap from './OverlaySlideWrap'
import { TypeBodyM } from '../../styles/mixins'

interface P {
  isActive: boolean
}

const OverlayShipping: React.FC<P> = ({ 
  isActive,
  ...props
}: P) => {
  const settings = useSettings();

  return (
    <Container isActive={isActive} title={settings.overlayShipping.title}>
      <Message>
        <Content layout="OVERLAY" blocks={settings.overlayShipping.content} />
      </Message>
    </Container>
  )
}

const Container = styled(OverlaySlideWrap)`
`

const Message = styled(TypesetCaptionL)`
`

const Content = styled(SanityContent)`
  ${TypeBodyM};
  padding-bottom: var(--spacing-16);
  border-bottom: 1px solid var(--color-charcoal200);
`

export default OverlayShipping

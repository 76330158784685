import sanityClient from "@sanity/client";

const config = {
  withCredentials: false,
  useCdn: true,
  apiVersion: process.env.NEXT_PUBLIC_SANITY_API_VERSION,
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
};

export const Client = sanityClient(config);

export const previewClient = sanityClient({
  ...config,
  useCdn: false,
  token: process.env.SANITY_API_TOKEN,
  withCredentials: true,
});

export const getClient = (isPreview = false) =>
  isPreview ? previewClient : Client;

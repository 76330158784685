import { useProduct } from "frontend/src/contexts/ProductProvider";
import styled from "styled-components";
import { TypeCaptionM } from "../../../styles/mixins";
import ProductCardHeading from "./ProductCardHeading";
import ProductCardPrice from "./ProductCardPrice";
import Link from "../../link/LinkRender";
import ProductCardMedia from "./ProductCardMedia";
import { useState } from "react";

interface Props {
  index: number;
  className?: string;
}
const ProductCardXS: React.FC<Props> = ({ index, className }: Props) => {
  const { shopifyProduct, product } = useProduct();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Container
      className={className}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <Index>[{index + 1}]</Index>

      <Link href={`/products/${shopifyProduct.handle}`}>
        <ProductCardMedia
          isHovered={isHovered}
          product={product}
          shopifyProduct={shopifyProduct}
        />

        <ProductCardHeading product={product} shopifyProduct={shopifyProduct} />

        <ProductCardPrice product={product} shopifyProduct={shopifyProduct} />
      </Link>
    </Container>
  );
};

const Container = styled.div``;

const Index = styled.div`
  ${TypeCaptionM}
  text-align: center;
  margin-bottom: 8px;
`;

export default ProductCardXS;

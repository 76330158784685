"use client";

import { useEffect, useState, useRef } from "react";
import { isIntersectionObserverRootSupported } from "src/utils/browserTest";

function useNearScreen() {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isNearScreen, setIsNearScreen] = useState(false);

  const rootMargin =
    typeof window == "undefined" ? "0px" : `${window?.innerHeight / 2}px`;

  const checkVisibility = () => {
    if (!ref.current) {
      return;
    }

    const rect = ref.current.getBoundingClientRect();
    const offset = window.innerHeight / 2; // Same as rootMargin in IntersectionObserver

    const isVisible =
      rect.top >= -offset &&
      rect.left >= -offset &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) +
          offset &&
      rect.right <=
        (window.innerWidth || document.documentElement.clientWidth) + offset;

    setIsNearScreen(isVisible);
  };

  useEffect(() => {
    const supported = isIntersectionObserverRootSupported();
    if (supported == true) {
      // Use Intersection Observer
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsNearScreen(entry.isIntersecting);
        },
        {
          root: null, // Relative to the viewport
          rootMargin: rootMargin, // Half the screen height
          threshold: 0.000001, // Call the callback whenever any part of the element comes into the viewport
        },
      );

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    } else {
      // Use the fallback method for browsers that do not support Intersection Observer
      window.addEventListener("scroll", checkVisibility);
      window.addEventListener("resize", checkVisibility);
      checkVisibility(); // Initial check
      return () => {
        window.removeEventListener("scroll", checkVisibility);
        window.removeEventListener("resize", checkVisibility);
      };
    }
  }, []);

  return { ref, isNearScreen };
}

export default useNearScreen;

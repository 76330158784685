import { submitNewsletter } from "library/services/klaviyo";
import { useCustomer } from "../contexts/CustomerProvider";
import useFormAccount from "./useFormAccount";

function useFormRegister() {
  const { customerRegister } = useCustomer();

  return useFormAccount(async (input) => {
    await customerRegister(input);

    // Subscribe to Klaviyo
    if (input.acceptsMarketing) {
      await submitNewsletter({
        input,
      });
    }

    return "Successfully registered! You can log in now";
  });
}

export default useFormRegister;

import { cp } from "fs";
import { useEffect, useState } from "react";
import { useWindowSize } from "react-use";
import { VarBreakpoints } from "../styles/variables";

function useBreakpoints() {
  const { width } = useWindowSize();

  const breakpoints = Object.keys(VarBreakpoints)
    .reduce((coll: any, key) => {
      const pixels = parseFloat(VarBreakpoints[key].replace("px", ""));

      coll = [[pixels, key], ...coll];

      return coll;
    }, [])
    .sort((a, b) => a[0] - b[0])
    .reverse(); // Lowest to highest

  const getBreakpoint = () => {
    const breakpoint = breakpoints.reduce((coll: boolean | string, value) => {
      const [pixels, breakpoint] = value;
      if (!coll) {
        coll = breakpoint;
      } else if (width < pixels) {
        coll = breakpoint;
      }
      return coll;
    }, false);

    return breakpoint;
  };

  // Todo: use UA sniffing to guess initial breakpoint
  const [breakpoint, setBreakpoint] = useState(breakpoints[0][1]);

  useEffect(() => setBreakpoint(getBreakpoint()), [width]);
  // useEffect(() => setBreakpoint(getBreakpoint()), [])

  // console.log(breakpoint);

  return {
    breakpoint,
  };
}

export default useBreakpoints;

import styled from 'styled-components'
import ArticleCardSubmenu from './ArticleCardSubmenu'

interface P {
  article: any
  [key: string]: any
}
const ArticleCardRelated: React.FC<P> = ({ 
  article,
  ...props
}: P) => {
  return (
    <Card article={article} {...props} />
  )
}

const Card = styled(ArticleCardSubmenu)`
  margin-top: -1px;
  padding-top: var(--spacing-16);
  padding-bottom: var(--spacing-16);
  border-top: 1px solid var(--color-charcoal200);
  border-bottom: 1px solid var(--color-charcoal200);
`


export default ArticleCardRelated
import { useEffect } from "react";
import { useRouter } from "next/router";
import { convertShopifyGidToNumber } from "library/utils/shopify/transform";
import {
  transformPixelAddToCartProduct,
  transformPixelCheckout,
  transformPixelSearchProducts,
  transformPixelViewProduct,
  transformPixelViewProductArguments,
} from "library/utils/shopify/transform/pixel";
import { isServer } from "../utils/env";
import { useCart } from "../contexts/CartProvider";
import { capiRequestEvent } from "../pages/api/capi";
import Axios from "axios";

const PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL;

export async function capiEvent(input: capiRequestEvent) {
  try {
    const response = await Axios.post("/api/capi", input);
    return response;
  } catch (e: any) {
    console.error("Error posting to capi", e);
    // throw new Error(`CAPI Error: ${JSON.stringify(input, null, 2)}, ${JSON.stringify(e, null, 2)}`);
    return true;
  }
}

const event = async (name, data = {}, cart) => {
  if (isServer() || !window.fbq) return;

  // Get cart ID
  const cartId = convertShopifyGidToNumber(cart?.id);
  if (!cartId) {
    console.log("Cart ID not defined");
    return;
  }

  // Server event
  const capiRequest: capiRequestEvent = {
    name,
    eventId: cartId,
    userData: {
      email: cart?.email || false,
    },
    customData: data,
    url: `${window.location}`,
  };

  capiEvent(capiRequest);

  // Browser event
  window.fbq("track", name, data, { eventID: cartId });
};

export function usePixel() {
  if (!PIXEL_ID) return;

  const router = useRouter();

  const { cart } = useCart();

  useEffect(() => {
    if (!cart) return;
    event("PageView", {}, cart);

    const handleRouteChange = () => {
      event("PageView", {}, cart);
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [cart, router.events]);
}

// Functionality for tracking events
export const pixelViewProduct = (
  cart,
  context: transformPixelViewProductArguments,
) => {
  const data = transformPixelViewProduct(context);
  event("ViewContent", data, cart);
};

export const pixelAddToCart = (cart, context) => {
  const data = transformPixelAddToCartProduct(context);
  event("AddToCart", data, cart);
};

export const pixelSearch = (cart, context) => {
  const data = transformPixelSearchProducts(context);
  event("Search", data, cart);
};

export const pixelCheckout = (cart, context) => {
  const data = transformPixelCheckout(context);
  event("InitiateCheckout", data, cart);
};

export const pixelRegistration = (cart, { name, currency }) => {
  const data = {
    content_name: name,
    currency,
    status: true,
  };
  event("CompleteRegistration", data, cart);
};


import styled, { css } from 'styled-components'
import { useCart } from '../../contexts/CartProvider'
import { TypeBodyM, TypeCaptionM } from '../../styles/mixins'
import ButtonCheckbox from '../button/ButtonCheckbox'
import { useRouter } from 'next/router'
import ButtonPrimary from '../button/ButtonPrimary'


const formatCountryName = (name) => {
  return name?.replace('United States', 'Rest of World')
}
interface FormLocationProps {
  isPopup?: boolean
}

const FormDetect: React.FC<FormLocationProps> = ({ 
  isPopup = false,
  ...props
}: FormLocationProps) => {
  const router = useRouter();
  const localizationCodes = router.locales?.map((v) => v.toUpperCase()) || [];
  const {country, availableCountries, dispatch: cartDispatch} = useCart();

  const displayCountries = availableCountries.filter((v) => localizationCodes.includes(v.isoCode))

  const closeDetect = () => cartDispatch({
    type: 'SET_DETECT_OVERLAY_ACTIVE',
    value: false,
  })

  const onClick = (code) => {
    // Update cart
    cartDispatch({
      type: "SET_COUNTRY_CODE",
      value: code,
    });

    // Change locale
    const {pathname, asPath, query} = router;
    router.push({pathname, query}, asPath, {locale: code.toLowerCase()});
    // router.replace(router.asPath);

    if(isPopup) {
      cartDispatch({
        type: 'SET_DETECT_OVERLAY_ACTIVE',
        value: false,
      });
    }
    
  }

  return (
    <Container>
      {/* <Message>
        You are currently shipping to {formatCountryName(country.name)} and 
        your order will be billed in {country.currency.isoCode}
      </Message> */}

      <Select>
        {/* <SelectHeading>
          Change Region / Currency
        </SelectHeading> */}
        {
          displayCountries
          .map((displayCountry, index) => 
            <Checkbox  
              onClick={() => onClick(displayCountry.isoCode)}
              isActive={country.isoCode == displayCountry.isoCode}
              key={`currency_${index}`}
            >
              {formatCountryName(displayCountry.name)} 
              &nbsp;/&nbsp;
              {displayCountry.currency.isoCode} 
              &nbsp;
              {displayCountry.currency.symbol}
            </Checkbox>
          )
        }
      </Select>


      <Button onClick={closeDetect}>Confirm</Button>
    </Container>
  )
}

const Container = styled.div`
  ${TypeBodyM}
`

// const Message = styled.div`
// `

const Select = styled.div`
  /* padding-top: var(--spacing-24); */
`

// const SelectHeading = styled.h3`
//   ${TypeCaptionM};
//   padding-bottom: 8px;
//   border-bottom: 1px solid var(--color-charcoal200);
// `

const Checkbox = styled(ButtonCheckbox)`
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-charcoal200);
  width: 100%;
`

const Button = styled(ButtonPrimary)`
  margin-top: var(--spacing-24);
`

export default FormDetect

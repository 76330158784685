import { FC } from "react";
import styled, { css } from "styled-components";
import { Breakpoint, Columns } from "../../styles/functions";

interface SanityLivestreamProps {
  autoplay?: boolean;
  livestream: any;
  ratio: string;
  [key: string]: any;
}

const SanityLivestream: FC<SanityLivestreamProps> = ({
  autoplay,
  livestream,
  ratio,
  ...props
}) => {
  return (
    <Wrapper $hasChat={!!livestream.chatUrl}>
      <StreamWrap>
        <Stream
          frameBorder="0"
          src={livestream.url}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          {...props}
        />
      </StreamWrap>

      {livestream.chatUrl && <Chat src={livestream.chatUrl} {...props} />}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $hasChat: boolean }>`
  ${Breakpoint("mobile", "min")} {
    display: grid;
    ${(p) =>
      p.$hasChat
        ? css`
            grid-template-columns: ${Columns(16)} 1fr;
          `
        : css`
            grid-template-columns: 1fr;
          `}
    width: 100%;
  }

  ${Breakpoint("mobile", "max")} {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

const StreamWrap = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

const Stream = styled.iframe`
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
`;

const Chat = styled.iframe`
  display: flex;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;

  ${Breakpoint("mobile", "max")} {
    display: none;
  }
`;

export default SanityLivestream;

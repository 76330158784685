import { ProductEdge } from "../services/shopify";
import { withShopifyProducts, withSanityProducts } from "../types/fetch";
import {
  convertSanityRefToShopifyProductNumber,
  convertShopifyIdToSanityRef,
} from "../utils/sanity";
import { convertShopifyPathIdToNumber } from "./shopify/transform";

export const formatShopifyProductIds = ({
  shopifyProducts,
}: withShopifyProducts) =>
  shopifyProducts?.edges?.map((v: ProductEdge) => v.node.id) || [];

export const formatSanityProductIds = ({
  shopifyProducts,
}: withShopifyProducts) =>
  formatShopifyProductIds({ shopifyProducts }).map((id: string) =>
    convertShopifyIdToSanityRef(id)
  );

export const formatProducts = ({
  shopifyProducts,
  sanityProducts,
}: withShopifyProducts & withSanityProducts) =>
  transformSanityCollectionProducts(
    shopifyProducts
      .map((shopifyProduct: any) =>
        sanityProducts.find(
          (sanityProduct: any) =>
            sanityProduct._id == convertShopifyIdToSanityRef(shopifyProduct?.id)
        )
      )
      .filter((v: any) => !!v)
  );

export function findShopifyProductByRef(shopifyProducts: any[], ref: string) {
  return shopifyProducts.find((shopifyProduct) => {
    const shopifyId = convertShopifyPathIdToNumber(shopifyProduct.id);
    const sanityId = convertSanityRefToShopifyProductNumber(ref);
    return `${shopifyId}` == `${sanityId}`;
  });
}

export const transformSanityCollectionProducts = (sanityProducts: any) => {
  // Blacklist attributes from product
  const excludedAttributes = [
    "accordion",
    "collection",
    "content",
    "introduction",
    "packing",
    "productGroups",
    "related",
  ];

  // Function to exclude attributes from object
  const excludeKeysFromObject = (sanityProduct: any) => {
    return Object.keys(sanityProduct).reduce((coll, key) => {
      if (excludedAttributes.includes(key)) return coll;

      return {
        ...coll,
        [key]: sanityProduct[key],
      };
    }, {});
  };

  return sanityProducts.reduce((coll: any[], sanityProduct: any) => {
    return [...coll, excludeKeysFromObject(sanityProduct)];
  }, []);
};

import { useRouter } from "next/router";
import { SHOPIFY_DEFAULT_LOCATION } from "library/utils/shopify";
import { FormEvent, useCallback } from "react";
import { useCart } from "../contexts/CartProvider";
import { useInterface } from "../contexts/InterfaceProvider";
import { useProduct } from "../contexts/ProductProvider";
import { klaviyoAddToCart } from "./useKlaviyo";
import { pixelAddToCart } from "./usePixel";

function useAddToCart() {
  const {
    shopifyProduct,
    selectedQuantity,
    selectedVariant,
    dispatch: productDispatch,
  } = useProduct();

  const router = useRouter();

  const { cart, cartLineAdd } = useCart();
  const { setOverlay } = useInterface();

  const addToCart = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      productDispatch({
        type: "SET_ADDING",
        value: true,
      });

      try {
        const quantityAvailable = selectedVariant.quantityAvailable || 9999;
        const maxQuantity =
          quantityAvailable < 1 && selectedVariant.availableForSale
            ? 1
            : quantityAvailable;
        const quantity = Math.min(selectedQuantity, maxQuantity);

        await cartLineAdd({
          merchandiseId: selectedVariant.id,
          quantity,
        });

        pixelAddToCart(cart, {
          shopifyVariant: selectedVariant,
          shopifyProduct,
          quantity,
        });

        klaviyoAddToCart({
          shopifyProduct,
          shopifyVariant: selectedVariant,
          quantity: selectedQuantity,
          cart,
          locale: router.locale || SHOPIFY_DEFAULT_LOCATION,
        });

        setOverlay("CART");
      } catch (e: any) {
        console.error("Error adding to cart: ", e);
      }

      productDispatch({
        type: "SET_ADDING",
        value: false,
      });
    },
    [productDispatch, cartLineAdd, setOverlay, selectedVariant],
  );

  return {
    addToCart,
  };
}

export default useAddToCart;

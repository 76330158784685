import { useEffect, useState } from "react";
import { useInterface } from "../../contexts/InterfaceProvider";
import LinkInline from "../link/LinkInline";
import { fetchSanityReference } from "library/services/fetchers";

type Props = {
  link: {
    title: string;
    href: string;
    reference?: any;
    altReference?: any;
  };
  children?: React.ReactNode;
  [key: string]: any;
};

const SanityLinkReference: React.FC<Props> = ({
  children,
  link,
  ...props
}: Props) => {
  const [href, setHref] = useState("");
  const { overlay, closeOverlay } = useInterface();

  useEffect(() => {
    const loadReference = async () => {
      const [reference] = await fetchSanityReference({
        reference: link.reference._ref,
      });

      if (!reference) return;
      if (reference._type == "collection")
        return setHref(`/collections/${reference.store.slug.current}`);
      if (reference._type == "product")
        return setHref(`/products/${reference.store.slug.current}`);
      if (reference._type == "article")
        return setHref(`/journal/${reference.slug.current}`);
      if (reference._type == "promo")
        return setHref(`/promo/${reference.slug.current}`);
      if (reference._type == "page")
        return setHref(`/${reference.slug.current}`);
    };

    loadReference();
  }, []);

  const onClick = function () {
    if (overlay == "MMENU") closeOverlay();
  };

  return (
    <>
      <LinkInline href={href} onClick={onClick} {...props}>
        {children || link.title}
      </LinkInline>
    </>
  );
};

export default SanityLinkReference;

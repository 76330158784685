import { useContext, createContext, useReducer, useEffect } from "react";

import {
  customerAccount as customerAccountService,
  customerRecover as customerRecoverService,
  customerRegister as customerRegisterService,
  customerUpdate as customerUpdateService,
} from "library/services/shopify/customer";

import { getSession } from "next-auth/react";
import { Session } from "next-auth";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";

interface StorefrontSession extends Session {
  customerAccessToken;
}

export interface State {
  isLoggedIn: boolean | undefined;
  accessToken?: string;
  customer: any;
}

type Action =
  | { type: "SET_TOKEN"; value: State["accessToken"] }
  | { type: "SET_CUSTOMER"; value: State["customer"] }
  | { type: "SET_LOGIN_STATUS"; value: State["isLoggedIn"] };

// Customer Provider
export const CustomerContext = createContext<State | any>({ customer: null });

const customerReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_CUSTOMER": {
      return {
        ...state,
        customer: action.value,
      };
    }
    case "SET_TOKEN": {
      return {
        ...state,
        accessToken: action.value,
      };
    }
    case "SET_LOGIN_STATUS": {
      return {
        ...state,
        isLoggedIn: action.value,
      };
    }
    default: {
      console.log(`Invalid action ${action}`);
      throw new Error(`Invalid action`);
    }
  }
};

export const CustomerProvider: React.FC = ({ children }: any) => {
  const router = useRouter();

  const initialState: State = {
    isLoggedIn: undefined,
    accessToken: undefined,
    customer: null,
  };

  const [state, dispatch] = useReducer(customerReducer, initialState);

  const fetchCustomer = async () => {
    const session = (await getSession()) as StorefrontSession;
    const customerAccessToken = session?.customerAccessToken?.accessToken;
    if (customerAccessToken) {
      const customer = await customerAccountService(customerAccessToken);
      // console.log('customer logged in')
      dispatch({
        type: "SET_CUSTOMER",
        value: customer,
      });
      dispatch({
        type: "SET_TOKEN",
        value: customerAccessToken,
      });
      dispatch({
        type: "SET_LOGIN_STATUS",
        value: true,
      });
    } else {
      // console.log('customer logged out')
      dispatch({
        type: "SET_LOGIN_STATUS",
        value: false,
      });
    }
  };

  const customerSignIn = async ({ email, password }) => {
    try {
      const response: any = await signIn("shopify-login", {
        redirect: false,
        email,
        password,
        callbackUrl: `${window.location.origin}/account`,
      });
      if (response?.error)
        throw new Error("Login failed, check your credentials");
      window.location.href = `${window.location.origin}/account`;
    } catch (e: any) {
      throw e;
    }
  };

  const customerActivate = async ({ activationUrl, password }) => {
    try {
      const response: any = await signIn("shopify-activate", {
        redirect: false,
        activationUrl,
        password,
        callbackUrl: `${window.location.origin}/account`,
      });
      if (response?.error)
        throw new Error(
          "Activation failed, try logging in or resetting password",
        );

      window.location.href = `${window.location.origin}/account`;
    } catch (e: any) {
      throw e;
    }
  };

  const customerReset = async ({ resetUrl, password }) => {
    try {
      const response: any = await signIn("shopify-reset", {
        redirect: false,
        resetUrl,
        password,
        callbackUrl: `${window.location.origin}/account`,
      });
      if (response?.error)
        throw new Error("Reset failed, try a different password or contact us");
      window.location.href = `${window.location.origin}/account`;
    } catch (e: any) {
      throw e;
    }
  };

  const customerRecover = async ({ email }) => {
    return customerRecoverService(email);
  };

  const customerRegister = async (input) => {
    return customerRegisterService({ input });
  };

  const customerUpdate = async (customer) => {
    const customerAccessToken = state.accessToken || "";

    const updatedCustomer = await customerUpdateService({
      customer,
      customerAccessToken,
    });

    dispatch({
      type: "SET_CUSTOMER",
      value: updatedCustomer,
    });

    return updatedCustomer;
  };

  useEffect(() => {
    fetchCustomer();
  }, []);

  const value = {
    ...state,
    customerSignIn,
    customerReset,
    customerRecover,
    customerUpdate,
    customerRegister,
    customerActivate,
    dispatch,
  };

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  );
};

export function useCustomer() {
  return useContext(CustomerContext);
}

export default CustomerProvider;

import { useState } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'


interface Props {
  isOpen: boolean,
  children?: React.ReactNode
  className?: string
}
const TransitionAccordion: React.FC<Props> = ({ 
  children,
  isOpen,
  className,
}: Props) => {

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { height: 'auto' },
            collapsed: { height: 0 },
          }}
          transition={{
            duration: 0.3,
            ease: [0.65, 0.03, 0.16, 0.96],
          }}
        >{children}

        </motion.div>
      )}
</AnimatePresence>
  )
}

const Container = styled.div`
`

export default TransitionAccordion
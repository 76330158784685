import BlockContent from "@sanity/block-content-to-react";
import styled from "styled-components";
import SanityContentLink from "./content/SanityContentLink";

interface Props {
  className?: string;
  blocks: any;
}

const serializers = {
  marks: {
    annotationLink: (props) => {
      return <ContentLink link={props.mark}>{props.children}</ContentLink>;
    },
    superscript: (props) => {
      return <sup>{props.children[0]}</sup>;
    },
  },
};

const SanityBlocks: React.FC<Props> = ({ blocks, className }: Props) => {
  return (
    <Content
      className={className}
      blocks={blocks}
      serializers={serializers}
      renderContainerOnSingleChild={true}
    />
  );
};

const ContentLink = styled(SanityContentLink)``;
const Content = styled(BlockContent)``;

export default SanityBlocks;

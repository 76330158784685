import styled from 'styled-components'
import { useCart } from '../../contexts/CartProvider'
import { TypeCaptionL } from '../../styles/mixins';
import PriceRender from '../price/PriceRender';

interface P {
  [key: string]: any
}
const CartTotals: React.FC<P> = ({ 
  ...props
}: P) => {
  const {cart} = useCart();

  return (
    <Container {...props}>
      <span>Sub Total</span>
      <PriceRender display="CART" amount={cart?.estimatedCost.totalAmount.amount} currencyCode={cart?.estimatedCost.totalAmount.currencyCode} />
    </Container>
  )
}

const Container = styled.div`
  ${TypeCaptionL};
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid var(--color-charcoal200);
  border-bottom: 1px solid var(--color-charcoal200);
`

export default CartTotals
import { fetchByParams } from "../sanity/resource/helpers";
import { createHashKey } from "../../utils";
import { fetchWithMemoryCache } from "../../utils/cache/memory";

export const fetchSanityReference = ({ reference }: { reference: string }) => {
  const fetcher = () =>
    fetchByParams({
      isPreview: false,
      queryParams: `_id == "${reference}"`,
    });
  const key = createHashKey("fetchSanityReference", { reference });
  return fetchWithMemoryCache(key, fetcher);
};

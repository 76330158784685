import Cookies from "js-cookie";
import { useEffect, useState } from "react";

const COOKIE_NAME = "acceptDuties";

export const useAcceptDuties = () => {
  const [isAccepted, setIsAcceptedState] = useState(false);

  useEffect(() => {
    if (!!Cookies.get(COOKIE_NAME)) {
      setIsAcceptedState(true);
    } else {
      setIsAcceptedState(false);
    }
  }, []);

  const setIsAccepted = (accepted) => {
    setIsAcceptedState(accepted);

    if (accepted) {
      Cookies.set(COOKIE_NAME, "accepted", { expires: 365 });
    } else {
      Cookies.remove(COOKIE_NAME);
    }
  };

  return {
    isAccepted,
    setIsAccepted,
  };
};

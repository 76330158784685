export const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === "true";
export const isStaging = process.env.NEXT_PUBLIC_IS_PRODUCTION === "false";
export const isDevelopment = process.env.NODE_ENV === "development";

export const isServer = typeof window === "undefined";
export const isClient = typeof window !== "undefined";

export function useFlags() {
  return {
    isProduction,
    isStaging,
    isDevelopment,

    isServer,
    isClient,
  };
}

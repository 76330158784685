import { useCart } from '../contexts/CartProvider'

export function usePrice(price: number) {
  const number = Math.floor(price);
  const { country, } = useCart();
  const { currency: {isoCode, symbol}, } = country;

  return {
    currencyCode: isoCode,
    symbol,
    number,
    hundredths: number % 1,
    hasHundredths: number % 1 != 0,
  }
}
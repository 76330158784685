import styled, { css } from "styled-components";
import { useInterface } from "../../contexts/InterfaceProvider";
import { Breakpoint, MobileBreakpoint } from "../../styles/functions";
import { TransitionSlide, TypeBodyM } from "../../styles/mixins";
import SvgClose from "../svg/SvgClose";

interface P {
  isActive: boolean;
  setIsActive?: any;
  title: string;
  children: React.ReactNode;
  backgroundColor?: string;
  borderColor?: string;
  zIndex?: number;
  width?: number;
  [key: string]: any;
}

const OverlaySlideWrap: React.FC<P> = ({
  isActive,
  setIsActive,
  title,
  children,
  width = 490,
  zIndex = 9999,
  backgroundColor = "background",
  borderColor = "charcoal200",
  ...props
}: P) => {
  const { closeOverlay } = useInterface();

  const onClose = setIsActive ? () => setIsActive(false) : closeOverlay;

  return (
    <Wrap $isActive={isActive} $zIndex={zIndex} {...props}>
      <Container
        $isActive={isActive}
        $backgroundColor={backgroundColor}
        $borderColor={borderColor}
        $width={width}
      >
        <Title>
          {title}

          <Close onClick={onClose}>
            <SvgClose />
          </Close>
        </Title>
        {children}
        <Background />
      </Container>
      <CloseBackground onClick={onClose} $isActive={isActive} />
    </Wrap>
  );
};

const Wrap = styled.div<{ $isActive; $zIndex }>`
  height: calc(var(--1vh) * 100);
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;

  z-index: ${(p) => p.$zIndex};
  pointer-events: none;
  overflow: hidden;

  ${(props) =>
    props.$isActive &&
    css`
      > * {
        pointer-events: auto;
      }
    `}
`;
const Container = styled.div<{
  $isActive;
  $backgroundColor;
  $borderColor;
  $width;
}>`
  height: calc(var(--1vh) * 100);
  transition: ${TransitionSlide};
  width: ${(p) => p.$width}px;
  padding: 16px var(--inset);
  flex-direction: column;
  pointer-events: auto;
  position: absolute;
  display: flex;
  left: auto;
  z-index: 10;
  bottom: 0;
  right: 0;

  ${Breakpoint("mobile", "min")} {
    right: -100%;
    left: auto;
  }

  ${MobileBreakpoint} {
    width: ${(p) => p.$width}px;
    max-width: calc(100% - var(--inset));
    bottom: -100%;
    top: auto;
  }

  ${(props) =>
    props.$isActive &&
    css`
      ${Breakpoint("mobile", "min")} {
        right: 0;
      }

      ${MobileBreakpoint} {
        bottom: 0;
      }
    `}

  ${(props) =>
    props.$backgroundColor &&
    css`
      background: var(--color-${props.$backgroundColor});
      --ButtonPrimary-color: var(--color-${props.$backgroundColor});
      --border-color: var(--color-${props.$borderColor});
    `}
`;

const Title = styled.h3`
  ${TypeBodyM};
  border-bottom: 1px solid var(--border-color);
  padding-bottom: var(--spacing-16);
  justify-content: space-between;
  position: relative;
  display: flex;
  margin-bottom: 8px;

  ${MobileBreakpoint} {
    margin-bottom: 16px;
  }
`;

const Close = styled.button`
  svg {
    width: 12px;
    height: 12px;
  }
  ${Breakpoint("mobile", "min")} {
    display: none;
  }
`;

const Background = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  background: var(--color-background);
  z-index: -1;
`;

const CloseBackground = styled.div<{ $isActive }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -10;
  background: var(--color-charcoal);
  opacity: 0;
  transition: ${TransitionSlide};

  cursor: pointer;

  ${(props) =>
    props.$isActive &&
    css`
      pointer-events: auto;
      opacity: 0.25;
    `}
`;

export default OverlaySlideWrap;

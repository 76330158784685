import styled from 'styled-components'
import UtilWrapper from 'frontend/src/components/util/UtilWrapper';
import { TypeBodyM, TypeHeadingS } from '../../styles/mixins';
import { DefaultComponentInterface } from '../../types/components';
import {contact} from '../../utils/postmark';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

const PageError: DefaultComponentInterface<{error: any}> = ({ error }: {error: any}) => {
  const router = useRouter();

  const sendEmailUsingPostmark = async () => {
    try {
      await contact({
        to: "joe@1of1studio.com",
        from: "joe@1of1studio.com",
        subject: "YM: Page Error",
        body: `
          <p>Error from: ${router.asPath}</p>
          <p>${JSON.stringify(error, null, 2)}</p>
        `,
      })
    } catch (error) {
      console.error('Failed to send email using Postmark:', error);
    }
  };

  useEffect(() => {
    sendEmailUsingPostmark();
  }, []);

  return (
    <Container>
    <Content>
      <p>Sorry, we could not load that page right now.</p>
    </Content>
      <Details>
        {error.message}: {error.details}<br/><br/>
        <Link href="/">Keep shopping</Link>
      </Details>
    </Container>
  )
}

const Container = styled(UtilWrapper)`
  margin-top: var(--spacing-page-top);
  margin-bottom: var(--spacing-18);
  text-align: center;
`

const Link = styled.a`
  color: #999;
`

const Content = styled.div`
  ${TypeHeadingS};
`

const Details = styled.div`
  ${TypeBodyM};
  max-width: 400px;
  margin: 0 auto 200px;
`

export default PageError

import { useState } from "react";
import { useForm } from "react-hook-form";

const useFormAccount = (requestFunction) => {
  // const {customerSignIn} = useCustomer();
  const [submitErrors, setSubmitErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm();

  const onSubmit = handleSubmit(async (form) => {
    setIsSubmitting(true);

    try {
      // await customerSignIn({ email, password })
      const response = await requestFunction(form);
      setSuccessMessage(response);
      reset();
    } catch (e: any) {
      console.error(e);
      setSubmitErrors([e]);
    }

    setIsSubmitting(false);
  });

  const disabled = isSubmitting || Object.keys(errors).length > 0;

  return {
    disabled,
    errors,
    onSubmit,
    register,
    submitErrors,
    isSubmitting,
    successMessage,
    reset,
  };
};

export default useFormAccount;

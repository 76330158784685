
import styled from 'styled-components'
import OverlayPopupWrap from './OverlayPopupWrap'
import { useCart } from '../../contexts/CartProvider'
import FormDetect from '../form/FormDetect'

interface P {
  isActive: boolean
}

const OverlayDetect: React.FC<P> = ({ 
  isActive,
}: P) => {
  const title = `Choose Your Shipping Region / Currency`
  // const router = useRouter();
  const {dispatch: cartDispatch} = useCart();
  const close = () => cartDispatch({
    type: 'SET_DETECT_OVERLAY_ACTIVE',
    value: false,
  })

  return (
    <Container isActive={isActive} setIsActive={close} title={title}>
      <FormDetect />
    </Container>
  )
}

const Container = styled(OverlayPopupWrap)``

export default OverlayDetect

export function isIntersectionObserverRootSupported() {
  try {
    const observer = new IntersectionObserver(() => null, {
      root: null,
    });

    // Check if the root property of the observer is the same as the one we provided
    const isSupported = observer.root === null;
    observer.disconnect();

    return isSupported;
  } catch (e) {
    console.log(e);
    return false;
  }
}

import styled, { css } from 'styled-components'
import OverlayBanner from '../overlay/OverlayBanner';
import HeaderDesktop from '../header/HeaderDesktop';
import TransitionFade from '../transition/TransitionFade'
import HeaderSubmenuShop from './HeaderSubmenuShop'
import HeaderSubmenuClub from './HeaderSubmenuClub'
import HeaderSubmenuAbout from './HeaderSubmenuAbout'
import { useHeaderSubmenu } from '../../contexts/HeaderSubmenuProvider';
import { useMeasure } from 'react-use';
import { useEffect } from 'react';
import { Breakpoint } from '../../styles/functions';
import { TransitionDefault, TransitionSlide } from '../../styles/mixins';
import { useRouter } from 'next/router';
import ErrorBoundary from '../ErrorBoundary';

type Props = {
  setHeaderHeight: any
  className?: string,
  children?: React.ReactNode,
}

const HeaderDesktopWrapper: React.FC<Props> = ({
  setHeaderHeight,
}: Props) => {
  const router = useRouter();
  const { submenu, hideSubmenu } = useHeaderSubmenu();

  const [ measureRef, { height } ] = useMeasure<HTMLDivElement>();

  useEffect(() => {
    if(height > 0) setHeaderHeight(height)
  }, [height]);

  // Close submenu if its active 
  // While route changes
  useEffect(() => {
    if(submenu != '') hideSubmenu();
  }, [router.asPath])

  return (
    <ErrorBoundary>
    <Container onMouseLeave={(hideSubmenu)}>
      <div ref={measureRef}>
        <OverlayBanner />

        <HeaderDesktop />
      </div>

      <SubmenuWrap $isOpen={submenu != ""}>
        <SubmenuShop $isOpen={submenu == 'SHOP'} /> 
        <SubmenuClub $isOpen={submenu == 'CLUB'} /> 
        <SubmenuAbout $isOpen={submenu == 'ABOUT'} /> 
      </SubmenuWrap>

      <Background $isActive={submenu != ''} />
    </Container>
    </ErrorBoundary>
  )
}

const Container = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9050;

  ${Breakpoint('tablet', 'max')} {
    display: none;
  }
`

const SubmenuWrap = styled.div<{$isOpen}>`
  position: absolute;
  top: 100%;
  display: grid;
  pointer-events: none;
  background: var(--color-background);
  transition: ${TransitionDefault};
  opacity: 0;
  left:0;right:0;

  > * {
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    width: 100vw;
  }

  ${p => p.$isOpen && css`
    opacity: 1;
    > * {
      pointer-events: auto;
    }
  `}
`

const SubmenuShop = styled(HeaderSubmenuShop)<{$isOpen}>`
  opacity: 0;
  pointer-events: none;
  ${p => p.$isOpen && css`
    opacity: 1;
    pointer-events: auto;
  `}
`
const SubmenuClub = styled(HeaderSubmenuClub)<{$isOpen}>`
  opacity: 0;
  pointer-events: none;
  ${p => p.$isOpen && css`
    opacity: 1;
    pointer-events: auto;
  `}
`
const SubmenuAbout = styled(HeaderSubmenuAbout)<{$isOpen}>`
  opacity: 0;
  pointer-events: none;
  ${p => p.$isOpen && css`
    opacity: 1;
    pointer-events: auto;
  `}
`

const Background = styled.div<{$isActive}>`
  position: fixed;
  left:0;right:0;
  top:0;bottom:0;
  z-index: -1;
  background: var(--color-charcoal);
  opacity: 0;
  transition: ${TransitionDefault};
  pointer-events: none;

  cursor: pointer;

  ${props => props.$isActive && css`
    opacity: 0.1;
  `}
`

export default HeaderDesktopWrapper

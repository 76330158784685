import { css } from 'styled-components'
import { VarBreakpoints } from 'frontend/src/styles/variables'

export const toPixels = (value: string | number) => {
  if (typeof value === 'number') {
    return `${value}px`
  }

  return value
}

export function mapToPixels(values: number[]) {
  return values.map((value) => toPixels(value))
}

export function mapCSSObject(variables: StyleVariables) {
  return Object.keys(variables).reduce((c: string[], key: string) => {
    const value = variables[key]

    return [`${key}: ${value};`, ...c]
  }, []).join('');
}

export function mapCSSVariables(variables: StyleVariables, prefix: string) {
  return Object.keys(variables).reduce((c: string[], key: string) => {
    const value = variables[key]

    return [`--${prefix}-${key}: ${value};`, ...c]
  }, []).join('');
}

export function mapCSSResponsive(variables: StyleResponsive) {
  const mobileWidth = VarBreakpoints.mobile
  const tabletWidth = VarBreakpoints.tablet

  return css`
    ${variables?.desktop && mapCSSObject(variables.desktop)}

    @media screen and (max-width: ${tabletWidth}) {
      ${variables?.tablet && mapCSSObject(variables.tablet)}
    }

    @media screen and (max-width: ${mobileWidth}) {
      ${variables?.mobile && mapCSSObject(variables.mobile)}
    }
  `
}

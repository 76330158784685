// import { useHeaderSubmenu } from '../../contexts/HeaderSubmenuProvider';
import {scrollToAnchor} from '../../hooks/useScrollToAnchor';
import ButtonInline from '../button/ButtonInline';
// import LinkInline from '../link/LinkInline';

type Props = {
  link: {
    title: string,
    href: string,
    destination?: string,
  },
  children?: React.ReactNode,
  [key: string]: any,
}

const SanityLinkScroll: React.FC<Props> = ({
  children, 
  link,
  ...props
}: Props) => {
  
  return (
      <ButtonInline 
        onClick={() => scrollToAnchor(link?.destination)}
        {...props}
      >
        {children || link.title}
      </ButtonInline>
  )
}

export default SanityLinkScroll

import styled from 'styled-components'
import { useSettings } from '../../contexts/SettingsProvider'
import { TypeCaptionL } from '../../styles/mixins';
import SanityContent from '../sanity/content/SanityContent';

interface P {
  foo?: any
}

const CartShipping: React.FC<P> = ({ 
  ...props
}: P) => {
  const settings = useSettings();

  return (
    <Container {...props}>
      <SanityContent layout="OVERLAY" blocks={settings.cart.shippingText} />
    </Container>
  )
}

const Container = styled.div`
  ${TypeCaptionL};
  padding-bottom: 8px;
`

export default CartShipping
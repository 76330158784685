import { getLineItemsFromCart } from 'library/utils/shopify/getters';
import styled, { css } from 'styled-components'
import { useCart } from '../../contexts/CartProvider';
import ItemProvider from '../../contexts/ItemProvider';
import { BezierDefault } from '../../styles/mixins';
import CartItem from './CartItem'

const CartItems: React.FC = ({ 
  ...props
}) => {
  const { cart, isUpdating } = useCart();
  const lineItems = getLineItemsFromCart(cart);

  return (
    <Container $isUpdating={isUpdating} {...props}>
      { lineItems.map((item) => 
        <ItemProvider item={item} key={item.id}>
          <Item item={item} />
        </ItemProvider>
      )}
    </Container>
  )
}


const Item = styled(CartItem)`
`

const Container = styled.div<{$isUpdating}>`
  transition: all 0.3s ${BezierDefault};
  ${props => props.$isUpdating && css`
    opacity: 0.5;
  `}
`

export default CartItems
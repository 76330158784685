import { useCustomer } from '../contexts/CustomerProvider'
import useFormAccount from './useFormAccount';

function useFormRecover() {
  const {customerRecover} = useCustomer();
  return useFormAccount(async (input) => {
    await customerRecover(input);
    return "Check your emails for recovery information"
  })
}

export default useFormRecover
// import { LayoutStyles } from 'frontend/src/styles/layout'
// import {SanityFileAsset} from '@sanity/asset-utils';
import styled, { css } from "styled-components";
import { Breakpoint, Columns, MobileBreakpoint } from "../../styles/functions";
import { BezierDefault, Hover } from "../../styles/mixins";
import * as TextStyles from "../../styles/mixins/text";
import { Indent, TypeBodyM } from "../../styles/mixins/text";
import SanityBlocks from "../sanity/SanityBlocks";
// import LinkInline from 'frontend/src/components/link/LinkInline';

interface Props {
  className?: string;
  caption: string;
  captionAlign?: "BOTTOM" | "TOP" | "SUPER";
  content?: any;
  indent?: number;
  textStyle: string;
  children?: React.ReactNode;
  style?: any;
}

const TypesetRender: React.FC<Props> = ({
  indent,
  textStyle,
  caption,
  captionAlign = "BOTTOM",
  content,
  children,
  className,
  style,
}: Props) => {
  const indentStyle = indent ? Columns(indent, 0) : undefined;

  return (
    <Container
      className={className}
      $indentStyle={indentStyle}
      $textStyle={textStyle}
      style={style}
    >
      {children
        ? children
        : content && (
            <Content
              $captionAlign={captionAlign}
              $caption={caption}
              $indentStyle={indentStyle}
              blocks={content}
            />
          )}
    </Container>
  );
};

const Container = styled.div<{
  $textStyle?: string;
  $indentStyle?: string;
}>`
  position: relative;

  ${(props) => props.$textStyle && TextStyles[props.$textStyle]};

  ${(props) =>
    props.$indentStyle &&
    `
    --indent: ${props.$indentStyle};
  `};

  .link-inline > * {
    transition: opacity 0.3s ${BezierDefault};
  }

  ${(props) =>
    props.$textStyle == "TypeCaptionL" &&
    css`
    .link-inline > * {
      opacity: 0.5;
      text-decoration: underline;
      
      ${Hover} {
        opacity: 1;
      }}
    }
  `};
`;

const Content = styled(SanityBlocks)<{
  $caption: string;
  $indentStyle?: string;
  $captionAlign: Props["captionAlign"];
}>`
  display: contents;

  ${Breakpoint("mobile", "min")} {
    ${(props) =>
      props.$indentStyle &&
      css`
        > * {
          ${Indent};
        }

        > *:nth-child(1):before {
          ${TypeBodyM};
          content: "${props.$caption || ""}";
          vertical-align: baseline;
        }
      `};

    ${(props) =>
      props.$captionAlign == "TOP" &&
      css`
        > *:nth-child(1):before {
          vertical-align: top;
        }
      `};

    ${(props) =>
      props.$captionAlign == "SUPER" &&
      css`
        > *:nth-child(1):before {
          vertical-align: super;
        }
      `};
  }

  ${MobileBreakpoint} {
    ${(props) =>
      props.$indentStyle &&
      css`
        > * {
          ${Indent};
          --indent: ${Columns(2, 0)};
        }

        > *:nth-child(1):before {
          ${TypeBodyM};
          display: block;
          content: "${props.$caption || ""}";
          margin-bottom: 8px;
          width: auto;
        }
      `};
  }
`;

export default TypesetRender;

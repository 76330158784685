import { formatDate } from 'library/services/sanity/helpers'
import styled from 'styled-components'
import { Breakpoint, Columns, MobileBreakpoint } from '../../styles/functions'
import { TypeBodyM, TypeHeadingXS } from '../../styles/mixins'
import Link from '../link/LinkRender'
import SanityImage from "../sanity/SanityImage"

interface P {
  article: any
  [key: string]: any
}
const ArticleCardSubmenu: React.FC<P> = ({ 
  article,
  ...props
}: P) => {

  const date = formatDate(article.publishedAt);
  
  return (
    <Wrapper {...props}>
      <LinkWrap href={`/journal/${article.slug.current}`}>
        <SanityImage  alt={article.title} image={article.image} ratio="2x3" />
        <Details>
          <time itemProp="datePublished" dateTime={article.publishedAt}>{date}</time>
          <div>Words by {article.author}</div>
          <Title>
            {article.title}
          </Title>
        </Details>
      </LinkWrap>
    </Wrapper>
  )
}

const Wrapper = styled.div`
`


const Details = styled.div`
  ${TypeBodyM}
`

const Title = styled.h3`
  ${TypeHeadingXS};
  margin-top: var(--spacing-16);
`

const LinkWrap = styled(Link)`
display: grid;
grid-template-columns: ${Columns(3)} 1fr;
gap: var(--gutter);

${MobileBreakpoint} {
grid-template-columns: ${Columns(4)} 1fr;
}
`

export default ArticleCardSubmenu

import { animateScroll } from 'react-scroll';

// Filter by displayed elements
const findAnchorTop = (anchor) => {
  const els = Array.from(document.querySelectorAll(`[data-anchor='${anchor}']`));

  const rects = els
    .map((el) => el.getBoundingClientRect())
    .filter((rect) => !!rect?.y && rect?.y > 0);
    
  return rects?.[0].top || 0;
}

// Get calculated header height
const findOffsetTop = () => {
  const el = document.querySelector("#__next > div");
  if(!el) return 0;
  const style = getComputedStyle(el);
  return parseFloat(style.getPropertyValue('--header-height').replace('px',''))
}

export const scrollToAnchor = function(anchor) {
  const offsetTop = findOffsetTop()
  const anchorTop = findAnchorTop(anchor)

  const top = window.pageYOffset + anchorTop - offsetTop;

  animateScroll.scrollTo(top, {
    smooth: 'easeInOutQuad',
    duration: 600,
  });
}

export default {
  scrollToAnchor,
}
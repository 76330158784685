
import Link from '../../link/LinkRender';
import ProductOverviewImages from '../ProductOverviewImages';
import styled, { css } from 'styled-components';
import { useProduct } from 'frontend/src/contexts/ProductProvider';
import { BezierDefault, Hover } from 'frontend/src/styles/mixins';

interface Props {
  index: number
  activeIndex?: number
  setActiveIndex: any
  className?: string
}
const ProductCardOverview: React.FC<Props> = ({ 
  index,
  activeIndex = -1,
  setActiveIndex = () => null,
}: Props) => {
  const { shopifyProduct } = useProduct();
  const medias = shopifyProduct.media.edges.map((v) => v.node);

  return (
    <Container 
      href={`/products/${shopifyProduct.handle}`} 
      onMouseEnter={() => setActiveIndex(index)}
      $isActive={activeIndex == index}
      $activeIndex={activeIndex}
    >
      <ProductOverviewImages
        layout="COLLECTION"
        index={index}
        medias={medias} 
        activeDetail={-1}
        onImageMouseOver={(index) => () => false}  
        shopifyProduct={shopifyProduct}
      />
    </Container>
  )
}

const Container = styled(Link)<{$isActive, $activeIndex}>`
  display: contents;

  .product-overview-image {
    &:nth-child(1) {
      position: relative;
      .product-overview-image__media {
        transition: opacity 0.3s ${BezierDefault};
        z-index: 2;
      }
      .product-overview-image__media-container {
        background: transparent;
        transition: background-color 0.3s ${BezierDefault};
      }
    }
  }

  .product-overview-image__caption {
    opacity: 1;
    transition: opacity 0.3s ${BezierDefault};
  }
  

  ${props => (props.$isActive) && css`
    .product-overview-image {
      &:nth-child(1) {
        .product-overview-image__media {
          opacity: 0;
        }
        .product-overview-image__caption {
          opacity: 1;
        }
      }
    }
  `}

  ${props => (props.$activeIndex !== -1 && !props.$isActive) && css`
    .product-overview-image {
      &:nth-child(1) {
        .product-overview-image__caption {
          opacity: 0;
        }
        .product-overview-image__media-container {
          background: var(--color-charcoal100);
        }
      }
    }

    .product-overview-image__media,
    .product-overview-image__caption {
      opacity: 0;
    }
  `}
`

export default ProductCardOverview
import styled, { css } from "styled-components";
import { MobileBreakpoint } from "../../../styles/functions";
import { TypeBodyM, TypeCaptionM } from "../../../styles/mixins";
// import {SanityFileAsset} from '@sanity/asset-utils';

interface Props {
  layout: string;
  className?: string;
  title: string;
  table: any;
}
const SanityContentTable: React.FC<Props> = ({
  layout,
  title,
  table,
  className,
}: Props) => {
  return (
    <Wrapper className={className} $layout={layout}>
      <Title>{title}</Title>

      <table>
        <Table style={{ display: "grid" }}>
          {table.rows.map((row) => (
            <Row key={row._key}>
              {row.cells.map((cell, k) => (
                <Cell key={`${row._key}-${k}`}>{cell}</Cell>
              ))}
            </Row>
          ))}
        </Table>
      </table>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $layout }>`
  ${(props) =>
    props.$layout == "INFO" &&
    css`
      margin-bottom: var(--spacing-48);

      ${Title} {
        ${TypeBodyM};
        margin-left: 0;
        margin-bottom: var(--spacing-24);
      }

      ${Row} {
        + tr td {
          padding-top: var(--spacing-24);
        }
      }

      ${Cell} {
        + td {
          padding-left: var(--gutter);
        }

        &:nth-child(1) {
          min-width: 180px;
          ${MobileBreakpoint} {
            min-width: 120px;
          }
        }
      }
    `}

  ${(props) =>
    props.$layout == "OVERLAY" &&
    css`
      margin-bottom: var(--spacing-16);
      display: grid;

      ${Title} {
        ${TypeCaptionM};
        margin-left: 0;
        margin-bottom: var(--spacing-16);
      }

      ${Table} {
        grid-template-columns: minmax(min-content, max-content) 1fr;
      }

      ${Row} {
        display: contents;
        + tr td {
          padding-top: 4px;
        }
      }

      ${Cell} {
        + td {
          padding-left: var(--gutter);
        }

        &:nth-child(1) {
          min-width: 180px;
          ${MobileBreakpoint} {
            min-width: 120px;
          }
        }

        &:nth-child(2) {
          flex-grow: 1;
        }
      }
    `}
`;

const Title = styled.h3`
  text-transform: initial;
`;
const Table = styled.tbody``;

const Row = styled.tr``;
const Cell = styled.td``;

export default SanityContentTable;

import { ReactNode } from 'react'
import styled from 'styled-components'
import { UnstyledLinkInline } from '../../styles/mixins'
import Link from './LinkRender'

type Props = {
  className?: string
  children?: ReactNode
  onMouseEnter?
  onClick?
  [key: string]: any
}

const LinkInline: React.FC<Props> = ({
  className,
  children,
  onMouseEnter,
  onClick,
  ...props
}: Props) => {
  const wrapperProps = {
    className: className + ' link-inline',
    ...(onMouseEnter && {onMouseEnter}),
    ...(onClick && {onClick}),
  }
  
  return (
    <Wrapper  
      {...wrapperProps}
      >
      <Link {...props}>
        {children}
      </Link>
    </Wrapper>
  )
}


const Wrapper = styled.span`
  ${UnstyledLinkInline};
  display: contents;
`

export default LinkInline

import { ProductFieldsFragment } from "library/services/shopify";
import { CustomerAccountFieldsFragment } from "./../../../library/services/shopify/generated";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { useCustomer } from "../contexts/CustomerProvider";
import { getGPCFromProduct } from "library/utils/shopify/getters";

export type GAPageProps = {
  isLoggedIn: boolean | undefined;
  customer: CustomerAccountFieldsFragment;
  pathname: string;
};

export const gaViewPage = ({ isLoggedIn, customer, pathname }: GAPageProps) => {
  if (!window.dataLayer) return;
  // const router = useRouter();

  const dateConversion = (date: string) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(new Date(date));
  };

  const lastOrder = customer?.orders?.edges?.[0]?.node.processedAt;

  const viewData = {
    event: "ga4kit_info",
    contentGroup: pathname,
    ...(isLoggedIn && customer
      ? {
          customer: {
            id: customer.id,
            lastOrder: lastOrder ? dateConversion(lastOrder) : "",
            orderCount: customer.orders.edges.length,
            totalSpent: customer.orders.edges.reduce((coll: number, order) => {
              return coll + parseFloat(order.node.currentTotalPrice.amount);
            }, 0),
            tags: customer.tags,
          },
        }
      : {
          userType: "visitor",
        }),
  };

  window.dataLayer.push(viewData);
};

export const gaViewProduct = (shopifyProduct: ProductFieldsFragment) => {
  if (!window.dataLayer) return;
  const variant = shopifyProduct.variants.edges[0].node;
  const category = getGPCFromProduct(shopifyProduct);
  const price = shopifyProduct.priceRange.minVariantPrice;

  const productData = {
    event: "analyzify_productDetail",
    productId: shopifyProduct.id,
    productName: shopifyProduct.title,
    productPrice: price.amount,
    productBrand: shopifyProduct.vendor,
    productType: shopifyProduct.productType,
    productCategory: category || "",
    productSku: variant.sku,
    productVariantId: variant.id,
    productVariantTitle: variant.title,
    currency: price.currencyCode,
  };

  window.dataLayer.push(productData);
};

export function useGA() {
  const router = useRouter();
  const { isLoggedIn, customer } = useCustomer();

  const handleRouteChange = () => {
    gaViewPage({ isLoggedIn, customer, pathname: router.pathname });
  };

  useEffect(() => {
    if (typeof isLoggedIn == "undefined") return;
    handleRouteChange();
  }, [isLoggedIn, router.asPath]);

  // useEffectOnce(() => {
  //   const url = router.asPath;
  //   handleRouteChange(url);
  // })

  // useEffect(() => {
  //   router.events.on('routeChangeComplete', handleRouteChange)
  //   return () => router.events.off('routeChangeComplete', handleRouteChange)
  // }, [router.events])
}

import { Breakpoint } from "../../styles/functions";
import { TransitionSlide, TypeBodyM } from "../../styles/mixins";
import CartUpsellProducts from "../cart/CartUpsellProducts";
import styled, { css } from "styled-components";

const width = 380;

type P = { isActive: boolean; className?: string };

const OverlayCartUpsell: React.FC<P> = ({ className, isActive }: P) => {
  return (
    <Container $isActive={isActive} className={className}>
      <Title>Recommended Pairings</Title>
      <Body>
        <CartUpsellProducts />
      </Body>
    </Container>
  );
};

const Container = styled.div<{ $isActive }>`
  background: var(--color-charcoal100);
  position: absolute;
  left: auto;
  bottom: 0;
  height: calc(var(--1vh) * 100);
  right: 100%;
  width: ${width}px;
  pointer-events: auto;
  padding: 16px var(--inset);
  z-index: -10;
  display: flex;
  flex-direction: column;
  transition: ${TransitionSlide};
  left: auto;
  right: -100%;

  ${(props) =>
    props.$isActive &&
    css`
      ${Breakpoint("mobile", "min")} {
        right: 100%;
      }
    `}
`;

const Title = styled.h3`
  ${TypeBodyM};
  border-bottom: 1px solid var(--border-color);
  padding-bottom: var(--spacing-16);
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
`;

const Body = styled.div`
  overflow-y: auto;
  flex-grow: 1;
`;

export default OverlayCartUpsell;

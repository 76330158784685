import { FC } from 'react'
import { FieldError, UseFormRegister } from 'react-hook-form'
import FormInputRender from './FormInputRender'

// How to type register properly:
// https://codesandbox.io/s/react-hook-form-typescript-xnb1u?file=/src/components/Input.tsx

interface InputEmailProps {
  error?: any
  register: UseFormRegister<any>
  required?: boolean
  name?: string
  label?: string
  [key: string]: any
}

const FormInputEmail: FC<InputEmailProps> = ({ 
  required,
  register,
  name = "email",
  label = "Email",
  ...props
}) => {
  const registerProps = register(name, {
    required,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Invalid email address',
    },
  });

  return <FormInputRender
    type="email"
    register={register}
    required={required}
    label={label}
    placeholder={label}
    {...registerProps}
    {...props}
  />
}

export default FormInputEmail

import styled, { css } from 'styled-components'
import { LinkInline, TypeBodyM, TypeCaptionM } from '../../styles/mixins';
import SanityLinks from './SanityLinks';

type Props = {
  className?: string,
  links?: any,
  layout: "MENU" 
    | "SUBMENU" 
    | "MMENU"
    | "FOOTER_DESKTOP_LEFT"
    | "FOOTER_DESKTOP_RIGHT",
  children?: React.ReactNode,
}

const SanityMenu: React.FC<Props> = ({
  // children, 
  className, 
  links, 
  layout,
}: Props) => {
  return (
    <Wrapper className={className} $layout={layout}>
      <Links links={links} />
    </Wrapper>
  )
}

const Links = styled(SanityLinks)``

const Wrapper = styled.nav<{$layout: string | undefined}>`
    
  ${props => props.$layout != 'MENU' && css`
    .sanity-link + .sanity-link {
      margin-top: 4px;
    }
  `}

  ${props => props.$layout == 'MENU' && css`
    ${Links} {
      display: inline-flex;
      ${TypeBodyM};
    
      .sanity-link {
        + .sanity-link {
          margin-left: 40px;
        }
      }
    }
  `}

${props => (props.$layout == 'SUBMENU' || props.$layout == 'MMENU') && css`
  ${Links} {
    ${TypeBodyM};
    .sanity-link {
      &--depth-1 {
        >.sanity-link {
          &__title,
          &__sublink {
            margin-bottom: var(--spacing-16);
          }
          
          &__title,
          &__sublink a,
          &__sublink button,
          &__sublink {
            ${TypeCaptionM};
          }
        }

        + * {
          margin-top: var(--spacing-16);
        }
      }
    }
  }
`}

${props => (props.$layout == 'MMENU') && css`
  ${Links} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px var(--gutter);

    .sanity-link {
      &--depth-1 {
        + * {
          margin-top: 0;
        }
      }
    }
  }
`}
`
// const Link = styled.a``

export default SanityMenu

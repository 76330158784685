// import BlockContent from '@sanity/block-content-to-react';
import styled from "styled-components";
import { useEffect, useState } from "react";

function getTimeRemaining(endtime = "") {
  const unflooredTotal = (endtime && Date.parse(endtime) - Date.now()) || 0;
  const total = unflooredTotal < 0 ? 0 : unflooredTotal;
  const seconds = (endtime && Math.floor((total / 1000) % 60)) || 0;
  const minutes = (endtime && Math.floor((total / 1000 / 60) % 60)) || 0;
  const hours = (endtime && Math.floor((total / (1000 * 60 * 60)) % 24)) || 0;
  const days = (endtime && Math.floor(total / (1000 * 60 * 60 * 24))) || 0;

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
}

interface SanityContentCountdownProps {
  className?: string;
  end: string;
}

const SanityContentCountdown: React.FC<SanityContentCountdownProps> = ({
  end,
}: SanityContentCountdownProps) => {
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());

  useEffect(() => {
    setTimeRemaining(getTimeRemaining(end));
    const interval = setInterval(() => {
      setTimeRemaining(getTimeRemaining(end));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatDigits = (time) => `${time}`.padStart(2, "0");

  return (
    <Container>
      <Counter>
        {/* <CounterBadge>Days</CounterBadge> */}
        <CounterNumber>{formatDigits(timeRemaining.days)}</CounterNumber>
      </Counter>
      <Divider />
      <Counter>
        {/* <CounterBadge>Hours</CounterBadge> */}
        <CounterNumber>{formatDigits(timeRemaining.hours)}</CounterNumber>
      </Counter>
      <Divider />
      <Counter>
        {/* <CounterBadge>Minutes</CounterBadge> */}
        <CounterNumber>{formatDigits(timeRemaining.minutes)}</CounterNumber>
      </Counter>
      <Divider />
      <Counter>
        {/* <CounterBadge>Seconds</CounterBadge> */}
        <CounterNumber>{formatDigits(timeRemaining.seconds)}</CounterNumber>
      </Counter>
    </Container>
  );
};

const Container = styled.div`
  /* margin: 0 auto; */
  display: flex;
  gap: 2px;
  flex-wrap: nowrap;
`;

const Counter = styled.div`
  position: relative;
  display: block;
`;

const CounterBadge = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const CounterNumber = styled.div`
  text-align: right;
  font-variant-numeric: tabular-nums;
`;

const Divider = styled.div`
  &:before {
    content: ":";
    /* padding-left: 4px; */
    /* padding-right: 4px; */
    text-align: center;
  }
`;

export default SanityContentCountdown;


interface P {
  isOpen: boolean
}
const SvgHamburger: React.FC<P> = ({ 
  isOpen,
  ...props
}: P) => {
  let lines;
  if(isOpen) {
    lines = (
      <path fillRule="evenodd" clipRule="evenodd" d="M18.2005 7.20047L17.154 6.15395L12.1665 11.1414L7.01251 5.98749L5.96599 7.03401L11.1199 12.188L5.84024 17.4677L6.88676 18.5142L12.1665 13.2345L17.2797 18.3477L18.3262 17.3012L13.213 12.188L18.2005 7.20047Z" fill="#414042"/>
    )
  } else {
    lines = (
      <>
        <line x1="21" y1="8.25" x2="3" y2="8.25" stroke="#414042" strokeWidth="1.5"/>
        <line x1="21" y1="16.75" x2="3" y2="16.75" stroke="#414042" strokeWidth="1.5"/>
      </>
    )
  }

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {lines}
    </svg>
  )
}

export default SvgHamburger;
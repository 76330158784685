import { FC } from 'react'
import { FieldError, UseFormRegister } from 'react-hook-form'
import FormInputRender from './FormInputRender'

// How to type register properly:
// https://codesandbox.io/s/react-hook-form-typescript-xnb1u?file=/src/components/Input.tsx

interface InputPasswordProps {
  error?: any
  register: UseFormRegister<any>
  required?: boolean
  name?: string
  label?: string
  [key: string]: any
}

const FormInputPassword: FC<InputPasswordProps> = ({ 
  required,
  register,
  name = "password",
  label = "Password",
  ...props
}) => 
  <FormInputRender
    type="password"
    register={register}
    required={required}
    label={label}
    placeholder={label}
    {...register(name, {
      required,
    })}
    {...props}
  />

export default FormInputPassword

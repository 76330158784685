// import { LayoutStyles } from 'frontend/src/styles/layout'
// import NextLink from 'next/link'
import styled from 'styled-components'
import SvgLogo from '../svg/SvgLogo'
import SanityMenu from '../sanity/SanityMenu'
import { Breakpoint, Columns } from '../../styles/functions'
import { useNavigation } from '../../contexts/NavigationProvider'
import UtilWrapper from '../util/UtilWrapper'
import { useHeaderSubmenu } from '../../contexts/HeaderSubmenuProvider'
import LinkRender from '../link/LinkRender'
import SvgSearch from '../svg/SvgSearch'
import SvgHamburger from '../svg/SvgHamburger'
import { useInterface } from '../../contexts/InterfaceProvider'

type Props = {
  className?: string,
}

const HeaderMobile: React.FC<Props> = ({className}: Props) => {
  const { submenu, hideSubmenu, setReference } = useHeaderSubmenu();
  const navigation = useNavigation();
  const { toggleOverlay, overlay } = useInterface();
  const header = navigation?.find((m) => m._id == "header-desktop");
  const { mainMenu, accountMenu, } = header;

  const onMouseLeave = function() {
    setReference(false);
  }

  return (
      <Header 
        className={className} 
        onMouseLeave={onMouseLeave}>
        <Wrapper>
          <LinkRender href="/">
            <Logo />
          </LinkRender>

          <Nav>
            <Button onClick={() => toggleOverlay('SEARCH')}>
              <SvgSearch />
            </Button>

            <Button onClick={() => toggleOverlay('MMENU')}>
              <SvgHamburger isOpen={overlay == 'MMENU'} />
            </Button>
          </Nav>
        </Wrapper>
      </Header>
  )
}


const Wrapper = styled(UtilWrapper)`
  display: flex;
  justify-content: space-between;
`

const Logo = styled(SvgLogo)`
  height: 14px;
  width: 54px;
  margin-top: 4px;
`

const Header = styled.header`
  padding-top: 4px;
  padding-bottom: 4px;
  background: var(--color-background);
`

const Button = styled.button`
  display: block;
  height: 24px;
`

const Nav = styled.nav`
  display: flex;
  gap: 0 12px;
`

export default HeaderMobile

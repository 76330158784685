import styled from 'styled-components'
import ButtonPrimary from '../button/ButtonPrimary';
import SanityLinkRender from './SanityLinkRender';

interface Props {
  link?: any
  children?: React.ReactNode
}
const SanityButton: React.FC<Props> = ({ 
  children,
  link,
  ...rest
}: Props) => {
  return (
    <Button 
      link={link} 
      elementType={SanityLinkRender}
      {...rest}
    >
      {children || link?.title}
    </Button>
  )
}

const Button = styled(ButtonPrimary)`
`

export default SanityButton
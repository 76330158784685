import { useRouter } from "next/router";
import styled from "styled-components";
import { useCart } from "../../contexts/CartProvider";
import { useInterface } from "../../contexts/InterfaceProvider";
import { useProduct } from "../../contexts/ProductProvider";
import { Breakpoint } from "../../styles/functions";
import { TypeBodyM } from "../../styles/mixins";
import ProductBuy from "../product/ProductBuy";
import UtilWrapper from "../util/UtilWrapper";

type Props = {
  className?: string;
  children?: React.ReactNode;
};

const OverlayMobileControls: React.FC<Props> = ({ ...props }: Props) => {
  const { setOverlay } = useInterface();
  const { count } = useCart();
  const productContext = useProduct();
  const router = useRouter();

  // Hide on products route
  if (router.route.startsWith("/products/") && !productContext) {
    return <></>;
  }

  return (
    <Container {...props}>
      {productContext && <BuyButton />}
      <CartButton onClick={() => setOverlay("CART")}>
        <UtilWrapper>Cart {count}</UtilWrapper>
      </CartButton>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-charcoal100);
  z-index: 9050;

  ${Breakpoint("tablet", "min")} {
    display: none;
  }
`;

const BuyButton = styled(ProductBuy)`
  ${Breakpoint("mobile", "min")} {
    display: none;
  }
`;

const CartButton = styled.button`
  padding-top: 12px;
  padding-bottom: 12px;
  ${TypeBodyM};
  width: 100%;
  text-align: left;
`;

export default OverlayMobileControls;

import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useRouter } from "next/router";

import { Breakpoint, Columns } from "frontend/src/styles/functions";
import { TypeBodyM, Wrapper } from "frontend/src/styles/mixins";
import ProductCardList from "../../product/card/ProductCardList";
import { TypesetHeadingS } from "../../typeset/Typeset";

interface SanityContentCollectionProps {
  title: string;
  excerpt: any;
  products: any;
}

const SanityContentCollection: React.FC<SanityContentCollectionProps> = ({
  title,
  excerpt,
  products,
}: SanityContentCollectionProps) => {
  const router = useRouter();
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  if (!products) return <></>;

  useEffect(() => {
    setIsOverlayOpen(false);
  }, [router.asPath]);

  return (
    <Container>
      <Introduction
        indent={4}
        caption={title}
        content={excerpt}
        captionAlign="SUPER"
      />

      <ProductsWrap>
        <Sidebar>
          {/* <CollectionMode
            setIsOpen={setIsOverlayOpen}
         /> */}
        </Sidebar>
        <Products $layout={"M"} layout={"M"} products={products} />
      </ProductsWrap>
      {/* 
      <CollectionOverlayFilter 
        isOpen={isOverlayOpen} 
        setIsOpen={setIsOverlayOpen}
      /> */}
    </Container>
  );
};

const Container = styled.div`
  margin-top: var(--header-height);
  margin-bottom: var(--spacing-120);
`;

const Introduction = styled(TypesetHeadingS)`
  ${Wrapper};
  margin-bottom: var(--spacing-64);
`;

const ProductsWrap = styled.section`
  ${Breakpoint("tablet", "min")} {
    display: grid;
    grid-template-columns: ${Columns(3, 0)} 1fr ${Columns(1)};
    gap: 0 var(--gutter);
  }
`;

const Products = styled(ProductCardList)<{ $layout }>`
  // Overview card layout
  ${(props) => props.$layout == "OVERVIEW" && css``}

  // Medium card layout
  ${(props) =>
    props.$layout == "M" &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: var(--spacing-64) var(--gutter);

      ${Breakpoint("tablet", "max")} {
        ${Wrapper};
        grid-template-columns: 1fr 1fr;
      }
    `}

  // Small card layout
  ${(props) =>
    props.$layout == "S" &&
    css`
      display: grid;
      gap: var(--spacing-64) var(--gutter);

      ${Breakpoint("tablet", "max")} {
        ${Wrapper};
        grid-template-columns: 1fr 1fr;
      }
    `}
`;

const Sidebar = styled.div`
  ${TypeBodyM};
  margin-bottom: auto;
  margin-left: var(--inset);

  ${Breakpoint("tablet", "min")} {
    position: sticky;
    top: var(--header-height);
  }
`;

export default SanityContentCollection;

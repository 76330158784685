import { findShopifyProductById as findShopifyProductByIdHelper } from "library/utils/shopify";
import { convertProductIdToShopifyGid } from "library/utils/shopify/transform";
import {
  useContext,
  createContext,
  useReducer,
  useCallback,
  useEffect,
} from "react";
import { ShopifySdk } from "library/services/shopify";
import { createHashKey } from "library/utils";
import { fetchWithMemoryCache } from "library/utils/cache/memory";
export interface State {
  shopifyProducts: any[];
}

type Action = {
  type: "ADD" | "REPLACE";
  shopifyProducts: any[];
};

const initialState = {
  shopifyProducts: [],
};

// Products Provider
export const ProductsContext = createContext<State | any>(initialState);

function productReducer(state: State, action: Action) {
  switch (action.type) {
    case "ADD": {
      // console.log('Adding products')
      const newShopifyProducts = action.shopifyProducts.filter((p) => {
        return !state.shopifyProducts.find((_p) => p.id == _p.id);
      });

      return {
        ...state,
        shopifyProducts: [...state.shopifyProducts, ...newShopifyProducts],
      };
    }
    case "REPLACE": {
      return {
        ...state,
        shopifyProducts: action.shopifyProducts,
      };
    }
  }
}

export const ProductsProvider: React.FC = ({
  children,
  shopifyProducts,
}: any) => {
  const [state, dispatch] = useReducer(productReducer, {
    shopifyProducts,
  });

  // Replace shopify products if argument changes
  useEffect(() => {
    dispatch({
      type: "REPLACE",
      shopifyProducts,
    });
  }, [shopifyProducts]);

  const fetchShopifyProductById = useCallback(
    async (id) => {
      const storedShopifyProduct = findShopifyProductByIdHelper(
        state.shopifyProducts,
        id,
      );
      if (storedShopifyProduct) return storedShopifyProduct;

      const shopifyProductId = convertProductIdToShopifyGid(id);
      const key = createHashKey("shopifyProduct", { id: shopifyProductId });
      const result = await fetchWithMemoryCache(key, () =>
        ShopifySdk.productById({ id: shopifyProductId }),
      );

      if (result.product) {
        dispatch({
          type: "ADD",
          shopifyProducts: [result.product],
        });

        return result.product;
      }
    },
    [state],
  );

  const value = {
    ...state,
    fetchShopifyProductById,
    dispatch,
  };

  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
};

export function useProducts() {
  return useContext(ProductsContext);
}

export default ProductsProvider;

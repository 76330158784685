import styled from "styled-components";
import { useSettings } from "../../contexts/SettingsProvider";
import { TypeCaptionM, TypeHeadingXS } from "../../styles/mixins";
import OverlaySlideWrap from "./OverlaySlideWrap";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { useInterface } from "../../contexts/InterfaceProvider";
import ButtonInline from "../button/ButtonInline";
import { useEffect } from "react";

interface P {
  isActive: boolean;
}

const OverlaySearch: React.FC<P> = ({ isActive, ...props }: P) => {
  const settings = useSettings();
  const { register, handleSubmit } = useForm();
  const router = useRouter();
  const { closeOverlay } = useInterface();

  const onSubmit = (data) => {
    const encodedQuery = encodeURIComponent(data.query.replace("’", "'"));
    window.location.href = `/search/${encodedQuery}`;
    closeOverlay();
  };

  const onClick = (search: string) => {
    const encodedSearch = encodeURIComponent(search.replace("’", "'"));
    window.location.href = `/search/${encodedSearch}`;
    closeOverlay();
  };

  useEffect(() => {
    if (isActive) closeOverlay();
  }, [router.pathname]);

  return (
    <Container isActive={isActive} title="Search">
      <Form onSubmit={handleSubmit(onSubmit)} action="#">
        <Input
          type="search"
          placeholder="Search"
          {...register("query", { required: true })}
        />
      </Form>
      <Popular>
        <Heading>Popular Searches</Heading>
        <Searches>
          {settings.overlaySearch?.popularSearches.map((search, index) => (
            <Search
              onClick={() => onClick(search)}
              key={`OverlaySearch__Search--${index}`}
            >
              {search}
            </Search>
          ))}
        </Searches>
      </Popular>
    </Container>
  );
};

const Container = styled(OverlaySlideWrap)``;

const Form = styled.form`
  margin-top: var(--spacing-24);
`;

const Input = styled.input`
  ${TypeHeadingXS};
  border-bottom: 1px solid var(--color-charcoal400);
  width: 100%;
  padding-bottom: 8px;
  border-radius: 0;
  &[type="search"] {
    appearance: none;
    -webkit-appearance: none;
  }
`;

const Popular = styled.div`
  margin-top: var(--spacing-40);
`;

const Heading = styled.h2`
  ${TypeCaptionM};
  margin-bottom: var(--spacing-16);
`;

const Searches = styled.nav`
  ${TypeHeadingXS};
`;

const Search = styled(ButtonInline)`
  display: inline-flex;

  &:after {
    content: ",";
    display: inline-flex;
    margin-right: 1ch;
  }
  &:last-child:after {
    display: none;
  }
`;

export default OverlaySearch;
